import { FilterChartsPage, FilterChartsPageConfig } from '@app/components/page/FilterChartsPage'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import React from 'react'

const filterChartsPageConfig: FilterChartsPageConfig = {
  tableName: 'ingredient_analysis_table',
  getFiltersActionName: BackofficeApiActions.GetIngredientInsightsFilters,
  getDataActionName: BackofficeApiActions.GetIngredientInsightsData,
  pageTitle: 'Ingredients',
  pageDescription: 'Apply filters and explore results to discover new opportunities.',
}

const IngredientAnalysisIndex: React.FunctionComponent = () => {
  return <FilterChartsPage {...filterChartsPageConfig}></FilterChartsPage>
}

export { IngredientAnalysisIndex }
