import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import React from 'react'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { Button, Pagination, PaginationVariant, Panel, PanelMain, PanelMainBody, Spinner, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem } from '@patternfly/react-core'
import { NoResultsInTable } from '@app/components/table/NoResultsInTable'
import { EyeIcon, PenIcon, TrashIcon } from '@patternfly/react-icons'
import { TableComposable, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table'
import { useHistory, useParams } from 'react-router-dom'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { get } from 'lodash'
import { CreateReadUpdateApiKey } from './CreateReadUpdateApiKey'
import { IApiKey } from './IApiKey'
import { ITenant } from './ITenant'

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const ListApiKeys: React.FunctionComponent = () => {
  const [refresh, setRefresh] = React.useState<number>(1)

  const api = useAxios()
  const history = useHistory()
  const pathParams = useParams()
  const actions = ['create', 'read', 'update']
  const { action, id } = pathParams as Record<string, string | undefined>

  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }

  let initialModalProps: IModalBasicComponentModalProps = defaultModalProps
  let initialShowModal = false

  const toggleRefresh = () => {
    setRefresh(refresh + 1)
  }

  if (actions.includes(action || '') && typeof id === 'string') {
    initialModalProps = {
      component: CreateReadUpdateApiKey,
      description: '',
      title: '',
      data: {
        action,
        id: parseInt(id, 10),
        toggleRefresh,
      },
    }
    initialShowModal = true
  }

  const [loading, setLoading] = React.useState(false)
  const [results, setResults] = React.useState<IApiKey[]>([])
  const [isModalOpen, setIsModalOpen] = React.useState(initialShowModal)
  const [modalProps, setModalProps] = React.useState(initialModalProps)
  const [helperTenants, setHelperTenants] = React.useState<ITenant[]>([])

  React.useEffect(() => {
    setLoading(true)
    const abortController = new AbortController()

    const fetchApiKeys = async () => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.ApiKeys, {
          action: BackofficeApiActions.ListApiKeys,
        })
        if (response?.data.result) {
          return response?.data.result as IApiKey[]
        }
        return []
      } catch (error) {
        throw error
      }
    }

    const fetchTenants = async () => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.ApiKeys, {
          action: BackofficeApiActions.ListTenants,
        })
        if (response?.data.result) {
          return response?.data.result as ITenant[]
        }
        return []
      } catch (error) {
        throw error
      }
    }

    const fetchAll = async () => {
      const apiKeys = await fetchApiKeys()
      const tenants = await fetchTenants()
      console.log(apiKeys)
      setResults(apiKeys)
      setHelperTenants(tenants)
    }

    fetchAll()
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [refresh])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const toggleModalProps = (props: Partial<IModalBasicComponentModalProps> = {}) => {
    setModalProps({ ...modalProps, ...props })
  }

  const clickCreate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    setModalProps({
      component: CreateReadUpdateApiKey,
      data: { action: 'create', toggleRefresh },
      description: '',
      title: '',
      id: 'api-keys-modal',
    })

    setIsModalOpen(true)

    history.push('/api_keys/create')
  }

  const clickRead = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    e.preventDefault()

    setModalProps({
      component: CreateReadUpdateApiKey,
      data: { action: 'read', id },
      description: ``,
      title: '',
      id: 'api-keys-modal',
    })

    setIsModalOpen(true)

    history.push('/api_keys/read/' + id)
  }

  const clickUpdate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    e.preventDefault()

    setModalProps({
      component: CreateReadUpdateApiKey,
      data: { action: 'update', id, toggleRefresh },
      description: ``,
      title: '',
      id: 'api-keys-modal',
    })

    setIsModalOpen(true)

    history.push('/api_keys/update/' + id)
  }

  const clickDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, apiKey: IApiKey) => {
    e.preventDefault()

    if (confirm(`Are you sure you want to delete data api key "${apiKey.handle}"?`)) {
      setLoading(true)
      await api.current
        ?.post(BackofficeAPIConfig.Domains.ApiKeys, {
          action: BackofficeApiActions.DeleteApiKey,
          data: {
            id: apiKey.id,
          },
        } as BackofficeApiEventSchema)
        .then(toggleRefresh)
        .catch((error) => {
          alert(getErrorMessage(error))
          setLoading(false)
        })
    }
  }

  const columns = [
    {
      key: 'id',
      label: 'ID',
    },
    {
      key: 'handle',
      label: 'Handle',
    },
    {
      key: 'tenantId',
      label: 'Tenant',
      format(val) {
        const tenant = helperTenants.find((n) => n.id === val)
        if (tenant) {
          return tenant.handle
        }
        return val
      },
    },
    {
      key: 'createdAt',
      label: 'Created At',
    },
    {
      key: 'updatedAt',
      label: 'Updated At',
    },
  ] as { key: string; label: string; format: (val: any) => any }[]

  return (
    <React.Fragment>
      <ModalBasicComponent
        isModalOpen={isModalOpen}
        handleModalToggle={toggleModal}
        modalProps={modalProps}
        toggleModalProps={toggleModalProps}
        key={null}
        type={''}
        props={undefined}
      />

      <Toolbar id="toolbar-items">
        <ToolbarContent alignment={{ lg: 'alignLeft' }}>
          <Button isSmall variant="primary" id="single-group-create-button" onClick={(e) => clickCreate(e)}>
            Create new API key
          </Button>
        </ToolbarContent>
      </Toolbar>

      <Panel>
        <PanelMain>
          <PanelMainBody>
            {loading && <Spinner />}
            {!loading && (
              <TableComposable className="vertical-align-middle" variant={'compact'} borders={true} isStriped>
                <Thead>
                  <Tr>
                    {columns.map((column) => (
                      <Th key={column.key}>{column.label}</Th>
                    ))}
                    <Th width={10}></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {results.length > 0 &&
                    results.map((result, index) => (
                      <Tr key={index}>
                        {columns.map((column) => (
                          <Td key={column.key}>{column.format ? column.format(get(result, column.key)) : get(result, column.key)}</Td>
                        ))}
                        <Td width={10}>
                          <Toolbar>
                            <ToolbarContent alignment={{ default: 'alignRight' }}>
                              <ToolbarGroup alignment={{ default: 'alignRight' }} variant="icon-button-group">
                                <ToolbarItem>
                                  <Button variant="plain" aria-label="view" onClick={(e) => clickRead(e, result.id)}>
                                    <EyeIcon></EyeIcon>
                                  </Button>
                                </ToolbarItem>
                                <ToolbarItem>
                                  <Button variant="plain" aria-label="update" onClick={(e) => clickUpdate(e, result.id)}>
                                    <PenIcon></PenIcon>
                                  </Button>
                                </ToolbarItem>
                                <ToolbarItem>
                                  <Button variant="plain" aria-label="delete" onClick={(e) => clickDelete(e, result)}>
                                    <TrashIcon></TrashIcon>
                                  </Button>
                                </ToolbarItem>
                              </ToolbarGroup>
                            </ToolbarContent>
                          </Toolbar>
                        </Td>
                      </Tr>
                    ))}
                  {results.length === 0 && <NoResultsInTable></NoResultsInTable>}
                </Tbody>
              </TableComposable>
            )}
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </React.Fragment>
  )
}

export { ListApiKeys }
