import React from 'react'
import { AnalysisCharts } from '@app/components/charts/AnalysisCharts'
import { CustomFilters, Filter } from '@app/components/filter/CustomFilters'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { Alert, AlertGroup, AlertProps, PageGroup, PageSection, PageSectionVariants, Spinner, Text, TextContent } from '@patternfly/react-core'
import { AxiosResponse } from 'axios'

interface IAlert {
  text: string
  variant: AlertProps['variant']
  timeout?: number
}

interface FilterChartsPageConfig {
  tableName: string
  pageTitle: string
  pageDescription: string
  getFiltersActionName: BackofficeApiActions
  getDataActionName: BackofficeApiActions
}

const FilterChartsPage: React.FunctionComponent<FilterChartsPageConfig> = (config: FilterChartsPageConfig) => {
  const api = useAxios()
  const [alerts, setAlerts] = React.useState<IAlert[]>([])
  const [loadingFilters, setLoadingFilters] = React.useState<boolean>(false)
  const [filterData, setFilterData] = React.useState<Filter[]>([])
  const [loadingCharts, setLoadingCharts] = React.useState<boolean>(false)
  const [chartData, setChartData] = React.useState<Record<string, unknown>[]>([])
  const [chartOrderData, setChartOrderData] = React.useState<Record<string, unknown>[]>([])

  // load filter data
  React.useEffect(() => {
    const abortController = new AbortController()

    setLoadingFilters(true)

    api.current
      ?.post(BackofficeAPIConfig.Domains.Insights, {
        action: config.getFiltersActionName,
        data: {},
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          const data = response.data.result['filters'] as Filter[]

          setFilterData(data)
          setLoadingFilters(false)
          onReloadFilters(data)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoadingFilters(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  React.useEffect(() => {
    setChartData([...reorderCharts(chartOrderData, chartData)])
  }, [chartOrderData])

  const onReloadFilters = async (filters: Filter[]) => {
    try {
      setLoadingCharts(true)

      const response = await api.current?.post(BackofficeAPIConfig.Domains.Insights, {
        action: config.getDataActionName,
        data: { filters, config },
      })

      if (response && response.data && response.data.result) {
        if (chartOrderData.length) {
          setChartData([...reorderCharts(chartOrderData, response.data.result.charts)])
        } else {
          setChartData(response.data.result.charts)
        }

        setLoadingCharts(false)
        console.log('QUERY:', response.data.result.query)
      } else {
        throw new Error(`There was an error: ${JSON.stringify(response?.data, null, 2)}`)
      }
    } catch (error) {
      alert(getErrorMessage(error))
      setLoadingCharts(false)
    }
  }

  // reorder and hide/show charts
  const reorderCharts = (newOrder: Record<string, unknown>[], chartData: Record<string, unknown>[]): Record<string, unknown>[] => {
    const chartDataClone = [...chartData]
    const nameToIndex = new Map(chartDataClone.map((obj, index) => [obj.name, index]))
    const reorderedArray = new Array(chartDataClone.length)
    newOrder.forEach((item, newIndex) => {
      const originalIndex = nameToIndex.get(item.name) as number
      reorderedArray[newIndex] = { ...chartDataClone[originalIndex], ...item }
    })
    return reorderedArray
  }

  const onSaveView = async (viewName: string, viewDescription: string, filters: Filter[]) => {
    try {
      await api.current?.post(BackofficeAPIConfig.Domains.Insights, {
        action: BackofficeApiActions.SaveInsightsView,
        data: { config, viewName, viewDescription, filterData: filters, chartOrderData },
      })

      addAlert({ text: `View "${viewName}" has been saved.`, variant: 'success', timeout: 5000 })
    } catch (error) {
      alert(getErrorMessage(error))
    }
  }

  const onLoadView = async (loadedView: Record<string, any>) => {
    setFilterData(loadedView['filterData'])

    await onReloadFilters(loadedView['filterData'])

    if (loadedView['chartOrderData'].length) {
      setChartOrderData(loadedView['chartOrderData'])
    }
  }

  const addAlert = (alert: IAlert) => {
    setAlerts((prevAlert) => [...prevAlert, alert])
  }

  return (
    <React.Fragment>
      <PageGroup stickyOnBreakpoint={{ default: 'top' }}>
        <PageSection variant={PageSectionVariants.light}>
          <TextContent>
            <Text component="h1">{config.pageTitle}</Text>
            <Text component="p">{config.pageDescription}</Text>
          </TextContent>

          {loadingFilters && <Spinner size="lg" className="pf-u-mt-md" />}
          {!loadingFilters && (
            <CustomFilters
              config={config as unknown as Record<string, unknown>}
              filters={filterData}
              chartData={chartData.map((p) => ({ name: p['name'], visible: p['visible'] }))}
              onReload={(filters) => onReloadFilters(filters)}
              onReorderCharts={(chartData) => setChartOrderData(chartData)}
              onSaveView={(viewName, viewDescription, filters) => onSaveView(viewName, viewDescription, filters)}
              onLoadView={(loadView) => onLoadView(loadView)}
            ></CustomFilters>
          )}

          <AlertGroup isLiveRegion>
            {alerts.map((alert, index) => (
              <Alert key={index} timeout={alert.timeout ? alert.timeout : 0} isInline isPlain variant={alert.variant} title={alert.text} className="pf-u-mt-md" />
            ))}
          </AlertGroup>
        </PageSection>
      </PageGroup>

      <PageSection isFilled>
        {loadingCharts && <Spinner />}
        {!loadingCharts && <AnalysisCharts chartData={chartData}></AnalysisCharts>}
      </PageSection>
    </React.Fragment>
  )
}

export { FilterChartsPage, FilterChartsPageConfig }
