import * as React from 'react'
import { Card, CardTitle, CardBody, Icon } from '@patternfly/react-core'

import styles from './Card.module.css'

interface ICardBasicComponentProps extends React.ReactElement {
  toggleModal(): unknown
  toggleModalProps(props: ICardBasicComponentProps): unknown
  title: string
  description: string
  body: string
  component: React.FunctionComponent<any>
  data: Record<string, any>
}

const component: React.FunctionComponent<ICardBasicComponentProps> = (props: ICardBasicComponentProps) => {
  const handleExpand = () => {
    props.toggleModal()
    props.toggleModalProps(props)
  }

  return (
    <Card isFullHeight onClick={handleExpand} hasSelectableInput isSelectableRaised selectableInputAriaLabel={props.title}>
      <CardTitle>{props.title}</CardTitle>
      <CardBody className={styles.cardBody}>{props.body}</CardBody>
    </Card>
  )
}

const CardBasicComponent = React.memo(component)

export { CardBasicComponent }
