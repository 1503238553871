import { FilterChartsPage, FilterChartsPageConfig } from '@app/components/page/FilterChartsPage'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import React from 'react'

const filterChartsPageConfig: FilterChartsPageConfig = {
  tableName: 'formulation_analysis_table',
  getFiltersActionName: BackofficeApiActions.GetFormulationInsightsFilters,
  getDataActionName: BackofficeApiActions.GetFormulationInsightsData,
  pageTitle: 'Formulations',
  pageDescription: 'Apply filters and explore results to discover new opportunities.',
}

const FormulationAnalysisIndex: React.FunctionComponent = () => {
  return <FilterChartsPage {...filterChartsPageConfig}></FilterChartsPage>
}

export { FormulationAnalysisIndex }
