import { useAxios } from '@app/utils/useAxios'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { Button, Card, Label, PageSection, PageSectionVariants, Spinner, Text, TextContent, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem } from '@patternfly/react-core'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { AxiosResponse } from 'axios'
import moment from 'moment-timezone'
import React from 'react'
import { IBayesianModel } from '@lib/Model/BayesianModel'
import { useHistory } from 'react-router-dom'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { BMCreateNew } from './Modals/BMCreateNew'

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const defaultModalProps: IModalBasicComponentModalProps = {
  component: DefaultModalComponent,
  description: '',
  title: '',
  data: {},
}

const BayesianIndex: React.FunctionComponent = () => {
  const api = useAxios()
  const history = useHistory()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [models, setModels] = React.useState<IBayesianModel[]>([])
  const [reloadModels, setReloadModels] = React.useState<number>(0)

  React.useEffect(() => {
    const abortController = new AbortController()

    setLoading(true)

    api.current
      ?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.ListBMModels,
        data: {},
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          setModels(response.data.result as IBayesianModel[])
          setLoading(false)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [reloadModels])

  const columnNames = {
    id: 'Model ID',
    name: 'Name',
    status: 'Status',
    type: 'Type',
    firstImplementedAt: 'First implemented',
  }

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const modalId = 'model-create-new-modal'
  const openModal = () => {
    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = 'Create new model'
    defaultModalProps.description = ''
    defaultModalProps.component = BMCreateNew
    defaultModalProps.id = modalId
    setModalProps(defaultModalProps)
  }
  const toggleModal = (id: string) => {
    setIsModalOpen(!isModalOpen)

    if (id === modalId) {
      setReloadModels(reloadModels + 1)
    }
  }

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      <PageSection variant={PageSectionVariants.light}>
        <TextContent>
          <Text component="h1">Intelligence</Text>
          <Text component="p">Manage customisation models</Text>
        </TextContent>
      </PageSection>
      <PageSection isFilled>
        <Card>
          <Toolbar usePageInsets id="compact-toolbar">
            <ToolbarContent>
              <ToolbarGroup>
                <ToolbarItem>
                  <Button variant="primary" isSmall onClick={() => openModal()}>
                    Create new model
                  </Button>
                </ToolbarItem>
              </ToolbarGroup>
            </ToolbarContent>
          </Toolbar>

          {loading && <Spinner className="pf-u-m-xl" size="lg" />}
          {!loading && (
            <TableComposable className="vertical-align-middle" variant={'compact'} borders={true} isStriped>
              <Thead>
                <Tr>
                  <Th>{columnNames.id}</Th>
                  <Th width={20}>{columnNames.name}</Th>
                  <Th>{columnNames.status}</Th>
                  <Th>{columnNames.type}</Th>
                  <Th>{columnNames.firstImplementedAt}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {models.map((model, index) => (
                  <Tr key={index}>
                    <Td dataLabel={columnNames.id}>{model.modelId}</Td>
                    <Td dataLabel={columnNames.name}>
                      <Button variant="link" isInline onClick={() => history.push(`/model/bayesian/${model.modelName}`)}>
                        {model.modelNameHumanReadable}
                      </Button>
                    </Td>
                    <Td dataLabel={columnNames.status}>
                      {model.active && (
                        <Label isCompact color="green">
                          active
                        </Label>
                      )}
                      {model.active === false && (
                        <Label isCompact color="red">
                          inactive
                        </Label>
                      )}
                    </Td>
                    <Td>
                      <Label isCompact color="cyan">
                        {model.type}
                      </Label>
                    </Td>
                    <Td>{moment(model.firstImplementedAt).format('YYYY-MM-DD HH:mm:ss')}</Td>
                  </Tr>
                ))}
              </Tbody>
            </TableComposable>
          )}
        </Card>
      </PageSection>
    </React.Fragment>
  )
}

export { BayesianIndex }
