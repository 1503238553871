import React from 'react'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { AxiosResponse } from 'axios'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { Spinner } from '@patternfly/react-core'

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [iframeUrl, setIframeUrl] = React.useState<string | undefined>(undefined)
  const [loading, setLoading] = React.useState(true)
  const iframeRef = React.useRef<HTMLIFrameElement>(null)

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetDatasetEditIframeUrl,
      data: {
        customerId: props.data.customerId,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const result = response?.data?.result as Record<string, any>

        setIframeUrl(result.url)

        if (iframeRef.current) {
          iframeRef.current.addEventListener('load', iframeOnLoad, false)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const iframeOnLoad = () => {
    setLoading(false)
  }

  React.useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    const messageHandler = (event: MessageEvent) => {
      if (event.origin.endsWith('noie.com') && event.data === 'success') {
        abortController.abort()

        setTimeout(() => {
          props.closeModal()
        }, 1000)
      }
    }

    window.addEventListener('message', messageHandler, { signal })
  }, [iframeUrl])

  return (
    <React.Fragment>
      <div className={`iframe-container ${loading ? 'loading' : 'loaded'}`}>
        {loading && <Spinner className="spinner" />}
        <iframe ref={iframeRef} src={iframeUrl} frameBorder="0" allow="clipboard-write;" referrerPolicy="strict-origin-when-cross-origin"></iframe>
      </div>
    </React.Fragment>
  )
}

const CustomerDatasetEdit = React.memo(component)

export { CustomerDatasetEdit }
