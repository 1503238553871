import React from 'react'
import { PageSection, Gallery, GalleryItem, Card, CardTitle, CardBody, CardProps } from '@patternfly/react-core'
import { useHistory } from 'react-router-dom'

const DashboardHome: React.FunctionComponent = () => {
  const history = useHistory()
  const cardProps: CardProps = {
    isFullHeight: true,
    hasSelectableInput: true,
    isSelectableRaised: true,
  }

  return (
    <React.Fragment>
      <PageSection type="default">
        <Gallery
          hasGutter
          minWidths={{
            md: '300px',
          }}
        >
          <GalleryItem>
            <Card onClick={() => history.push('/data')} {...cardProps}>
              <CardTitle>Data collection</CardTitle>
              <CardBody>Manage user submissions</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card onClick={() => history.push('/data_refinement/main')} {...cardProps}>
              <CardTitle>Data refinement</CardTitle>
              <CardBody>Manage data refinement and success indicators</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card onClick={() => history.push('/insights/chat-assistant')} {...cardProps}>
              <CardTitle>Insights</CardTitle>
              <CardBody>Access product development analytics</CardBody>
            </Card>
          </GalleryItem>

          <GalleryItem>
            <Card onClick={() => history.push('/model/bayesian')} {...cardProps}>
              <CardTitle>Intelligence</CardTitle>
              <CardBody>Manage customisation models</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card onClick={() => history.push('/product/main')} {...cardProps}>
              <CardTitle>Products</CardTitle>
              <CardBody>Product & Ingredient management</CardBody>
            </Card>
          </GalleryItem>

          <GalleryItem>
            <Card onClick={() => history.push('/customer')} {...cardProps}>
              <CardTitle>Operations</CardTitle>
              <CardBody>Access user management & operational tools</CardBody>
            </Card>
          </GalleryItem>
        </Gallery>
      </PageSection>
    </React.Fragment>
  )
}

export { DashboardHome }
