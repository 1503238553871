import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { DescriptionList, DescriptionListDescription, DescriptionListGroup, DescriptionListTerm, Label, Text, TextContent, TextVariants } from '@patternfly/react-core'
import React from 'react'

const BMClusterRules: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const cluster = props.data as Record<string, any>

  const renderRules = (rules: Record<string, any>) => {
    if (!rules) {
      return (
        <Label isCompact color="grey">
          none
        </Label>
      )
    }

    const rulesArray = Object.entries(rules)

    if (rulesArray.length) {
      return rulesArray.map((value, index) => (
        <React.Fragment key={index}>
          {(typeof value[1] === 'string' || typeof value[1] === 'number' || typeof value[1] === 'boolean') && (
            <React.Fragment>
              <Label key={index} isCompact color="blue">
                {value[0]}: <b>{value[1].toString()}</b>
              </Label>{' '}
            </React.Fragment>
          )}

          {typeof value[1] === 'object' && (
            <React.Fragment>
              <Label isCompact color="blue">
                {value[0]}
              </Label>{' '}
              {(value[1] as []).map((value, index) => (
                <React.Fragment key={index}>
                  <Label isCompact color="gold">
                    {value}
                  </Label>{' '}
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        </React.Fragment>
      ))
    } else {
      return (
        <Label isCompact color="grey">
          none
        </Label>
      )
    }
  }

  return (
    <React.Fragment>
      <TextContent className="pf-u-mb-md">
        <Text component={TextVariants.h1}>Rules</Text>
        <Text component={TextVariants.p}>All rules must be fulfilled to be eligible for cluster</Text>
      </TextContent>
      <DescriptionList isCompact isHorizontal>
        <DescriptionListGroup>
          <DescriptionListTerm>Greater</DescriptionListTerm>
          <DescriptionListDescription>{renderRules(cluster['greaterRules'])}</DescriptionListDescription>
        </DescriptionListGroup>

        <DescriptionListGroup>
          <DescriptionListTerm>Lesser</DescriptionListTerm>
          <DescriptionListDescription>{renderRules(cluster['lesserRules'])}</DescriptionListDescription>
        </DescriptionListGroup>

        <DescriptionListGroup>
          <DescriptionListTerm>Equals</DescriptionListTerm>
          <DescriptionListDescription>{renderRules(cluster['equalsRules'])}</DescriptionListDescription>
        </DescriptionListGroup>

        <DescriptionListGroup>
          <DescriptionListTerm>Range</DescriptionListTerm>
          <DescriptionListDescription>{renderRules(cluster['inRules'])}</DescriptionListDescription>
        </DescriptionListGroup>
      </DescriptionList>
      <TextContent className="pf-u-mt-lg pf-u-mb-md">
        <Text component={TextVariants.h3}>Any rules</Text>
        <Text component={TextVariants.p}>At least one rule must be fulfilled to be eligible for cluster</Text>
      </TextContent>
      <DescriptionList isCompact isHorizontal>
        <DescriptionListGroup>
          <DescriptionListTerm>Greater</DescriptionListTerm>
          <DescriptionListDescription>{renderRules(cluster['anyRules']['greater'])}</DescriptionListDescription>
        </DescriptionListGroup>

        <DescriptionListGroup>
          <DescriptionListTerm>Lesser</DescriptionListTerm>
          <DescriptionListDescription>{renderRules(cluster['anyRules']['lesser'])}</DescriptionListDescription>
        </DescriptionListGroup>

        <DescriptionListGroup>
          <DescriptionListTerm>Equals</DescriptionListTerm>
          <DescriptionListDescription>{renderRules(cluster['anyRules']['equals'])}</DescriptionListDescription>
        </DescriptionListGroup>

        <DescriptionListGroup>
          <DescriptionListTerm>Range</DescriptionListTerm>
          <DescriptionListDescription>{renderRules(cluster['anyRules']['in'])}</DescriptionListDescription>
        </DescriptionListGroup>
      </DescriptionList>
    </React.Fragment>
  )
}

export { BMClusterRules }
