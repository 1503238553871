import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useAxios } from '@app/utils/useAxios'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { AxiosResponse } from 'axios'
import {
  ActionGroup,
  Alert,
  Button,
  Form,
  FormAlert,
  FormGroup,
  FormSelect,
  FormSelectOption,
  Grid,
  GridItem,
  NumberInput,
  Spinner,
  TextInput,
  TextInputGroup,
  TextInputGroupMain,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
} from '@patternfly/react-core'
import { TagInput } from '@app/components/textInput/TagInput'
import { PlusIcon, TrashIcon } from '@patternfly/react-icons'
import { IDataDefinition } from '../IData'

const componentCreateReadUpdateDataDefinition: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  // Fixed
  const api = useAxios()
  const history = useHistory()
  const modalTitles = {
    create: 'Creating data definition',
    read: 'Viewing data definition',
    update: 'Updating data definition',
  }

  // Reactive
  const [action, setAction] = React.useState<string>(props.data.action)
  const [id, setId] = React.useState<string | undefined>(props.data.id)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [sending, setSending] = React.useState<boolean>(false)
  const [isReadOnly, setIsReadOnly] = React.useState<boolean>(action === 'read')
  const [validated, setValidated] = React.useState<string>('default')
  const [saved, setSaved] = React.useState<boolean>(false)

  const [dataDefinition, setDataDefinition] = React.useState<IDataDefinition | undefined>(undefined)

  const [formHandle, setFormHandle] = React.useState<string>('')
  const [formHandleTitle, setFormHandleTitle] = React.useState<string>('')
  const [formValueType, setFormValueType] = React.useState<string>('string')
  const [formTag, setFormTag] = React.useState<string>('')
  const [formTagTitle, setFormTagTitle] = React.useState<string>('')
  const [formOrder, setFormOrder] = React.useState<string>('')
  const [formDisplayFieldType, setFormDisplayFieldType] = React.useState<string>('text')
  const [formValues, setFormValues] = React.useState<string[]>([])

  const [helperValueTypes, setHelperValueTypes] = React.useState<string[]>(['string', 'boolean', 'integer', 'date'])
  const [helperDisplayFieldTypes, setHelperDisplayFieldTypes] = React.useState<string[]>(['text', 'textarea', 'image'])

  React.useEffect(() => {
    setLoading(true)
    console.log('test', action, id, modalTitles[action])
    props.setTitle(modalTitles[action])

    const abortController = new AbortController()

    const fetchDataDefinition = async (id: string) => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.Data, {
          action: BackofficeApiActions.ReadDataDefinition,
          data: {
            id,
          },
        })
        if (response?.data.result) {
          return response?.data.result as IDataDefinition
        }
        return undefined
      } catch (error) {
        throw error
      }
    }

    const fetchAll = async (id?: string) => {
      if (id) {
        const dd = await fetchDataDefinition(id)
        if (typeof dd !== 'undefined') {
          setDataDefinition(dd)
          setFormHandle(dd.handle)
          setFormHandleTitle(dd.handleTitle.en || '')
          setFormValueType(dd.valueType)
          setFormTag(dd.tag)
          setFormTagTitle(dd.tagTitle.en || '')
          setFormOrder(`${dd.metadata?.order || ''}`)
          setFormDisplayFieldType(dd.metadata?.displayFieldType || 'text')
          setFormValues(dd.values || [])
        }
      }
    }

    fetchAll(id)
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [action])

  React.useEffect(() => {
    props.onBeforeCloseModal.once((_props) => {
      history.push(`/data/data_definitions`)
    })
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  const updateValue = (index: number, value: string) => {
    setFormValues(
      formValues.map((n, i) => {
        if (i === index) {
          n = value
        }
        return n
      })
    )
  }

  const clickAddValue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    setFormValues([...formValues, ''])
  }

  const clickRemoveValue = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.preventDefault()

    setFormValues(formValues.filter((_, i) => i !== index))
  }

  return (
    <React.Fragment>
      {loading && <Spinner></Spinner>}
      {!loading && (
        <Form onSubmit={handleSubmit}>
          {validated === 'error' && (
            <FormAlert>
              <Alert variant="danger" title="Fill out all required fields before continuing." aria-live="polite" isInline />
            </FormAlert>
          )}
          {saved && (
            <FormAlert>
              <Alert variant="success" title="Formulation saved." aria-live="polite" isInline />
            </FormAlert>
          )}
          <Grid hasGutter>
            <GridItem span={6}>
              <FormGroup label="Handle" isRequired>
                <TextInput isRequired id="formHandle" type="text" value={formHandle} readOnly={isReadOnly} onChange={(val) => setFormHandle(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label="Handle Title" isRequired>
                <TextInput isRequired id="formHandleTitle" type="text" value={formHandleTitle} readOnly={isReadOnly} onChange={(val) => setFormHandleTitle(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label="Tag" isRequired>
                <TextInput isRequired id="formTag" type="text" value={formTag} readOnly={isReadOnly} onChange={(val) => setFormTag(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label="Tag Title" isRequired>
                <TextInput isRequired id="formTagTitle" type="text" value={formTagTitle} readOnly={isReadOnly} onChange={(val) => setFormTagTitle(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={4}>
              <FormGroup label="Value Type" isRequired>
                <FormSelect isRequired id="formValueType" value={formValueType} readOnly={isReadOnly} onChange={(val) => setFormValueType(val)}>
                  {helperValueTypes.map((option, index) => (
                    <FormSelectOption key={index} value={option} label={option.toUpperCase()} />
                  ))}
                </FormSelect>
              </FormGroup>
            </GridItem>
            <GridItem span={4}>
              <FormGroup label="Display Field Type" isRequired>
                <FormSelect isRequired id="formDisplayFieldType" value={formDisplayFieldType} readOnly={isReadOnly} onChange={(val) => setFormDisplayFieldType(val)}>
                  {helperDisplayFieldTypes.map((option, index) => (
                    <FormSelectOption key={index} value={option} label={option.toUpperCase()} />
                  ))}
                </FormSelect>
              </FormGroup>
            </GridItem>
            <GridItem span={4}>
              <FormGroup label="Order" isRequired>
                <TextInput isRequired id="formOrder" type="text" value={formOrder} readOnly={isReadOnly} onChange={(val) => setFormOrder(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={12}>
              <FormGroup label="Values">
                {formValues.map((item, index) => (
                  <Grid key={'value' + index} hasGutter>
                    <GridItem span={8}>
                      <TextInput
                        isRequired
                        placeholder="value"
                        id={'formOptionValue' + index}
                        type="text"
                        value={item}
                        readOnly={isReadOnly}
                        onChange={(val) => updateValue(index, val)}
                      ></TextInput>
                    </GridItem>
                    <GridItem span={4}>
                      {!isReadOnly && (
                        <Toolbar>
                          <ToolbarContent alignment={{ default: 'alignRight' }}>
                            <ToolbarGroup alignment={{ default: 'alignRight' }} variant="icon-button-group">
                              {index + 1 === formValues.length && (
                                <ToolbarItem>
                                  <Button variant="plain" aria-label="view" onClick={(e) => clickAddValue(e)}>
                                    <PlusIcon></PlusIcon>
                                  </Button>
                                </ToolbarItem>
                              )}
                              <ToolbarItem>
                                <Button variant="plain" aria-label="delete" onClick={(e) => clickRemoveValue(e, index)}>
                                  <TrashIcon></TrashIcon>
                                </Button>
                              </ToolbarItem>
                            </ToolbarGroup>
                          </ToolbarContent>
                        </Toolbar>
                      )}
                    </GridItem>
                  </Grid>
                ))}
              </FormGroup>
            </GridItem>
            {!isReadOnly && (
              <GridItem offset={9} span={3}>
                <ActionGroup style={{ float: 'right' }}>
                  <Button variant="primary" type="submit" isLoading={sending}>
                    Submit
                  </Button>
                  <Button variant="link" type="button" onClick={() => props.closeModal()}>
                    Cancel
                  </Button>
                </ActionGroup>
              </GridItem>
            )}
          </Grid>
        </Form>
      )}
    </React.Fragment>
  )
}

const CreateReadUpdateDataDefinition = React.memo(componentCreateReadUpdateDataDefinition)

export { CreateReadUpdateDataDefinition }
