import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { TypeaheadSelectMultiple } from '@app/components/select/TypeaheadSelectMultiple'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import {
  ActionGroup,
  ActionList,
  ActionListItem,
  Alert,
  AlertGroup,
  Button,
  Checkbox,
  Divider,
  Form,
  FormGroup,
  FormSection,
  HelperText,
  HelperTextItem,
  Spinner,
  TextArea,
  getUniqueId,
} from '@patternfly/react-core'
import { SelectOptionProps } from '@patternfly/react-core/next'
import { AxiosResponse } from 'axios'
import moment from 'moment-timezone'
import * as React from 'react'
import { CustomerAllergyReport } from './CustomerAllergyReport'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

interface IManualExclusion {
  customerInformationAllergyId: number
  customerId: number
  excludeSku: string | null
  excludeBody: boolean | null
  excludeFace: boolean | null
  excludeFaceRetinol: boolean | null
  excludeCleanser: boolean | null
  excludeSunscreen: boolean | null
  excludeBodySunscreen: boolean | null
  excludeSpotTreatment: boolean | null
  excludeSerum: boolean | null
  ignore: string | null
  createdAt?: Date
  updatedAt?: Date
}

interface IManualExclusionModel {
  products: SelectOptionProps[]
}

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [manualExclusion, setManualExclusion] = React.useState<IManualExclusion>()
  const [manualExclusionModel, setManualExclusionModel] = React.useState<IManualExclusionModel>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [sending, setSending] = React.useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const [updated, setUpdated] = React.useState<number>(0)
  const [alerts, setAlerts] = React.useState<React.ReactNode[]>([])
  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  type TypeaheadSelectHandle = React.ElementRef<typeof TypeaheadSelectMultiple>
  const typeaheadSelectMultipleRef = React.useRef<TypeaheadSelectHandle>(null)

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetManualExclusion,
      data: {
        customerId: props.data.customerId,
      },
    }

    const formDataPromise = api.current?.post(BackofficeAPIConfig.Domains.Customer, payload)
    const formModelPromise = api.current?.post(BackofficeAPIConfig.Domains.Customer, {
      action: BackofficeApiActions.GetManualExclusionModel,
      data: {
        customerId: props.data.customerId,
      },
    })

    Promise.all([formDataPromise, formModelPromise])
      .then((responses) => {
        const manualExclusionFromResponse = (responses[0] as AxiosResponse<BackofficeApiEventResponse>).data.result as IManualExclusion

        setManualExclusion(manualExclusionFromResponse)
        setManualExclusionModel((responses[1] as AxiosResponse<BackofficeApiEventResponse>).data.result as IManualExclusionModel)

        setLoading(false)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [updated])

  const openAllergyReportModal = (customerId: string) => {
    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = 'Allergy report'
    defaultModalProps.description = `Allergy report from Allergy API`
    defaultModalProps.component = CustomerAllergyReport
    defaultModalProps.data = { customerId }
    setModalProps(defaultModalProps)
  }

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSending(true)

    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Customer, {
        action: BackofficeApiActions.SaveManualExclusion,
        data: manualExclusion,
      })

      if (response && response.data && response.data.result.success) {
        setTimeout(() => {
          setSending(false)
          setUpdated(updated + 1)
          setAlerts((prevAlerts) => {
            return [...prevAlerts, <Alert key={getUniqueId('alert')} className="pf-u-mb-md" variant="success" isInline timeout title="Manual exclusions updated" />]
          })
        }, 1000)
      }
    } catch (error) {
      alert(getErrorMessage(error))
      setSending(false)
    }
  }

  const remove = async () => {
    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Customer, {
        action: BackofficeApiActions.DeleteManualExclusion,
        data: {
          customerInformationAllergyId: manualExclusion?.customerInformationAllergyId,
          customerId: manualExclusion?.customerId,
        },
      })

      if (response && response.data && response.data.result.success) {
        typeaheadSelectMultipleRef.current?.clear()
        setUpdated(updated + 1)
        setAlerts((prevAlerts) => {
          return [...prevAlerts, <Alert className="pf-u-mb-md" variant="success" isInline timeout title="Manual exclusion deleted" />]
        })
      } else {
        alert(getErrorMessage(null))
      }
    } catch (error) {
      alert(getErrorMessage(error))
    }
  }

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      {loading && <Spinner />}
      {!loading && manualExclusion && manualExclusionModel && manualExclusionModel.products && (
        <React.Fragment>
          <AlertGroup isLiveRegion>{alerts}</AlertGroup>
          <div className="pf-u-display-flex pf-u-justify-content-flex-end pf-u-mb-md">
            <ActionList>
              <ActionListItem>
                <Button variant="plain" isSmall onClick={() => openAllergyReportModal(props.data.customerId)}>
                  View output from allergy API
                </Button>
              </ActionListItem>
            </ActionList>
          </div>
          <Divider className="pf-u-mb-md"></Divider>
          <Form onSubmit={submit}>
            <FormSection
              title={
                <React.Fragment>
                  Manual exclusion due to allergy
                  {manualExclusion.updatedAt && (
                    <HelperText>
                      <HelperTextItem variant="indeterminate">Last updated at: {moment(manualExclusion.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</HelperTextItem>
                    </HelperText>
                  )}
                </React.Fragment>
              }
              titleElement="h2"
            >
              <FormGroup label="Ignore allergy" fieldId="horizontal-form-exp">
                <TextArea
                  value={manualExclusion.ignore ? manualExclusion.ignore : undefined}
                  id="horizontal-form-exp"
                  name="horizontal-form-exp"
                  onChange={(value) => setManualExclusion({ ...manualExclusion, ignore: value })}
                />
              </FormGroup>
              <FormGroup role="group" fieldId="basic-form-checkbox-group" label="Exlude product groups">
                <Checkbox
                  label="Exclude body"
                  id="excludeBody"
                  isChecked={manualExclusion.excludeBody}
                  onChange={(value) => setManualExclusion({ ...manualExclusion, excludeBody: value })}
                />
                <Checkbox
                  label="Exclude body sunscreen"
                  id="excludeBodySunscreen"
                  isChecked={manualExclusion.excludeBodySunscreen}
                  onChange={(value) => setManualExclusion({ ...manualExclusion, excludeBodySunscreen: value })}
                />
                <Checkbox
                  label="Exclude cleanser"
                  id="excludeCleanser"
                  isChecked={manualExclusion.excludeCleanser}
                  onChange={(value) => setManualExclusion({ ...manualExclusion, excludeCleanser: value })}
                />
                <Checkbox
                  label="Exclude face cream"
                  id="excludeFace"
                  isChecked={manualExclusion.excludeFace}
                  onChange={(value) => setManualExclusion({ ...manualExclusion, excludeFace: value })}
                />
                <Checkbox
                  label="Exclude face cream retinol"
                  id="excludeFaceRetinol"
                  isChecked={manualExclusion.excludeFaceRetinol}
                  onChange={(value) => setManualExclusion({ ...manualExclusion, excludeFaceRetinol: value })}
                />
                <Checkbox
                  label="Exclude face serum"
                  id="excludeFaceSerum"
                  isChecked={manualExclusion.excludeSerum}
                  onChange={(value) => setManualExclusion({ ...manualExclusion, excludeSerum: value })}
                />
                <Checkbox
                  label="Exclude spot treatment"
                  id="excludeSpotTreatment"
                  isChecked={manualExclusion.excludeSpotTreatment}
                  onChange={(value) => setManualExclusion({ ...manualExclusion, excludeSpotTreatment: value })}
                />
                <Checkbox
                  label="Exclude sunscreen"
                  id="excludeSunscreen"
                  isChecked={manualExclusion.excludeSunscreen}
                  onChange={(value) => setManualExclusion({ ...manualExclusion, excludeSunscreen: value })}
                />
              </FormGroup>
              <FormGroup label="Products" fieldId="products">
                <TypeaheadSelectMultiple
                  ref={typeaheadSelectMultipleRef}
                  items={manualExclusionModel.products}
                  placeholder="Exclude specific SKU's..."
                  preselected={manualExclusion.excludeSku?.split(',')}
                  onSelect={(selected) => setManualExclusion({ ...manualExclusion, excludeSku: selected.join(',') })}
                ></TypeaheadSelectMultiple>
              </FormGroup>
            </FormSection>
            <ActionGroup>
              <Button variant="primary" type="submit" isLoading={sending} isDisabled={sending}>
                Save
              </Button>
              {manualExclusion.customerInformationAllergyId && (
                <Button variant="link" isDanger onClick={(e) => (confirm('Delete manual exclusion?') ? remove() : e.preventDefault())}>
                  Delete
                </Button>
              )}
            </ActionGroup>
          </Form>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const CustomerAllergy = React.memo(component)

export { CustomerAllergy }
