import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { Alert, Button, CodeBlock, CodeBlockCode, ExpandableSection, Text } from '@patternfly/react-core'
import { TableComposable, Tbody, Td, Tr } from '@patternfly/react-table'
import { AxiosResponse } from 'axios'
import React from 'react'

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [reload, setReload] = React.useState<number>(0)
  const [views, setViews] = React.useState<Record<string, any>[]>([])

  React.useEffect(() => {
    const abortController = new AbortController()

    api.current
      ?.post(BackofficeAPIConfig.Domains.Insights, {
        action: BackofficeApiActions.GetInsightsViews,
        data: { config: props.data.config },
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          const data = response.data.result['views'] as Record<string, any>[]
          setViews(data)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })

    return () => {
      abortController.abort()
    }
  }, [reload])

  const loadViewClick = (view: Record<string, any>) => {
    props.data['loadView'] = view
    props.closeModal()
  }

  const removeViewClick = async (view: Record<string, any>) => {
    try {
      await api.current?.post(BackofficeAPIConfig.Domains.Insights, {
        action: BackofficeApiActions.RemoveInsightsView,
        data: { viewId: view.viewId },
      })

      setReload(reload + 1)
    } catch (error) {
      alert(getErrorMessage(error))
    }
  }

  const tdStyle: React.CSSProperties = {
    verticalAlign: 'top',
  }

  return (
    <React.Fragment>
      <TableComposable variant="compact">
        <Tbody>
          {views.map((view, index) => (
            <Tr key={index}>
              <Td style={tdStyle}>
                <Button isInline isSmall variant="link" onClick={() => loadViewClick(view)}>
                  {view['viewName']}
                </Button>
              </Td>
              <Td style={tdStyle}>{typeof view['viewDescription'] === 'string' && <Text component="small">{view['viewDescription']}</Text>}</Td>
              <Td style={tdStyle}>
                <Button isInline variant="plain" isSmall onClick={() => removeViewClick(view)}>
                  Remove
                </Button>
              </Td>
              <Td width={50} style={tdStyle}>
                <ExpandableSection toggleText="Show configuration">
                  <CodeBlock>
                    <CodeBlockCode id="code-content" className="pf-u-font-size-xs">
                      {JSON.stringify(view, null, 2)}
                    </CodeBlockCode>
                  </CodeBlock>
                </ExpandableSection>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </TableComposable>

      {views.length === 0 && <Alert variant="info" isInline title="You have no saved views" />}
    </React.Fragment>
  )
}

const LoadViewComponent = React.memo(component)

export { LoadViewComponent }
