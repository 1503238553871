import * as React from 'react'

//components
import {
  Gallery,
  GalleryItem,
  PageSection,
  PageSectionVariants,
  Spinner,
  TextContent,
  TextList,
  TextListItem,
  TextListItemVariants,
  TextListVariants,
} from '@patternfly/react-core'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '../../components/modal/Modal'
import { CardBasicComponent } from '../../components/card/Card'
import { CustomerDatasets } from './Modals/CustomerDatasets'
import { CustomerProductAssignments } from './Modals/CustomerProductAssignments'
import { CustomerOrders } from './Modals/CustomerOrders'
import { CustomerDetails } from './Modals/CustomerDetails'
import { CustomerAllergy } from './Modals/CustomerAllergy'
import { CustomerEvaluations } from './Modals/CustomerEvaluations'
import { CustomerHistory } from './Modals/CustomerHistory'
import { CustomerDraftOrders } from './Modals/CustomerDraftOrders'
import { CustomerImages } from './Modals/CustomerImages'
import { CustomerConversations } from './Modals/CustomerConversations'
import { useParams, useLocation } from 'react-router-dom'
import { CustomerConversation } from './Modals/CustomerConversation'
import { CustomerInternalConversation } from './Modals/CustomerInternalConversation'
import { KeycloakUserRoles, useKeycloakUser } from '@lib/User/useKeycloakUser'
import { usePatientDetails } from '@lib/Patient/usePatientDetails'
import moment from 'moment-timezone'

interface ICustomerDashboardProps extends React.ReactElement {
  id: number
  masterId: number
  isMaster: boolean
}

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const component: React.FunctionComponent<ICustomerDashboardProps> = (props: ICustomerDashboardProps) => {
  const queryParams = useQuery()
  const pathParams = useParams()
  const location = useLocation()
  const user = useKeycloakUser()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const patientDetails = usePatientDetails(props.masterId.toString())
  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const toggleModalProps = (props) => {
    setModalProps(props)
  }

  // Pre-open conversation
  const conversationId: string | undefined = pathParams['conversationId']

  React.useEffect(() => {
    if (conversationId) {
      setModalProps({
        component: CustomerConversation,
        data: { id: conversationId },
        description: `You have a conversation with ${patientDetails?.name}`,
        title: 'Conversation',
      })

      setIsModalOpen(true)
    }
  }, [patientDetails])

  React.useEffect(() => {
    if (queryParams.get('modal')) {
      if (queryParams.get('modal') === 'conversations') {
        setModalProps({
          component: CustomerConversations,
          data: { customerId: props.id },
          description: 'Direct messages between user and advisor',
          title: 'Conversations',
        })

        toggleModal()
      }
    }
  }, [location])

  return (
    <PageSection variant={PageSectionVariants.default}>
      <ModalBasicComponent
        isModalOpen={isModalOpen}
        handleModalToggle={toggleModal}
        modalProps={modalProps}
        toggleModalProps={toggleModalProps}
        key={null}
        type={''}
        props={undefined}
      />

      {typeof patientDetails === 'undefined' && (
        <Gallery>
          <GalleryItem>
            <TextContent className="patient-details-dashboard">
              <TextList component={TextListVariants.dl}>
                <TextListItem component={TextListItemVariants.dt}>
                  <Spinner isSVG size="md"></Spinner>
                </TextListItem>
              </TextList>
            </TextContent>
          </GalleryItem>
        </Gallery>
      )}

      {typeof patientDetails === 'object' && (
        <React.Fragment>
          <Gallery>
            <GalleryItem>
              <TextContent className="patient-details-dashboard">
                <TextList component={TextListVariants.dl}>
                  <TextListItem component={TextListItemVariants.dt}>Name</TextListItem>
                  <TextListItem component={TextListItemVariants.dd}>{patientDetails.name}</TextListItem>
                </TextList>
              </TextContent>
            </GalleryItem>
            <GalleryItem>
              <TextContent className="patient-details-dashboard">
                <TextList component={TextListVariants.dl}>
                  <TextListItem component={TextListItemVariants.dt}>DOB</TextListItem>
                  <TextListItem component={TextListItemVariants.dd}>
                    {moment(patientDetails.dob).format('YYYY-MM-DD')}, {patientDetails.age} years old
                  </TextListItem>
                </TextList>
              </TextContent>
            </GalleryItem>
            <GalleryItem>
              <TextContent className="patient-details-dashboard">
                <TextList component={TextListVariants.dl}>
                  <TextListItem component={TextListItemVariants.dt}>Gender</TextListItem>
                  <TextListItem component={TextListItemVariants.dd}>{patientDetails.gender}</TextListItem>
                </TextList>
              </TextContent>
            </GalleryItem>
          </Gallery>
        </React.Fragment>
      )}

      <Gallery
        hasGutter
        minWidths={{
          md: '100px',
          lg: '150px',
          xl: '200px',
          '2xl': '300px',
        }}
      >
        {props.isMaster && (
          <React.Fragment>
            <GalleryItem>
              <CardBasicComponent
                title={'Account details'}
                description={'See user contact information'}
                body={'See user contact information'}
                toggleModal={toggleModal}
                toggleModalProps={toggleModalProps}
                key={null}
                type={''}
                props={undefined}
                component={CustomerDetails}
                data={{ customerId: props.id }}
              />
            </GalleryItem>
            <GalleryItem>
              <CardBasicComponent
                title={'Profile and recommendation'}
                description={`Patient name: ${patientDetails?.name}`}
                body={'See / edit survey and manage recommendation'}
                toggleModal={toggleModal}
                toggleModalProps={toggleModalProps}
                key={null}
                type={''}
                props={undefined}
                component={CustomerDatasets}
                data={{ customerId: props.id }}
              />
            </GalleryItem>
            <GalleryItem>
              <CardBasicComponent
                title={'Images'}
                description={`Patient name: ${patientDetails?.name}`}
                body={'See images uploaded by patient and track progress'}
                toggleModal={toggleModal}
                toggleModalProps={toggleModalProps}
                key={null}
                type={''}
                props={undefined}
                component={CustomerImages}
                data={{ customerId: props.id }}
              />
            </GalleryItem>
            <GalleryItem>
              <CardBasicComponent
                title={'Messages'}
                description={`Patient name: ${patientDetails?.name}`}
                body={'Conversation between user and advisor'}
                toggleModal={toggleModal}
                toggleModalProps={toggleModalProps}
                key={null}
                type={''}
                props={undefined}
                component={CustomerConversations}
                data={{ customerId: props.id }}
              />
            </GalleryItem>
            <GalleryItem>
              <CardBasicComponent
                title={'Notes'}
                description={`Patient name: ${patientDetails?.name}`}
                body={'Read and write internal notes on the patient and their treatment'}
                toggleModal={toggleModal}
                toggleModalProps={toggleModalProps}
                key={null}
                type={''}
                props={undefined}
                component={CustomerInternalConversation}
                data={{ customerId: props.id }}
              />
            </GalleryItem>

            {user.current?.isInRole(KeycloakUserRoles.BackofficeManagement) && (
              <React.Fragment>
                <GalleryItem>
                  <CardBasicComponent
                    title={'Orders'}
                    description={`Patient name: ${patientDetails?.name}`}
                    body={'View customer orders'}
                    toggleModal={toggleModal}
                    toggleModalProps={toggleModalProps}
                    key={null}
                    type={''}
                    props={undefined}
                    component={CustomerOrders}
                    data={{ customerId: props.id }}
                  />
                </GalleryItem>
                <GalleryItem>
                  <CardBasicComponent
                    title={'Draft orders'}
                    description={`Patient name: ${patientDetails?.name}`}
                    body={'View and create draft orders'}
                    toggleModal={toggleModal}
                    toggleModalProps={toggleModalProps}
                    key={null}
                    type={''}
                    props={undefined}
                    component={CustomerDraftOrders}
                    data={{ customerId: props.id }}
                  />
                </GalleryItem>
                <GalleryItem>
                  <CardBasicComponent
                    title={'History'}
                    description={`Patient name: ${patientDetails?.name}`}
                    body={'Patient history including sub accounts'}
                    toggleModal={toggleModal}
                    toggleModalProps={toggleModalProps}
                    key={null}
                    type={''}
                    props={undefined}
                    component={CustomerHistory}
                    data={{ customerId: props.id }}
                  />
                </GalleryItem>
                <GalleryItem>
                  <CardBasicComponent
                    title={'Products'}
                    description={`Patient name: ${patientDetails?.name}`}
                    body={'Product recommendation and manual assignments'}
                    toggleModal={toggleModal}
                    toggleModalProps={toggleModalProps}
                    key={null}
                    type={''}
                    props={undefined}
                    component={CustomerProductAssignments}
                    data={{ customerId: props.id }}
                  />
                </GalleryItem>
                <GalleryItem>
                  <CardBasicComponent
                    title={'Evaluations'}
                    description={`Patient name: ${patientDetails?.name}`}
                    body={'Product evaluations this user made'}
                    toggleModal={toggleModal}
                    toggleModalProps={toggleModalProps}
                    key={null}
                    type={''}
                    props={undefined}
                    component={CustomerEvaluations}
                    data={{ customerId: props.id }}
                  />
                </GalleryItem>
                <GalleryItem>
                  <CardBasicComponent
                    title={'Allergy'}
                    description={`Patient name: ${patientDetails?.name}`}
                    body={'Allergy Data'}
                    toggleModal={toggleModal}
                    toggleModalProps={toggleModalProps}
                    key={null}
                    type={''}
                    props={undefined}
                    component={CustomerAllergy}
                    data={{ customerId: props.id }}
                  />
                </GalleryItem>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {!props.isMaster && (
          <React.Fragment>
            <GalleryItem>
              <CardBasicComponent
                title={'Profile and recommendation'}
                description={`Patient name: ${patientDetails?.name}`}
                body={'See / edit survey and manage recommendation'}
                toggleModal={toggleModal}
                toggleModalProps={toggleModalProps}
                key={null}
                type={''}
                props={undefined}
                component={CustomerDatasets}
                data={{ customerId: props.id }}
              />
            </GalleryItem>
            <GalleryItem>
              <CardBasicComponent
                title={'Products'}
                description={`Patient name: ${patientDetails?.name}`}
                body={'Product recommendation and manual assignments'}
                toggleModal={toggleModal}
                toggleModalProps={toggleModalProps}
                key={null}
                type={''}
                props={undefined}
                component={CustomerProductAssignments}
                data={{ customerId: props.id }}
              />
            </GalleryItem>
            <GalleryItem>
              <CardBasicComponent
                title={'Evaluations'}
                description={`Patient name: ${patientDetails?.name}`}
                body={'Product evaluations this user made'}
                toggleModal={toggleModal}
                toggleModalProps={toggleModalProps}
                key={null}
                type={''}
                props={undefined}
                component={CustomerEvaluations}
                data={{ customerId: props.id }}
              />
            </GalleryItem>
            <GalleryItem>
              <CardBasicComponent
                title={'Allergy'}
                description={`Patient name: ${patientDetails?.name}`}
                body={'Allergy Data'}
                toggleModal={toggleModal}
                toggleModalProps={toggleModalProps}
                key={null}
                type={''}
                props={undefined}
                component={CustomerAllergy}
                data={{ customerId: props.id }}
              />
            </GalleryItem>
          </React.Fragment>
        )}
      </Gallery>
    </PageSection>
  )
}

const CustomerDashboard = React.memo(component)

export { CustomerDashboard }
