import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { IBayesianModel } from '@lib/Model/BayesianModel'
import { Button, Label, Spinner, Text, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem } from '@patternfly/react-core'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { AxiosResponse } from 'axios'
import moment from 'moment-timezone'
import React from 'react'
import { BMClusterRules } from '../Modals/BMClusterRules'
import { BMCreateNewCluster } from '../Modals/BMCreateNewCluster'
import { BMClusterPriors } from '../Modals/BMClusterPriors'

interface Props {
  data: IBayesianModel
}

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const defaultModalProps: IModalBasicComponentModalProps = {
  component: DefaultModalComponent,
  description: '',
  title: '',
  data: {},
}

const columnNames = {
  id: 'Cluster ID',
  productType: 'Product type',
  status: 'Status',
  created: 'Created',
  lastModified: 'Last modified',
  actions: 'Actions',
}

const BMClusters: React.FunctionComponent<Props> = (props: Props) => {
  const model = props.data
  const api = useAxios()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [clusters, setClusters] = React.useState<[]>([])
  const [reloadClusters, setReloadClusters] = React.useState<number>(0)

  React.useEffect(() => {
    const abortController = new AbortController()

    setLoading(true)

    api.current
      ?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.ListBMModelClusters,
        data: {
          modelId: model.modelId,
        },
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          setClusters(response.data.result as [])
          setLoading(false)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [reloadClusters])

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const toggleModal = (id: string) => {
    setIsModalOpen(!isModalOpen)

    if (id === modalId) {
      setReloadClusters(reloadClusters + 1)
    }
  }

  const openModal = (cluster: Record<string, any>) => {
    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = 'Model cluster rules'
    defaultModalProps.description = ''
    defaultModalProps.component = BMClusterRules
    defaultModalProps.data = cluster
    defaultModalProps.id = 'model-cluster-rules-modal'
    setModalProps(defaultModalProps)
  }

  const modalId = 'cluster-create-new-modal'
  const openCreateClusterModal = () => {
    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = `Create new cluster for model ${model.modelName}`
    defaultModalProps.description = ''
    defaultModalProps.component = BMCreateNewCluster
    defaultModalProps.id = modalId
    defaultModalProps.data = model
    setModalProps(defaultModalProps)
  }

  const priorsModalId = 'manage-priors-modal'
  const openManagePriorsModal = (cluster) => {
    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = `Manage priors for cluster ${cluster['clusterId']}`
    defaultModalProps.description = ''
    defaultModalProps.component = BMClusterPriors
    defaultModalProps.id = priorsModalId
    defaultModalProps.data = { cluster, model }
    setModalProps(defaultModalProps)
  }

  const deleteCluster = async (cluster) => {
    if (!confirm(`Delete cluster ${cluster['clusterId']}?`)) return

    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.DeleteBMCluster,
        data: {
          clusterId: cluster['clusterId'],
        },
      })

      if (response?.status === 200) {
        setReloadClusters(reloadClusters + 1)
      } else {
        throw new Error(`Error: ${JSON.stringify(response?.data)}`)
      }
    } catch (error) {
      alert(getErrorMessage(error))
    }
  }

  const setClusterStatus = async (cluster, isActive) => {
    if (isActive) {
      if (!confirm(`Activate cluster ${cluster['clusterId']}?`)) return
    } else {
      if (!confirm(`Deactivate cluster ${cluster['clusterId']}?`)) return
    }

    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.SetStatusBMCluster,
        data: {
          clusterId: cluster['clusterId'],
          isActive,
        },
      })

      if (response?.status === 200) {
        setReloadClusters(reloadClusters + 1)
      } else {
        throw new Error(`Error: ${JSON.stringify(response?.data)}`)
      }
    } catch (error) {
      alert(getErrorMessage(error))
    }
  }

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      <Toolbar usePageInsets id="compact-toolbar">
        <ToolbarContent>
          <ToolbarGroup>
            <ToolbarItem>
              <Button variant="primary" isSmall onClick={() => openCreateClusterModal()}>
                Create a cluster
              </Button>
            </ToolbarItem>
          </ToolbarGroup>
        </ToolbarContent>
      </Toolbar>
      {loading && <Spinner />}
      {!loading && clusters.length > 0 && (
        <React.Fragment>
          <TableComposable className="vertical-align-middle" variant={'compact'} borders={true} isStriped>
            <Thead>
              <Tr>
                <Th>{columnNames.id}</Th>
                <Th>{columnNames.productType}</Th>
                <Th>{columnNames.status}</Th>
                <Th>{columnNames.created}</Th>
                <Th>{columnNames.lastModified}</Th>
                <Th width={30}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {clusters.map((cluster, index) => (
                <Tr key={index}>
                  <Td dataLabel={columnNames.id}>{cluster['clusterId']}</Td>
                  <Td dataLabel={columnNames.productType}>
                    <Label isCompact color="cyan">
                      {cluster['productType']}
                    </Label>
                  </Td>
                  <Td dataLabel={columnNames.status}>
                    {cluster['isActive'] && (
                      <Label isCompact color="green">
                        active
                      </Label>
                    )}
                    {cluster['isActive'] === false && (
                      <Label isCompact color="red">
                        inactive
                      </Label>
                    )}
                  </Td>
                  <Td dataLabel={columnNames.created}>{moment(cluster['createdAt']).format('YYYY-MM-DD HH:mm:ss')}</Td>
                  <Td dataLabel={columnNames.lastModified}>{moment(cluster['updatedAt']).format('YYYY-MM-DD HH:mm:ss')}</Td>
                  <Td dataLabel={columnNames.actions}>
                    <Button variant="plain" isSmall onClick={() => openModal(cluster)}>
                      View rules
                    </Button>{' '}
                    <Button variant="plain" isSmall onClick={() => openManagePriorsModal(cluster)}>
                      Manage priors
                    </Button>{' '}
                    {cluster['isActive'] === true && (
                      <Button variant="link" isDanger isSmall onClick={() => setClusterStatus(cluster, false)}>
                        Deactivate
                      </Button>
                    )}
                    {cluster['isActive'] === false && (
                      <Button variant="link" isDanger isSmall onClick={() => setClusterStatus(cluster, true)}>
                        Activate
                      </Button>
                    )}
                    <Button variant="link" isDanger isSmall onClick={() => deleteCluster(cluster)}>
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </TableComposable>
        </React.Fragment>
      )}
      {!loading && clusters.length === 0 && <Text className="pf-u-p-lg">This model does not have any clusters.</Text>}
    </React.Fragment>
  )
}

export { BMClusters }
