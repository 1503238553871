import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { Banner, Flex, FlexItem, Gallery, GalleryItem, ModalVariant, Spinner, Truncate } from '@patternfly/react-core'
import { AxiosResponse } from 'axios'
import moment from 'moment-timezone'
import React from 'react'
import { CustomerImage } from './CustomerImage'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { InfoCircleIcon } from '@patternfly/react-icons'
import { usePatientDetails } from '@lib/Patient/usePatientDetails'

interface IImageDTO {
  id: string
  url: string
  created: Date
  source: string
}

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [loading, setLoading] = React.useState(true)
  const [images, setImages] = React.useState<IImageDTO[]>([])
  const patientDetails = usePatientDetails(props.data.customerId)

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetImages,
      data: {
        customerId: props.data.customerId,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const images = response?.data.result as IImageDTO[]

        setImages(images)
        setLoading(false)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const columnNames = {
    name: 'Image name',
    source: 'Source',
    created: 'Created',
    thumbnail: 'Thumbnail',
  }

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const toggleModal = (id: string) => {
    setIsModalOpen(!isModalOpen)
  }

  const imageClick = (e: React.MouseEvent, value: string) => {
    e.preventDefault()

    defaultModalProps.title = 'Image'
    defaultModalProps.description = `Patient name: ${patientDetails?.name}, age: ${patientDetails?.age}, gender: ${patientDetails?.gender}`
    defaultModalProps.component = CustomerImage
    defaultModalProps.data = { value }
    defaultModalProps.size = ModalVariant.default

    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)
  }

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      {loading && <Spinner />}
      {!loading && (
        <React.Fragment>
          <Gallery hasGutter minWidths={{ lg: '200px' }} className="customer-image-gallery">
            {images.map((image, index) => (
              <GalleryItem key={index} onClick={(e) => imageClick(e, image.url)} className="customer-image-gallery-item">
                <div className="customer-image-gallery-item-img-wrap">
                  <img
                    className="customer-image-gallery-item-img"
                    src={`${BackofficeAPIConfig.MediaURL}?url=${image.url}&width=250&height=250`}
                    onError={(e) => {
                      e.currentTarget.parentElement?.classList.add('customer-image-gallery-item-img-wrap-error')
                    }}
                  />
                </div>
                <div className="customer-image-gallery-item-desc">
                  <div>
                    <b>Created:</b> {moment(image.created).format('YYYY-MM-DD')}
                  </div>
                  <div>
                    <b>Source:</b>{' '}
                    <span style={{ display: 'inline-block', maxWidth: '135px' }}>
                      <Truncate content={image.source} />
                    </span>
                  </div>
                </div>
              </GalleryItem>
            ))}
          </Gallery>
          {images.length === 0 && (
            <React.Fragment>
              <Banner screenReaderText="Info banner" variant="info">
                <Flex spaceItems={{ default: 'spaceItemsSm' }}>
                  <FlexItem>
                    <InfoCircleIcon />
                  </FlexItem>
                  <FlexItem>User hasn't uploaded any images yet.</FlexItem>
                </Flex>
              </Banner>
              <br />
              <br />
              <br />
              <br />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const CustomerImages = React.memo(component)

export { CustomerImages }
