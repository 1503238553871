import { CodeBlock, CodeBlockCode } from '@patternfly/react-core'
import React from 'react'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  return (
    <CodeBlock>
      <CodeBlockCode id="code-content">{JSON.stringify(props.data, null, 2)}</CodeBlockCode>
    </CodeBlock>
  )
}

const CustomerHistoryEventDetails = React.memo(component)

export { CustomerHistoryEventDetails }
