import { BasicSelect } from '@app/components/select/BasicSelect'
import { TypeaheadSelect } from '@app/components/select/TypeaheadSelect'
import { TypeaheadSelectMultiple } from '@app/components/select/TypeaheadSelectMultiple'
import { cloneDeep } from 'lodash'
import update from 'immutability-helper'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import {
  ActionGroup,
  Button,
  Chip,
  ChipGroup,
  DatePicker,
  Form,
  FormFieldGroupExpandable,
  FormFieldGroupHeader,
  FormFieldGroupHeaderTitleTextObject,
  FormGroup,
  FormSection,
  InputGroup,
  InputGroupText,
  NumberInput,
  Radio,
  Spinner,
  TextInput,
  TextInputGroup,
  TextInputGroupMain,
} from '@patternfly/react-core'
import { PlusCircleIcon, TrashIcon } from '@patternfly/react-icons'
import { AxiosError, AxiosResponse } from 'axios'
import * as React from 'react'
import moment from 'moment-timezone'
import { IAccount } from '@lib/DraftOrder/IAccount'
import { IProduct } from '@lib/DraftOrder/IProduct'
import { IDraftOrderModel } from '@lib/DraftOrder/IDraftOrderModel'
import { IBasicSelectOption } from '@lib/Core/Select'
import { IDraftOrderFormData } from '@lib/DraftOrder/IDraftOrderFormData'
import { IOrder } from '../IOrder'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

type BasicSelectHandle = React.ElementRef<typeof BasicSelect>
type TypeaheadSelectHandle = React.ElementRef<typeof TypeaheadSelectMultiple>

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()

  const initialDraftOrderForm: IDraftOrderFormData = {
    customerId: parseInt(props.data.customerId),
    createManualAssignment: true,
    manualAssignmentExpiry: moment(new Date()).add(1, 'day').format('YYYY-MM-DD'),
    sendInvoice: false,
    products: [],
    tags: [],
  }

  const [previousOrders, setPreviousOrders] = React.useState<IOrder[]>([])
  const [draftOrderModel, setDraftOrderModel] = React.useState<IDraftOrderModel>()
  const [draftOrderFormData, setDraftOrderFormData] = React.useState<IDraftOrderFormData>(cloneDeep(initialDraftOrderForm))

  const [reload, setReload] = React.useState<number>(0)
  const [sending, setSending] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    const abortController = new AbortController()

    const draftOrderModelRequestPromise = api.current?.post(BackofficeAPIConfig.Domains.Customer, {
      action: BackofficeApiActions.GetDraftOrderModel,
      data: { customerId: props.data.customerId },
    })

    const ordersRequestPromise = api.current?.post(BackofficeAPIConfig.Domains.Customer, {
      action: BackofficeApiActions.GetOrders,
      data: { customerId: props.data.customerId },
    })

    Promise.all([draftOrderModelRequestPromise, ordersRequestPromise])
      .then((responses) => {
        const draftOrderModelResponse = responses[0] as AxiosResponse<BackofficeApiEventResponse>

        if (draftOrderModelResponse && draftOrderModelResponse.data && draftOrderModelResponse.data.result) {
          setDraftOrderModel(draftOrderModelResponse.data.result as IDraftOrderModel)
        }

        const ordersResponse = responses[1] as AxiosResponse<BackofficeApiEventResponse>

        if (ordersResponse && ordersResponse.data && ordersResponse.data.result) {
          const previousOrders = ordersResponse.data.result as IOrder[]

          setPreviousOrders(previousOrders.filter((p) => p.orderName.toLowerCase() !== 'essential'))
        }

        setLoading(false)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [reload])

  const addNewProduct = () => {
    const product: IProduct = {
      account: draftOrderModel?.accounts[0] as IAccount,
      usagePattern: draftOrderModel?.usagePatterns[2].key as string,
      quantity: 1,
      sku: '',
      hasCustomLabel: false,
    }

    if (draftOrderFormData) {
      if (!draftOrderFormData.products) {
        draftOrderFormData.products = []
      }

      setDraftOrderFormData({ ...draftOrderFormData, products: [...(draftOrderFormData?.products as []), ...[product]] })
    }
  }

  const updateProduct = (productIndex: number, itemId: string) => {
    const data = update(draftOrderFormData, {
      products: {
        [productIndex]: {
          sku: { $set: itemId },
        },
      },
    })

    setDraftOrderFormData(data)
  }

  const updateProductQuantity = (productIndex: number, newValue: number) => {
    const data = update(draftOrderFormData, {
      products: {
        [productIndex]: {
          quantity: { $set: newValue },
        },
      },
    })

    setDraftOrderFormData(data)
  }

  const updateProductAccount = (productIndex: number, customerId: number) => {
    let account: IAccount
    let hasCustomLabel: boolean = false

    if (customerId === 0) {
      const masterId = draftOrderModel?.accounts.find((p) => p.isMaster)?.customerId

      account = {
        customerId: masterId ? masterId : customerId,
        isMaster: true,
        name: '',
      }

      hasCustomLabel = true
    } else {
      account = draftOrderModel?.accounts.find((p) => p.customerId === customerId) as IAccount
    }

    const data = update(draftOrderFormData, {
      products: {
        [productIndex]: {
          account: { $set: account },
          hasCustomLabel: { $set: hasCustomLabel },
        },
      },
    })

    setDraftOrderFormData(data)
  }

  const updateOtherProductLabel = (productIndex: number, value: string) => {
    const data = update(draftOrderFormData, {
      products: {
        [productIndex]: {
          account: {
            name: { $set: value },
          },
        },
      },
    })

    setDraftOrderFormData(data)
  }

  const getProductTitleText = (productIndex: number): FormFieldGroupHeaderTitleTextObject => {
    let title = '...'

    if (draftOrderFormData && draftOrderFormData.products && draftOrderFormData.products[productIndex] && draftOrderFormData.products[productIndex].sku) {
      const sku = draftOrderFormData.products[productIndex].sku as string
      title = draftOrderModel?.products.find((p) => p.itemId === sku)?.children as string
    }

    return {
      id: `product-title-${productIndex}`,
      text: title,
    }
  }

  const getAccountSelectDisplayName = (account: IAccount | undefined): string => {
    if (!account) {
      return 'Select...'
    }

    return `${account.customerId}: ${account.name} ${account.isMaster ? '(main profile)' : ''}`
  }

  const getAccountsForProductSelect = (): IBasicSelectOption[] => {
    const items: IBasicSelectOption[] = []

    if (draftOrderModel) {
      items.push(
        ...draftOrderModel.accounts.map((i) => {
          return { key: i.customerId, value: getAccountSelectDisplayName(i) }
        })
      )
    }

    items.push({
      key: 0,
      value: 'Other',
    })

    return items
  }

  const updateProductUsagePattern = (productIndex: number, usagePattern: string) => {
    const data = update(draftOrderFormData, {
      products: {
        [productIndex]: {
          usagePattern: { $set: usagePattern },
        },
      },
    })

    setDraftOrderFormData(data)
  }

  const removeProduct = (index: number) => {
    const data = update(draftOrderFormData, {
      products: {
        $splice: [[index, 1]],
      },
    })

    setDraftOrderFormData(data)
  }

  const storeBasicSelectRef = React.useRef<BasicSelectHandle>(null)
  const tagsBasicSelectRef = React.useRef<TypeaheadSelectHandle>(null)
  const fillFromPreviousOrder = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, order: IOrder) => {
    e.preventDefault()

    if (!draftOrderModel) return

    const draftOrder = cloneDeep(initialDraftOrderForm) as IDraftOrderFormData

    // Store
    const storeLocale = order.orderName.toLowerCase().startsWith('en') ? 'en' : 'da'
    const store = draftOrderModel.stores.find((p) => p.locale === storeLocale)
    draftOrder.store = store
    storeBasicSelectRef.current?.set(store ? store.name : '')

    // Tags
    const tags = [draftOrderModel.orderTags[0].itemId]
    tagsBasicSelectRef.current?.set(tags)
    draftOrder.tags = tags

    // Manual assignment
    draftOrder.createManualAssignment = false

    // Products
    const products: IProduct[] = order.items.map((order) => {
      return {
        account: draftOrderModel?.accounts[0] as IAccount,
        usagePattern: order.properties['_usage_pattern'] as string,
        quantity: order.quantity,
        sku: order.sku,
        hasCustomLabel: false,
      }
    })
    draftOrder.products = products

    setDraftOrderFormData(draftOrder)
  }

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSending(true)

    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Customer, {
        action: BackofficeApiActions.CreateDraftOrder,
        data: draftOrderFormData,
      })

      if (response && response.data && response.data.result.success) {
        setTimeout(() => {
          setSending(false)
          props.closeModal()
        }, 3000)
      }
    } catch (error) {
      setSending(false)
      alert(getErrorMessage(error))
    }
  }

  return (
    <React.Fragment>
      {loading && <Spinner />}
      {draftOrderModel && !loading && (
        <Form isHorizontal onSubmit={submit} className="draft-order-form">
          {/** --------------------------- Start order properties --------------------------- */}
          <FormSection title="Order properties" titleElement="h2">
            {previousOrders.length > 0 && (
              <FormGroup label="Fill from previous order" fieldId="txt-previous-order">
                <TextInputGroup>
                  <TextInputGroupMain>
                    <ChipGroup isClosable={false} numChips={10}>
                      {previousOrders.map((previousOrder) => (
                        <Chip key={previousOrder.customerOrderId} isReadOnly={true} textMaxWidth={'160px'}>
                          <a href="#" onClick={(e) => fillFromPreviousOrder(e, previousOrder)}>
                            {previousOrder.orderName} ({moment(previousOrder.openedAt).format('YYYY-MM-DD')})
                          </a>
                        </Chip>
                      ))}
                    </ChipGroup>
                  </TextInputGroupMain>
                </TextInputGroup>
              </FormGroup>
            )}

            <FormGroup label="Store" isRequired fieldId="txt-store">
              <BasicSelect
                id="txt-store"
                initialValue={'Select...'}
                ref={storeBasicSelectRef}
                items={draftOrderModel.stores.map((p) => {
                  return { key: p.name, value: p.name }
                })}
                onSelect={(item) => setDraftOrderFormData({ ...draftOrderFormData, store: draftOrderModel.stores.find((p) => p.name === item.key) })}
              ></BasicSelect>
            </FormGroup>

            <FormGroup label="Order tags" fieldId="txt-tags">
              <TypeaheadSelectMultiple
                items={draftOrderModel.orderTags}
                placeholder="Assign tags..."
                ref={tagsBasicSelectRef}
                onSelect={(selected) => setDraftOrderFormData({ ...draftOrderFormData, tags: selected })}
              ></TypeaheadSelectMultiple>
            </FormGroup>

            <FormGroup label="Discount" fieldId="txt-discount">
              <InputGroup>
                <BasicSelect
                  onSelect={(item) => setDraftOrderFormData({ ...draftOrderFormData, discountType: item.key.toString() })}
                  id="txt-discount-type"
                  initialValue={'Select...'}
                  items={draftOrderModel.discountTypes}
                ></BasicSelect>
                <TextInput
                  id="txt-discount-amount"
                  value={draftOrderFormData?.discountAmount ?? ''}
                  onChange={(value) => setDraftOrderFormData({ ...draftOrderFormData, discountAmount: value })}
                />
                <InputGroupText id="txt-discount-label">
                  {draftOrderFormData?.discountType === 'percentage' && '%'}
                  {draftOrderFormData?.discountType === 'fixed_amount' && draftOrderFormData?.store && `${draftOrderFormData?.store.currency}`}
                </InputGroupText>
              </InputGroup>
            </FormGroup>

            <FormGroup isInline hasNoPaddingTop fieldId="create-manual-assignment" label="Create manual assignment?">
              <Radio
                name="create-manual-assignment"
                label="Yes"
                id="create-manual-assignment-yes"
                isChecked={draftOrderFormData?.createManualAssignment}
                onChange={(isChecked) => setDraftOrderFormData({ ...draftOrderFormData, createManualAssignment: isChecked })}
              />
              <Radio
                name="create-manual-assignment"
                label="No"
                id="create-manual-assignment-no"
                isChecked={!draftOrderFormData?.createManualAssignment}
                onChange={(isChecked) => setDraftOrderFormData({ ...draftOrderFormData, createManualAssignment: !isChecked })}
              />
            </FormGroup>

            <FormGroup label="Expires" fieldId="txt-expires">
              <DatePicker
                value={draftOrderFormData.manualAssignmentExpiry}
                onChange={(_event, value) => setDraftOrderFormData({ ...draftOrderFormData, manualAssignmentExpiry: value })}
              />
            </FormGroup>

            <FormGroup isInline hasNoPaddingTop fieldId="send-invoice" label="Send invoice?">
              <Radio
                name="send-invoice"
                label="Yes"
                id="send-invoice-yes"
                isChecked={draftOrderFormData?.sendInvoice}
                onChange={(isChecked) => setDraftOrderFormData({ ...draftOrderFormData, sendInvoice: isChecked })}
              />
              <Radio
                name="send-invoice"
                label="No"
                id="send-invoice-no"
                isChecked={!draftOrderFormData?.sendInvoice}
                onChange={(isChecked) => setDraftOrderFormData({ ...draftOrderFormData, sendInvoice: !isChecked })}
              />
            </FormGroup>
          </FormSection>
          {/** --------------------------- end order properties --------------------------- */}

          {/** --------------------------- Start products section --------------------------- */}
          <FormSection title="Order products" titleElement="h2">
            <FormGroup
              label="Products"
              fieldId="txt-products"
              isRequired
              labelIcon={
                <Button variant="plain" onClick={() => addNewProduct()}>
                  <PlusCircleIcon />
                </Button>
              }
            >
              {draftOrderFormData?.products?.map((product, index) => (
                <FormFieldGroupExpandable
                  className="pf-u-mb-lg"
                  isExpanded
                  key={index}
                  header={
                    <FormFieldGroupHeader
                      titleText={getProductTitleText(index)}
                      actions={
                        <Button variant="plain" aria-label="Remove" onClick={() => removeProduct(index)}>
                          <TrashIcon />
                        </Button>
                      }
                    />
                  }
                >
                  <FormGroup label="Product" isRequired fieldId="product">
                    <TypeaheadSelect items={draftOrderModel.products} placeholder="Select a product..." onSelect={(itemId) => updateProduct(index, itemId)}></TypeaheadSelect>
                  </FormGroup>
                  <FormGroup label="Usage pattern" isRequired fieldId="usage-pattern">
                    <BasicSelect
                      id="txt-usage-pattern"
                      initialValue={(draftOrderFormData.products as IProduct[])[index].usagePattern}
                      items={draftOrderModel.usagePatterns}
                      onSelect={(item) => updateProductUsagePattern(index, item.key as string)}
                    ></BasicSelect>
                  </FormGroup>
                  <FormGroup label="Quantity" isRequired fieldId="quantity">
                    <NumberInput
                      value={product.quantity}
                      min={1}
                      max={100}
                      onMinus={() => updateProductQuantity(index, product.quantity - 1)}
                      onChange={(e) => updateProductQuantity(index, parseInt((e.target as HTMLInputElement).value))}
                      onPlus={() => updateProductQuantity(index, product.quantity + 1)}
                    />
                  </FormGroup>
                  <FormGroup label="Profile" isRequired fieldId="profile">
                    <BasicSelect
                      id="txt-profile"
                      initialValue={getAccountSelectDisplayName((draftOrderFormData.products as IProduct[])[index].account)}
                      items={getAccountsForProductSelect()}
                      onSelect={(item) => updateProductAccount(index, item.key as number)}
                    ></BasicSelect>
                  </FormGroup>
                  {product.hasCustomLabel && (
                    <FormGroup label="Label" fieldId="custom_label">
                      <TextInput id="txt-custom-label" name="custom-label" type="text" onChange={(value) => updateOtherProductLabel(index, value)}></TextInput>
                    </FormGroup>
                  )}
                </FormFieldGroupExpandable>
              ))}
            </FormGroup>
          </FormSection>

          {/** --------------------------- end products section --------------------------- */}

          <ActionGroup className="pf-u-mt-0">
            <Button variant="primary" type="submit" isLoading={sending} isDisabled={sending}>
              Submit
            </Button>
            <Button variant="secondary" type="reset" isDisabled={sending} onClick={() => props.closeModal()}>
              Cancel
            </Button>
          </ActionGroup>
        </Form>
      )}
    </React.Fragment>
  )
}

const CustomerDraftOrderForm = React.memo(component)

export { CustomerDraftOrderForm }
