import { useAxios } from '@app/utils/useAxios'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { IPatientConversationAttachment } from '@lib/PatientConversation/IPatientConversationAttachment'
import { ActionGroup, Button, Chip, ChipGroup, Form, FormGroup, TextArea } from '@patternfly/react-core'
import { useKeycloak } from '@react-keycloak/web'
import React from 'react'
import { processAttachments } from '@lib/Attachments/processAttachments'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

interface ICreatePatientConversationDTO {
  customerId: number
  author: string
  content: string
  attachments: IPatientConversationAttachment[]
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [sending, setSending] = React.useState<boolean>(false)
  const { keycloak } = useKeycloak()
  const authorId = keycloak && keycloak.tokenParsed ? keycloak.tokenParsed['email'] : ''
  const [conversationFormData, setConversationFormData] = React.useState<ICreatePatientConversationDTO>({
    author: authorId,
    customerId: props.data.customerId,
    content: '',
    attachments: [],
  })
  const [files, setFiles] = React.useState<File[]>([])

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSending(true)

    let attachments: IPatientConversationAttachment[] = []

    if (files.length && api.current) {
      attachments = await processAttachments(props.data.customerId, files, 'patient-conversation', api.current)
    }

    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Customer, {
        action: BackofficeApiActions.CreatePatientConversation,
        data: {
          ...conversationFormData,
          attachments,
        },
      })

      if (response && response.data && response.data.result.success) {
        setSending(false)
        conversationFormData.content = ''

        setTimeout(() => {
          props.closeModal()
        }, 500)
      } else {
        setSending(false)
        alert(getErrorMessage(null))
      }
    } catch (error) {
      setSending(false)
      alert(getErrorMessage(error))
    }
  }

  const onFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const txtFileInput = e.target as HTMLInputElement

    if (txtFileInput && txtFileInput.files) {
      const file = txtFileInput.files[0]
      setFiles((files) => [...files, file])
      txtFileInput.value = ''
    }
  }

  const removeFile = (_file: File) => {
    setFiles(files.filter((file) => file.name !== _file.name))
  }

  return (
    <React.Fragment>
      <Form onSubmit={submit}>
        <FormGroup>
          <TextArea
            id="txt-message"
            onChange={(value) => setConversationFormData({ ...conversationFormData, content: value })}
            rows={8}
            readOnly={sending}
            value={conversationFormData.content}
          ></TextArea>
        </FormGroup>
        {files.length > 0 && (
          <ChipGroup categoryName="Attachments:">
            {files.map((file, index) => (
              <Chip key={index} textMaxWidth={'200px'} onClick={() => removeFile(file)}>
                {file.name}
              </Chip>
            ))}
          </ChipGroup>
        )}
        <ActionGroup>
          <Button variant="primary" type="submit" isLoading={sending} isDisabled={sending || !conversationFormData.content}>
            Send
          </Button>
          <label className="pf-c-button pf-m-inline pf-m-link pf-u-mr-lg" htmlFor="file" style={{ cursor: 'pointer', lineHeight: '33px' }}>
            Select attachments
          </label>
          <input id="file" type="file" onChange={(e) => onFileChanged(e)} style={{ opacity: 0, position: 'absolute', zIndex: -1 }}></input>
        </ActionGroup>
      </Form>
    </React.Fragment>
  )
}

const CustomerConversationCreateForm = React.memo(component)

export { CustomerConversationCreateForm }
