import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { BasicSelect } from '@app/components/select/BasicSelect'
import { TypeaheadSelect } from '@app/components/select/TypeaheadSelect'
import { TypeaheadSelectMultiple } from '@app/components/select/TypeaheadSelectMultiple'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { IBasicSelectOption } from '@lib/Core/Select'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { IBayesianModel, IBayesianModelCluster } from '@lib/Model/BayesianModel'
import { ActionGroup, Button, Checkbox, Form, FormGroup, FormSection, Grid, GridItem, Spinner, Switch, TextInput } from '@patternfly/react-core'
import { SelectOptionProps } from '@patternfly/react-core/next'
import { PlusIcon } from '@patternfly/react-icons'
import { AxiosResponse } from 'axios'
import React from 'react'

enum IRuleType {
  Equals = 'equals',
  Greater = 'greater',
  Lesser = 'lesser',
  In = 'range',
}

const ruleTypes: string[] = Object.values(IRuleType)
  .filter((p) => typeof p === 'string')
  .map((p) => {
    return p.toString()
  })

enum IRuleValueType {
  'string',
  'array',
  'integer',
  'boolean',
  'date',
}

const ruleValueTypeDescriptions = {
  string: 'A sequence of characters, like a word, sentence, or paragraph',
  array: 'An ordered collection of items, where each item can be of any data type',
  integer: 'A whole number, positive, negative, or zero',
  boolean: 'A value that can only be true or false',
  date: 'A representation of a point in time, typically consisting of a year, month, and day',
}

const ruleValueTypes: string[] = Object.values(IRuleValueType)
  .filter((p) => typeof p === 'string')
  .map((p) => {
    return p.toString()
  })

interface IRule {
  type: IRuleType | null
  reference: string
  value: string | string[] | number | undefined | boolean
  valueType: IRuleValueType | string | null
  isAny: boolean
}

const BMCreateNewCluster: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const model = props.data as IBayesianModel
  const api = useAxios()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [sending, setSending] = React.useState<boolean>(false)
  const [formModel, setFormModel] = React.useState<IBayesianModelCluster | undefined>(undefined)
  const [productTypes, setProductTypes] = React.useState<string[]>([])
  const [dataDefinitionHandles, setDataDefinitionHandles] = React.useState<[]>([])
  const [dataDefinitionValues, setDataDefinitionValues] = React.useState<[]>([])
  const [rules, setRules] = React.useState<IRule[]>([])

  React.useEffect(() => {
    const abortController = new AbortController()

    setLoading(true)

    api.current
      ?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.GetBMClusterFormModel,
        data: {
          modelId: model.modelId,
        },
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          const clusterFormModel = response.data.result['clusterFormModel'] as IBayesianModelCluster

          setFormModel(clusterFormModel)
          setProductTypes(response.data.result['productTypes'])
          setDataDefinitionHandles(response.data.result['dataDefinitionHandles'])
          setDataDefinitionValues(response.data.result['dataDefinitionValues'])
          setLoading(false)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const updateRule = (value, index, property: 'type' | 'reference' | 'value' | 'valueType' | 'isAny') => {
    const rulesCopy = [...rules]
    rulesCopy[index][property] = value as never
    setRules(rulesCopy)
  }

  const removeRule = (index: number) => {
    const rulesCopy = [...rules]
    rulesCopy.splice(index, 1)
    setRules(rulesCopy)
  }

  const addRule = () => {
    const rulesCopy = [...rules]
    rulesCopy.push({
      reference: '',
      type: null,
      value: '',
      valueType: null,
      isAny: false,
    })
    setRules(rulesCopy)
  }

  const getRuleValueTypes = (rule: IRule): IBasicSelectOption[] => {
    const handle = dataDefinitionHandles.find((p) => p['handle'] === rule.reference)

    return ruleValueTypes.map((p) => {
      let isDisabled = false

      if (rule.reference.length < 1) {
        isDisabled = true
      } else {
        isDisabled = handle && handle['valueType'] === p ? false : true

        if (handle && handle['valueType'] === 'string' && p === 'array') {
          isDisabled = false
        }
      }

      return {
        key: p, // to be updated
        value: p, // for display
        isDisabled,
        description: ruleValueTypeDescriptions[p],
      }
    })
  }

  const getRuleValues = (rule: IRule, multiselect: boolean = false): IBasicSelectOption[] | SelectOptionProps[] => {
    const values = dataDefinitionValues.filter((p) => p['handle'] === rule.reference)

    if (values && values.length) {
      if (multiselect) {
        return values.map((p) => {
          return {
            itemId: p['value'],
            children: p['value'],
          }
        })
      } else {
        return values.map((p) => {
          return {
            key: p['value'],
            value: p['value'],
          }
        })
      }
    } else {
      return []
    }
  }

  const transformRulesToFormModel = (rules: IRule[]) => {
    const rulesCopy = [...rules]
    const rr = (newObj, rule) => ((newObj[rule.reference] = rule.value), newObj) // rule reducer

    const equalsRules: Record<string, any> = rulesCopy.filter((p) => p.isAny === false && p.type === IRuleType.Equals).reduce(rr, {})
    const greaterRules: Record<string, any> = rulesCopy.filter((p) => p.isAny === false && p.type === IRuleType.Greater).reduce(rr, {})
    const inRules: Record<string, any> = rulesCopy.filter((p) => p.isAny === false && p.type === IRuleType.In).reduce(rr, {})
    const lesserRules: Record<string, any> = rulesCopy.filter((p) => p.isAny === false && p.type === IRuleType.Lesser).reduce(rr, {})

    const anyRules = {
      equals: rulesCopy.filter((p) => p.isAny === true && p.type === IRuleType.Equals).reduce(rr, {}),
      greater: rulesCopy.filter((p) => p.isAny === true && p.type === IRuleType.Greater).reduce(rr, {}),
      in: rulesCopy.filter((p) => p.isAny === true && p.type === IRuleType.In).reduce(rr, {}),
      lesser: rulesCopy.filter((p) => p.isAny === true && p.type === IRuleType.Lesser).reduce(rr, {}),
    }

    return {
      equalsRules: equalsRules ? equalsRules : {},
      greaterRules: greaterRules ? greaterRules : {},
      inRules: inRules ? inRules : {},
      lesserRules: lesserRules ? lesserRules : {},
      anyRules,
    }
  }

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSending(true)

    if (!formModel) return

    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.CreateBMCluster,
        data: { ...formModel, ...transformRulesToFormModel(rules) },
      })

      if (response?.status === 200) {
        setTimeout(() => {
          setSending(false)
          props.closeModal()
        }, 1000)
      } else {
        throw new Error(`Error: ${JSON.stringify(response?.data)}`)
      }
    } catch (error) {
      alert(getErrorMessage(error))
      setSending(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Spinner />}
      {!loading && typeof formModel !== 'undefined' && (
        <React.Fragment>
          <Form isHorizontal onSubmit={submit}>
            <FormSection title="New cluster configuration" titleElement="h2">
              <FormGroup label="Product type" isRequired fieldId="productType">
                <BasicSelect
                  id="type"
                  initialValue={'Select...'}
                  items={productTypes.map((p) => {
                    return { key: p, value: p }
                  })}
                  onSelect={(item) => setFormModel({ ...formModel, productType: item.value })}
                ></BasicSelect>
              </FormGroup>

              <FormGroup label="Active" isRequired fieldId="active">
                <Switch id="active" isChecked={formModel.isActive} label="Is cluster active?" onChange={(value) => setFormModel({ ...formModel, isActive: value })} />
              </FormGroup>
            </FormSection>

            <FormSection title="Cluster rules" titleElement="h2">
              {rules.length > 0 && (
                <FormGroup isStack label="Rules" isRequired fieldId="rule">
                  {rules.map((rule, index) => (
                    <Grid hasGutter key={index} className="pf-u-box-shadow-sm pf-u-p-lg pf-u-mb-md">
                      <GridItem span={11}>
                        <TypeaheadSelect
                          items={dataDefinitionHandles.map((p) => {
                            return {
                              itemId: p['handle'],
                              children: `${p['dataDefinitionId']}: ${p['handleTitle']} (${p['handle']})`,
                            }
                          })}
                          placeholder="Rule parameter..."
                          onSelect={(itemId) => updateRule(itemId, index, 'reference')}
                        ></TypeaheadSelect>
                      </GridItem>
                      <GridItem span={1}>
                        <Button variant="link" isDanger isSmall onClick={() => removeRule(index)}>
                          Remove
                        </Button>
                      </GridItem>
                      <GridItem span={4}>
                        <BasicSelect
                          id="ruleLogic"
                          initialValue={'Logic...'}
                          items={ruleTypes.map((p) => {
                            return { key: p, value: p }
                          })}
                          onSelect={(item) => updateRule(item.value, index, 'type')}
                        ></BasicSelect>
                      </GridItem>
                      <GridItem span={4}>
                        <BasicSelect
                          id="ruleValueType"
                          initialValue={'Data type...'}
                          items={getRuleValueTypes(rule)}
                          onSelect={(item) => updateRule(item.value, index, 'valueType')}
                        ></BasicSelect>
                      </GridItem>
                      <GridItem span={3}>
                        <Checkbox
                          label="Move to any rules"
                          id={`anyRule-${rule.reference}`}
                          name={`anyRule-${rule.reference}`}
                          isChecked={rule.isAny}
                          onChange={(checked) => updateRule(checked, index, 'isAny')}
                        />
                      </GridItem>

                      {getRuleValues(rule).length > 0 && (
                        <GridItem span={11}>
                          {rule.valueType === 'string' && (
                            <BasicSelect
                              id="ruleValue"
                              initialValue={'Value...'}
                              items={getRuleValues(rule) as IBasicSelectOption[]}
                              onSelect={(item) => updateRule(item.value, index, 'value')}
                            ></BasicSelect>
                          )}
                          {rule.valueType === 'array' && (
                            <TypeaheadSelectMultiple
                              items={getRuleValues(rule, true) as SelectOptionProps[]}
                              placeholder="Value..."
                              onSelect={(selected) => updateRule(selected, index, 'value')}
                            ></TypeaheadSelectMultiple>
                          )}
                        </GridItem>
                      )}

                      {/* Temp until all possible data definition values are in the db  */}
                      {getRuleValues(rule).length === 0 && rule.valueType === 'string' && (
                        <GridItem span={11}>
                          <TextInput
                            type="text"
                            id="ruleValue"
                            name="ruleValue"
                            placeholder="Value..."
                            value={rule.value?.toString()}
                            onChange={(value) => updateRule(value, index, 'value')}
                          />
                        </GridItem>
                      )}

                      {rule.valueType === 'integer' && (
                        <GridItem span={11}>
                          <TextInput
                            type="number"
                            id="ruleValue"
                            name="ruleValue"
                            placeholder="Value..."
                            value={rule.value?.toString()}
                            onChange={(value) => updateRule(value, index, 'value')}
                          />
                        </GridItem>
                      )}

                      {rule.valueType === 'boolean' && (
                        <GridItem span={11}>
                          <Switch
                            id="ruleValue"
                            name="ruleValue"
                            label="True or False?"
                            isChecked={rule.value as boolean}
                            onChange={(value) => updateRule(value, index, 'value')}
                          />
                        </GridItem>
                      )}
                    </Grid>
                  ))}
                </FormGroup>
              )}

              <FormGroup label="">
                <Button id="addRule" variant="tertiary" isSmall onClick={() => addRule()}>
                  <PlusIcon></PlusIcon> Add rule
                </Button>
              </FormGroup>
            </FormSection>

            <ActionGroup>
              <Button variant="primary" type="submit" isLoading={sending} isDisabled={sending}>
                Create
              </Button>
              <Button variant="secondary" type="reset" isDisabled={sending} onClick={() => props.closeModal()}>
                Cancel
              </Button>
            </ActionGroup>
          </Form>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export { BMCreateNewCluster }
