import * as React from 'react'
import { Card, CardBody, Label, LabelGroup, PageSection, PageSectionVariants, SearchInput, Text, TextContent } from '@patternfly/react-core'
import { useHistory } from 'react-router-dom'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import type { AxiosError, AxiosResponse } from 'axios'
import { InfoCircleIcon } from '@patternfly/react-icons'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

interface ISavedQuery {
  customerEmail: string
  customerId: number
}

const CustomerHome: React.FunctionComponent = () => {
  const history = useHistory()
  const [value, setValue] = React.useState('')
  const [savedQueries, setSavedQueries] = React.useState<ISavedQuery[]>([])
  const api = useAxios()

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetDashboardData,
      data: {},
    }

    api.current?.post(BackofficeAPIConfig.Domains.Customer, payload).then((dashboardData: AxiosResponse<BackofficeApiEventResponse>) => {
      if (dashboardData.data.result && dashboardData.data.result['savedQueries']) {
        setSavedQueries(dashboardData.data.result['savedQueries'])
      }
    })

    return () => {
      abortController.abort()
    }
  }, [])

  const onSearch = async (_value: string, _event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
    if (!value) return

    const input = _event.target as HTMLInputElement
    input.disabled = true

    let path = null

    try {
      const payload: BackofficeApiEventSchema = {
        action: BackofficeApiActions.Search,
        data: {
          customerId: value,
        },
      }
      const searchResponse: AxiosResponse<BackofficeApiEventResponse> | undefined = await api.current?.post(BackofficeAPIConfig.Domains.Customer, payload)
      path = searchResponse?.data?.result && searchResponse?.data?.result['path'] ? searchResponse?.data?.result['path'] : null
    } catch (error) {
      const e = error as AxiosError
      if (e.response?.status === 400) {
        alert('Customer not found')
      } else {
        alert(getErrorMessage(error))
      }
    }

    if (path) {
      history.push(`/customer/${path}`)
    } else {
      alert('Customer not found.')
    }

    input.disabled = false
  }

  const onSavedSearchClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, masterId: number) => {
    e.preventDefault()

    history.push(`/customer/${masterId}`)
  }

  return (
    <React.Fragment>
      <PageSection variant={PageSectionVariants.light}>
        <TextContent>
          <Text component="h1">User management</Text>
          <Text component="p">Manage user data and communication</Text>
        </TextContent>
      </PageSection>
      <PageSection isFilled variant={PageSectionVariants.default}>
        <Card selectableInputAriaLabel="search">
          <CardBody>
            <SearchInput
              placeholder="Find customer by ID or email"
              value={value}
              onChange={(_event, value) => setValue(value)}
              onSearch={(_event, value) => onSearch(value, _event)}
              onClear={() => setValue('')}
            />
            {savedQueries.length > 0 && (
              <LabelGroup categoryName="Latest searches:" isCompact numLabels={10} className="pf-u-mt-md">
                {savedQueries.map(
                  (query, index) =>
                    query.customerEmail && (
                      <Label isCompact icon={<InfoCircleIcon />} href="#" key={index} onClick={(e) => onSavedSearchClick(e, query.customerId)}>
                        {query.customerEmail}
                      </Label>
                    )
                )}
              </LabelGroup>
            )}
          </CardBody>
        </Card>
      </PageSection>
    </React.Fragment>
  )
}

export { CustomerHome }
