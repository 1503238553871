import { useAxios } from '@app/utils/useAxios'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { ActionGroup, Alert, Button, Form, FormAlert, FormGroup, FormSection, Panel, PanelMain, PanelMainBody, TextInput } from '@patternfly/react-core'
import axios, { AxiosError } from 'axios'
import React from 'react'
import mime from 'mime-types'
import { PlusCircleIcon } from '@patternfly/react-icons'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

const CustomerTokenGeneration: React.FunctionComponent = () => {
  const api = useAxios()
  const [file, setFile] = React.useState<File>()
  const [sending, setSending] = React.useState<boolean>(false)
  const [success, setSuccess] = React.useState<boolean>(false)
  const [links, setLinks] = React.useState<string[]>([])
  const fileInputRef = React.createRef<HTMLInputElement>()
  const [error, setError] = React.useState<string>('')

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSending(true)

    if (!file) {
      setSending(false)
      return
    }

    let s3InputFilePath: string = ''

    /**
     * Upload to S3
     */
    try {
      const getFileUploadUrlsResponse = await api.current?.post(BackofficeAPIConfig.Domains.Customer, {
        action: BackofficeApiActions.GetFileUploadUrls,
        data: {
          attachments: [
            {
              customerId: 'backoffice',
              folderName: 'customer-tokens',
              extension: file.name.split('.').pop(),
            },
          ],
        },
      })

      if (getFileUploadUrlsResponse?.data?.result?.length) {
        for await (const uploadItem of getFileUploadUrlsResponse.data.result) {
          const extension = file.name.split('.').pop() as string

          // IMPORTANT use of axios default instance instead of API
          await axios.put(uploadItem.uploadUrl, file, {
            headers: {
              'Content-Type': mime.lookup(extension),
            },
          })

          s3InputFilePath = uploadItem.s3Path
        }
      }
    } catch (error) {
      alert(getErrorMessage(error))
    }

    /**
     * Create job
     */
    if (s3InputFilePath) {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.Customer, {
          action: BackofficeApiActions.GenerateAuthTokensReq,
          data: { s3InputFilePath, filename: file.name, links },
        })

        if (response && response.data && response.data.result.success) {
          setSuccess(true)
        }
      } catch (error) {
        alert(getErrorMessage(error))
      }
    }

    setSending(false)
    setLinks([])
    setFile(undefined)
    setError('')

    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  let reader: FileReader | null = null

  if (typeof FileReader != 'undefined') {
    reader = new FileReader()

    reader.onload = function (e: ProgressEvent<FileReader>) {
      const rows = e.target?.result ? e.target?.result?.toString().split('\n') : null
      const rowLimit = 10000

      if (rows && rows.length > rowLimit) {
        setError('CSV file contains too many emails. Try splitting the file in smaller chunks.')
      } else {
        setError('')
      }
    }
  }

  const onFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const txtFileInput = e.target as HTMLInputElement

    if (txtFileInput && txtFileInput.files) {
      if (reader) {
        reader.readAsText(txtFileInput.files[0])
      }

      setFile(txtFileInput.files[0])
    }
  }

  const addLink = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    setLinks([...links, ''])
  }

  const updateLink = (value, index) => {
    setLinks((links) => {
      links[index] = value
      return links
    })
  }

  return (
    <React.Fragment>
      <Panel>
        <PanelMain>
          <PanelMainBody>
            <Form isWidthLimited onSubmit={submit} className="customer-token-generation-form">
              <FormSection title="Generate customer tokens form" titleElement="h2">
                <FormAlert>
                  <Alert
                    variant="default"
                    title="Use this tool to generate customer login tokens that can be later used for magic links. Upload file must be a CSV with a single column of customer email addresses. You can add links to get Klaviyo URL templates. They will be emailed together with the results."
                    isInline
                  />
                </FormAlert>
                {success && (
                  <FormAlert>
                    <Alert
                      variant="success"
                      title="You should shortly receive an email with link to generated customer tokens, errors if any and your custom defined link templates."
                      isInline
                    />
                  </FormAlert>
                )}
                {error && (
                  <FormAlert>
                    <Alert variant="danger" title={error} isInline />
                  </FormAlert>
                )}
                <FormGroup label="Choose a CSV file:">
                  <input type="file" accept=".csv" onChange={(e) => onFileChanged(e)} ref={fileInputRef}></input>
                </FormGroup>
                {links.map((link, index) => (
                  <FormGroup key={index} label="Link:" helperText="For example: https://noie.com/survey/standard or https://noie.com/profile/subscription">
                    <TextInput type="url" id={`link-${index}`} name={`link-${index}`} onChange={(value) => updateLink(value, index)} />
                  </FormGroup>
                ))}
                <FormGroup label="Add links">
                  <Button variant="tertiary" isSmall icon={<PlusCircleIcon />} onClick={(e) => addLink(e)}>
                    Add link
                  </Button>
                </FormGroup>
              </FormSection>
              <ActionGroup>
                <Button variant="primary" type="submit" isLoading={sending} isDisabled={sending || !file}>
                  Send
                </Button>
              </ActionGroup>
            </Form>
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </React.Fragment>
  )
}

export { CustomerTokenGeneration }
