import React from 'react'
import { PageSection, TextContent, Text, PageSectionVariants, TabTitleText, Tabs, Tab, TabContent, TabContentBody } from '@patternfly/react-core'
import { useHistory, useParams } from 'react-router-dom'
import { ListApiKeys } from './ListApiKeys'

const ApiKeysHome: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <PageSection variant={PageSectionVariants.light}>
        <TextContent>
          <Text component="h1">API Keys</Text>
          <Text component="p">Manage API keys</Text>
        </TextContent>
      </PageSection>
      <PageSection>
        <ListApiKeys></ListApiKeys>
      </PageSection>
    </React.Fragment>
  )
}

export { ApiKeysHome }
