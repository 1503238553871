import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { Button, CodeBlock, CodeBlockCode, Hint, HintBody, Spinner } from '@patternfly/react-core'
import { AxiosResponse } from 'axios'
import * as React from 'react'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [recommendation, setRecommendation] = React.useState<Record<string, any>>({})
  const [loading, setLoading] = React.useState(true)

  const payload: BackofficeApiEventSchema = {
    action: BackofficeApiActions.GetRecommendation,
    data: {
      customerId: props.data.customerId,
    },
  }

  React.useEffect(() => {
    const abortController = new AbortController()

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const recommendation = response?.data.result as {}
        setRecommendation(recommendation)
        setLoading(false)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const clearRecommendationCache = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    try {
      payload.action = BackofficeApiActions.ClearRecommendation
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Customer, payload)

      if (response && response.data && response.data.result && response.data.result.success) {
        alert('User recommendation cache was cleared successfuly. If you want to pre-load recommendation for the user impersonate and wait for the profile page to load. ')
      } else {
        alert(getErrorMessage(null))
      }
    } catch (error) {
      alert(getErrorMessage(error))
    }
  }

  return (
    <React.Fragment>
      {loading && <Spinner />}
      {!loading && (
        <React.Fragment>
          <Hint>
            <HintBody>
              Below is raw output from data-api. This output is used to display products on user's recommendation page <b>UNLESS</b> there is an active manual assignment.
            </HintBody>
          </Hint>
          <br></br>
          <Button variant="tertiary" isSmall onClick={(e) => clearRecommendationCache(e)}>
            Clear recommendation cache
          </Button>
          <br></br>
          <br></br>
          <CodeBlock>
            <CodeBlockCode id="code-content">{JSON.stringify(recommendation, null, 2)}</CodeBlockCode>
          </CodeBlock>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const CustomerRecommendation = React.memo(component)

export { CustomerRecommendation }
