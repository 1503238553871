import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import {
  CodeBlock,
  CodeBlockCode,
  DataList,
  DataListCell,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  ExpandableSection,
  ExpandableSectionVariant,
  Flex,
  FlexItem,
  OptionsMenu,
  OptionsMenuItem,
  OptionsMenuPosition,
  OptionsMenuToggle,
  Spinner,
  Tooltip,
} from '@patternfly/react-core'
import { AxiosResponse } from 'axios'
import * as React from 'react'
import Moment from 'react-moment'
import ReactCountryFlag from 'react-country-flag'
import { DatabaseIcon, DesktopIcon, OutlinedClockIcon, UserIcon } from '@patternfly/react-icons'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { CustomerHistoryEventDetails } from './CustomerHistoryEventDetails'
import { cloneDeep, filter, orderBy, uniqBy } from 'lodash'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

interface IPatientHistoryRecord {
  links?: []
  message_id: string
  customer_id: string
  message: string
  owner: string
  tag: string
  data?: IPatientHistoryRecordData
  created_at: Date
  updated_at: Date
}

interface IPatientHistoryRecordData {
  [x: string]: React.ReactNode
  clientIp?: string
  clientUA?: string
  clientCountryCode?: string
  clientCountry?: string
}

interface ITag {
  name: string
  isSelected: boolean
}

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [patientHistoryRecords, setPatientHistoryRecords] = React.useState<IPatientHistoryRecord[]>([])
  const [filteredPatientHistoryRecords, setFilteredPatientHistoryrecords] = React.useState<IPatientHistoryRecord[]>([])
  const [tags, setTags] = React.useState<ITag[]>([])
  const [filterItems, setFilterItems] = React.useState<JSX.Element[]>([])
  const [isFilterMenuOpen, setIsFilterMenuOpen] = React.useState<boolean>(false)

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetPatientHistory,
      data: {
        customerId: props.data.customerId,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const result = response?.data.result as IPatientHistoryRecord[]

        let _tags = result.map((record) => {
          return {
            name: record.tag,
            isSelected: false,
          }
        })

        _tags = uniqBy(_tags, (tag) => tag.name)

        setTags(_tags)
        setPatientHistoryRecords(result)
        setFilteredPatientHistoryrecords(result)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })

    return () => {
      abortController.abort()
    }
  }, [])

  React.useEffect(() => {
    const items: JSX.Element[] = []

    for (const tag of tags) {
      items.push(
        <OptionsMenuItem onSelect={(e) => onFilterSelect(e)} isSelected={tag.isSelected} id={tag.name} key={tag.name}>
          {tag.name.charAt(0).toUpperCase() + tag.name.slice(1).replace(/-/g, ' ')}
        </OptionsMenuItem>
      )
    }

    setFilterItems(items)

    if (tags.every((t) => !t.isSelected)) {
      setFilteredPatientHistoryrecords(patientHistoryRecords)
    } else {
      const records = cloneDeep(patientHistoryRecords)
      const selectedTags: ITag[] = filter(tags, { isSelected: true })
      const results: IPatientHistoryRecord[] = []

      for (const tag of selectedTags) {
        results.push(...filter(records, { tag: tag.name }))
      }

      setFilteredPatientHistoryrecords(orderBy(results, ['created_at'], ['desc']))
    }
  }, [tags])

  const filterToggle = <OptionsMenuToggle onToggle={() => setIsFilterMenuOpen(!isFilterMenuOpen)} toggleTemplate={'Filter by tag'} />
  const onFilterSelect = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<Element> | undefined) => {
    if (e) {
      e.preventDefault()

      const selectedTagName = e.currentTarget.id

      const nextTags = tags.map((tag) => {
        if (tag.name === selectedTagName) {
          return {
            ...tag,
            isSelected: !tag.isSelected,
          }
        } else {
          return tag
        }
      })

      setTags(nextTags)
    }
  }

  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const openEventDetailsModal = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: IPatientHistoryRecordData | undefined) => {
    e.preventDefault()

    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = 'Event data'
    defaultModalProps.description = 'Patient history event details'
    defaultModalProps.component = CustomerHistoryEventDetails
    defaultModalProps.data = data as Record<string, any>
    setModalProps(defaultModalProps)
  }

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      {!patientHistoryRecords.length ? <Spinner /> : null}
      {patientHistoryRecords.length ? (
        <div style={{ minHeight: 'calc(100vh - 200px)' }}>
          {
            <div className="clearfix pf-u-mb-md">
              <OptionsMenu id="filters" className="pf-u-float-right" position={OptionsMenuPosition.right} menuItems={filterItems} toggle={filterToggle} isOpen={isFilterMenuOpen} />
            </div>
          }
          <DataList aria-label={'data list'}>
            {filteredPatientHistoryRecords.map((record, index) => (
              <DataListItem key={index} id={`patient-record-item-${index}`} className="patient-record-item">
                <DataListItemRow className="pf-u-px-0">
                  <DataListItemCells
                    dataListCells={[
                      <DataListCell key="primary content">
                        <Flex direction={{ default: 'column' }}>
                          <FlexItem>
                            <CodeBlock className="pf-u-mb-md">
                              <CodeBlockCode id={record.message_id}>
                                {record.tag === 'conversation-message-created' && (
                                  <ExpandableSection
                                    variant={ExpandableSectionVariant.truncate}
                                    toggleTextCollapsed={'Expand'}
                                    toggleTextExpanded={'Collapse'}
                                    truncateMaxLines={8}
                                  >
                                    <span id={record.message_id} dangerouslySetInnerHTML={{ __html: record.message }}></span>
                                  </ExpandableSection>
                                )}
                                {record.tag !== 'conversation-message-created' && <span id={record.message_id} dangerouslySetInnerHTML={{ __html: record.message }}></span>}
                              </CodeBlockCode>
                            </CodeBlock>
                          </FlexItem>
                        </Flex>
                        <Flex className="pf-u-font-size-sm">
                          <FlexItem>
                            <UserIcon /> {record.owner}
                          </FlexItem>
                          {record.data && record.data.clientIp ? (
                            <FlexItem>
                              <ReactCountryFlag countryCode={record.data.clientCountryCode || ''} />
                              {' ' + record.data.clientCountry}
                            </FlexItem>
                          ) : null}
                          {record.data && record.data.clientDevice && record.data.clientDevice['client'] && record.data.clientDevice['device'] && record.data.clientDevice['os'] ? (
                            <React.Fragment>
                              {record.data.clientDevice['client'] !== null && (
                                <span>
                                  <DesktopIcon />
                                  {' ' +
                                    record.data.clientDevice['client']['name'] +
                                    ', ' +
                                    record.data.clientDevice['device']['brand'] +
                                    ' ' +
                                    record.data.clientDevice['os']['name']}
                                </span>
                              )}
                              {record.data.clientDevice['bot'] !== null && (
                                <span>
                                  <DesktopIcon />
                                  {' ' + record.data.clientDevice['bot']['name']}
                                </span>
                              )}
                            </React.Fragment>
                          ) : null}
                          {record.links && record.links.length
                            ? record.links.map((link, index) => (
                                <FlexItem key={index}>
                                  <a href={link['linkUrl']} target={'_blank'}>
                                    {link['linkText']}
                                  </a>
                                </FlexItem>
                              ))
                            : null}
                          {record.data ? (
                            <FlexItem>
                              <span>
                                <DatabaseIcon></DatabaseIcon>{' '}
                                <a href="#" onClick={(e) => openEventDetailsModal(e, record.data)}>
                                  Event data
                                </a>
                              </span>
                            </FlexItem>
                          ) : null}
                          <FlexItem>
                            <OutlinedClockIcon />{' '}
                            <Tooltip
                              content={
                                <div>
                                  <Moment date={record.created_at} />
                                </div>
                              }
                            >
                              <span>
                                <Moment date={record.created_at} />
                              </span>
                            </Tooltip>
                          </FlexItem>
                        </Flex>
                      </DataListCell>,
                    ]}
                  />
                </DataListItemRow>
              </DataListItem>
            ))}
          </DataList>
        </div>
      ) : null}
    </React.Fragment>
  )
}

const CustomerHistory = React.memo(component)

export { CustomerHistory }
