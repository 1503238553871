import { AxiosError, AxiosResponse } from 'axios'
import * as React from 'react'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import {
  ActionList,
  ActionListItem,
  Button,
  ClipboardCopy,
  Divider,
  Dropdown,
  DropdownItem,
  Form,
  FormGroup,
  Icon,
  KebabToggle,
  Modal,
  ModalVariant,
  Spinner,
  TextArea,
  TextInput,
} from '@patternfly/react-core'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '../../../components/modal/Modal'
import { CustomerChangeEmail } from './CustomerChangeEmail'
import { ExclamationCircleIcon, ExternalLinkSquareAltIcon } from '@patternfly/react-icons'
import { CustomerLinkManager } from './CustomerLinkManager'
import moment from 'moment-timezone'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { CustomerMembership } from './CustomerMembership'
import { KeycloakUserRoles, useKeycloakUser } from '@lib/User/useKeycloakUser'
import { PatientDetailsDTO, usePatientDetails } from '@lib/Patient/usePatientDetails'
import { useHistory } from 'react-router-dom'

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

/**
 * Customer Details Component
 * @param props
 * @returns
 */
const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const history = useHistory()
  const patientDetails = usePatientDetails(props.data.customerId)
  const user = useKeycloakUser()

  const impersonateButtonClick = async (email: string, locale: string, target: HTMLButtonElement) => {
    target.disabled = true
    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetImpersonateUrl,
      data: { email, locale },
    }
    try {
      const response: AxiosResponse<BackofficeApiEventResponse> | undefined = await api.current?.post(BackofficeAPIConfig.Domains.Customer, payload)
      const impersonateURL = response?.data.result as string
      window.open(impersonateURL, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1350,height=750')
      target.disabled = false
    } catch (error) {
      alert('Impersonate link could not be retrieved. Customer may have to login first.')
      target.disabled = false
    }
  }

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const [isNoWrapperModal, setIsNoWrapperModal] = React.useState(true)
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const openEmailChangeModal = (patientDetails: PatientDetailsDTO | undefined) => {
    if (!patientDetails) return

    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = 'Change email address'
    defaultModalProps.description = `Change email address wizard`
    defaultModalProps.component = CustomerChangeEmail
    defaultModalProps.data = patientDetails
    setIsNoWrapperModal(true)
    setModalProps(defaultModalProps)
  }
  const openLinkManagerModal = (customerId: string) => {
    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = 'Link manager'
    defaultModalProps.description = `Generated customer links with embedded authentication`
    defaultModalProps.component = CustomerLinkManager
    defaultModalProps.data = { customerId }
    setIsNoWrapperModal(false)
    setModalProps(defaultModalProps)
  }
  const openMembershipModal = (membership: string) => {
    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = 'Membership'
    defaultModalProps.description = `Raw membership data`
    defaultModalProps.component = CustomerMembership
    defaultModalProps.data = { membership }
    setIsNoWrapperModal(false)
    setModalProps(defaultModalProps)
  }

  const [isGdprDeleteModalOpen, setIsGdprDeleteModalOpen] = React.useState(false)
  const handleGdprDeleteModalToggle = () => {
    setIsGdprDeleteModalOpen(!isGdprDeleteModalOpen)
  }

  const dropdownItems = [
    <DropdownItem onClick={() => openEmailChangeModal(patientDetails)} key="change-email" component="button">
      Change email
    </DropdownItem>,
    <Divider key={'divider'}></Divider>,
    <DropdownItem onClick={handleGdprDeleteModalToggle} key="delete-account" component="button" className="pf-u-danger-color-100">
      Delete account (GDPR)
    </DropdownItem>,
  ]

  const [isOpen, setIsOpen] = React.useState(false)
  const [deleteUserRequest, setDeleteUserRequest] = React.useState(false)
  const onToggle = (isOpen: boolean, event: MouseEvent | TouchEvent | KeyboardEvent | React.KeyboardEvent<any> | React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setIsOpen(isOpen)
  }
  const onSelect = (event: React.SyntheticEvent<HTMLDivElement, Event> | undefined) => {
    if (event) {
      event.stopPropagation()
    }
    setIsOpen(!isOpen)
  }

  const deleteBtn = React.useRef<HTMLButtonElement>(null)
  const cancelBtn = React.useRef<HTMLButtonElement>(null)
  const outputDiv = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (deleteUserRequest && outputDiv.current && patientDetails) {
      outputDiv.current.innerHTML = '<div class="pf-u-pt-sm">Loading...</div>'

      if (deleteBtn.current) {
        deleteBtn.current.disabled = true
      }
      if (cancelBtn.current) {
        cancelBtn.current.disabled = true
      }

      const payload: BackofficeApiEventSchema = {
        action: BackofficeApiActions.DeleteCustomer,
        data: { email: patientDetails.email, id: patientDetails.id },
      }

      api.current
        ?.post(BackofficeAPIConfig.Domains.Customer, payload)
        .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
          if (response.status === 200 && outputDiv.current) {
            outputDiv.current.innerHTML =
              '<div class="pf-u-pt-sm pf-u-success-color-100">Account deletion request has been sent. You should receive an email soon with a receipt.</div>'

            setTimeout(() => {
              history.push('/')
            }, 5000)
          }
        })
        .catch((e) => {
          const error = e as AxiosError
          console.error(error)

          if (outputDiv.current) {
            outputDiv.current.innerHTML = `<div class="pf-u-pt-sm pf-u-danger-color-100">${error.message}</div>`
          }
          if (cancelBtn.current) {
            cancelBtn.current.disabled = false
          }
        })
    }
  }, [deleteUserRequest])

  return (
    <React.Fragment>
      <ModalBasicComponent
        isModalOpen={isModalOpen}
        handleModalToggle={toggleModal}
        modalProps={modalProps}
        toggleModalProps={() => {}}
        key={null}
        type={''}
        props={undefined}
        hasNoBodyWrapper={isNoWrapperModal}
      />
      <Modal
        variant={ModalVariant.small}
        aria-label="GDPR Delete"
        isOpen={isGdprDeleteModalOpen}
        onClose={handleGdprDeleteModalToggle}
        showClose={false}
        actions={[
          <Button key="confirm" variant="danger" ref={deleteBtn} onClick={() => setDeleteUserRequest(true)}>
            Confirm
          </Button>,
          <Button key="cancel" variant="tertiary" ref={cancelBtn} onClick={handleGdprDeleteModalToggle}>
            Cancel
          </Button>,
        ]}
      >
        <h3 className="pf-u-font-size-xl pf-u-mb-md">
          <Icon size="md" status="danger">
            <ExclamationCircleIcon />
          </Icon>{' '}
          Delete account
        </h3>
        <p>
          Press confirm in order to delete the account according to the GDPR.
          <br />
          <b>IMPORTANT</b>: this can not be undone.
        </p>
        <div ref={outputDiv}></div>
      </Modal>
      {!patientDetails && <Spinner />}
      {patientDetails && (
        <React.Fragment>
          {user.current?.isInRole(KeycloakUserRoles.BackofficeManagement) && (
            <div className="pf-u-display-flex pf-u-justify-content-flex-end pf-u-mb-md">
              <ActionList>
                {patientDetails.links.map((link, index) => (
                  <ActionListItem key={index}>
                    <Button variant="link" isSmall icon={<ExternalLinkSquareAltIcon />} iconPosition="right" onClick={() => window.open(link.value, '_blank')}>
                      {link.name}
                    </Button>
                  </ActionListItem>
                ))}
                <ActionListItem>
                  <Button variant="plain" isSmall onClick={() => openLinkManagerModal(patientDetails.id.toString())}>
                    Link manager
                  </Button>
                </ActionListItem>
                {typeof patientDetails.membership === 'string' && (
                  <ActionListItem>
                    <Button variant="plain" isSmall onClick={() => openMembershipModal(patientDetails.membership as string)}>
                      Membership
                    </Button>
                  </ActionListItem>
                )}
                <ActionListItem>
                  <Dropdown onSelect={onSelect} toggle={<KebabToggle onToggle={onToggle} />} isOpen={isOpen} isPlain dropdownItems={dropdownItems} position="right" />
                </ActionListItem>
              </ActionList>
            </div>
          )}

          <Form className="pf-u-mb-md" isHorizontal>
            <FormGroup label="Customer ID" fieldId="id">
              <ClipboardCopy isReadOnly hoverTip="Copy" clickTip="Copied">
                {patientDetails.id}
              </ClipboardCopy>
            </FormGroup>
            <FormGroup label="Email" fieldId="email">
              <ClipboardCopy isReadOnly hoverTip="Copy" clickTip="Copied">
                {patientDetails.email}
              </ClipboardCopy>
            </FormGroup>

            {typeof patientDetails.phone === 'string' && (
              <FormGroup label="Phone" fieldId="phone">
                <ClipboardCopy isReadOnly hoverTip="Copy" clickTip="Copied">
                  {patientDetails.phone}
                </ClipboardCopy>
              </FormGroup>
            )}

            {typeof patientDetails.whatsapp === 'string' && (
              <FormGroup label="WhatsApp" fieldId="whatsapp">
                <ClipboardCopy isReadOnly hoverTip="Copy" clickTip="Copied">
                  {patientDetails.whatsapp}
                </ClipboardCopy>
              </FormGroup>
            )}

            <FormGroup label="Full name" fieldId="name">
              <ClipboardCopy isReadOnly hoverTip="Copy" clickTip="Copied">
                {patientDetails.name}
              </ClipboardCopy>
            </FormGroup>

            {typeof patientDetails.dob === 'string' && (
              <FormGroup label="DOB" fieldId="dob">
                <TextInput value={moment(patientDetails.dob).format('YYYY-MM-DD')} readOnly readOnlyVariant="default" type="text" id="dob" name="dob" />
              </FormGroup>
            )}

            {typeof patientDetails.age === 'string' && (
              <FormGroup label="Age" fieldId="age">
                <TextInput value={patientDetails.age} readOnly readOnlyVariant="default" type="text" id="age" name="age" />
              </FormGroup>
            )}

            {typeof patientDetails.gender === 'string' && (
              <FormGroup label="Gender" fieldId="gender">
                <TextInput value={patientDetails.gender} readOnly readOnlyVariant="default" type="text" id="gender" name="gender" />
              </FormGroup>
            )}

            {typeof patientDetails.address === 'string' && (
              <FormGroup label="Address" fieldId="address">
                <TextArea id="address" readOnlyVariant="default" rows={3} value={patientDetails.address.replace(new RegExp('null', 'g'), '')}></TextArea>
              </FormGroup>
            )}

            <FormGroup label="Locale" fieldId="locale">
              <TextInput value={patientDetails.locale} readOnly readOnlyVariant="default" type="text" id="locale" name="locale" />
            </FormGroup>
            <FormGroup label="Account created" fieldId="created">
              <TextInput value={moment(patientDetails.created).format('YYYY-MM-DD HH:mm:ss')} readOnly readOnlyVariant="default" type="text" id="created" name="created" />
            </FormGroup>

            {user.current?.isInRole(KeycloakUserRoles.BackofficeManagement) && (
              <FormGroup label="Impersonate">
                <Button isSmall variant="tertiary" onClick={(e) => impersonateButtonClick(patientDetails.email, patientDetails.locale, e.currentTarget)}>
                  Impersonate
                </Button>
              </FormGroup>
            )}
          </Form>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const CustomerDetails = React.memo(component)

export { CustomerDetails }
