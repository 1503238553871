let apiBase = ''

if (process.env.NODE_ENV === 'production' && process.env.API === 'tenant') {
  apiBase = 'https://tenant.noie.io/production'
} else if (process.env.NODE_ENV === 'production' && process.env.API === 'services') {
  apiBase = 'https://services.noie.com/production'
} else {
  apiBase = 'http://localhost:4000'
}

export const APIBase = apiBase

export const BackofficeAPIConfig = {
  BaseURL: `${APIBase}/backoffice`,
  MediaURL: `${APIBase}/media`,
  VersaURL: `${APIBase}/versa`,
  Domains: {
    Customer: '/customer',
    Data: '/data',
    WhatsApp: '/whatsapp',
    Employee: '/employee',
    Product: '/product',
    Model: '/model',
    MiniModel: '/mini_model',
    Insights: '/insights',
    ApiKeys: '/api_keys',
  },
}
