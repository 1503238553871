import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import Moment from 'react-moment'
import * as React from 'react'
import { TableComposable, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table'
import { AxiosResponse } from 'axios'
import { Button, Spinner, Toolbar, ToolbarContent, ToolbarItem } from '@patternfly/react-core'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '../../../components/modal/Modal'
import { CustomerDataset } from './CustomerDataset'
import { NoResultsInTable } from '../../../components/table/NoResultsInTable'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { CustomerDatasetEdit } from './CustomerDatasetEdit'
import { CustomerRecommendationWebsite } from './CustomerRecommendationWebsite'
import { usePatientDetails } from '@lib/Patient/usePatientDetails'
import { CustomerDatasetRaw } from './CustomerDatasetRaw'
import { KeycloakUserRoles, useKeycloakUser } from '@lib/User/useKeycloakUser'

interface IRepository {
  customer_id: string
  data_set_id: number
  submitted_at: Date
  source: string
  img: null
  displayName: string
}

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const defaultModalProps: IModalBasicComponentModalProps = {
  component: DefaultModalComponent,
  description: '',
  title: '',
  data: {},
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const user = useKeycloakUser()
  const [repositories, setRepositories] = React.useState<IRepository[]>([])
  const [loading, setLoading] = React.useState(false)
  const [reload, setReload] = React.useState(0)
  const patientDetails = usePatientDetails(props.data.customerId)

  React.useEffect(() => {
    setLoading(true)

    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetSurveys,
      data: {
        customerId: props.data.customerId,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const surveys = response?.data.result as []
        setRepositories(surveys)
        setLoading(false)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [reload])

  const imageButtonClick = async (url: string | null) => {
    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetSkinImage,
      data: { url },
    }
    const response: AxiosResponse<BackofficeApiEventResponse> | undefined = await api.current?.post(BackofficeAPIConfig.Domains.Customer, payload)
    const imageURL = response?.data.result as string
    window.open(imageURL, '_blank')
  }

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const openModal = (repo: IRepository) => {
    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = 'Dataset details'
    defaultModalProps.description = `Patient name: ${patientDetails?.name}. Dataset id: ${repo.data_set_id}`
    defaultModalProps.component = CustomerDataset
    defaultModalProps.data = repo
    defaultModalProps.id = 'customer-dataset-details-modal'
    setModalProps(defaultModalProps)
  }
  const toggleModal = (id: string) => {
    setIsModalOpen(!isModalOpen)

    if (id === datasetEditModalId) {
      setReload(reload + 1)
    }
  }

  const datasetEditModalId = 'modal-edit-dataset-form'
  const openEditDatasetModal = (customerId) => {
    defaultModalProps.title = 'Edit dataset'
    defaultModalProps.description = `Patient name: ${patientDetails?.name}`
    defaultModalProps.component = CustomerDatasetEdit
    defaultModalProps.data = { customerId }
    defaultModalProps.id = datasetEditModalId

    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)
  }

  const openRecommendationModal = () => {
    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = 'Recommendation'
    defaultModalProps.description = `Patient name: ${patientDetails?.name}`
    defaultModalProps.component = CustomerRecommendationWebsite
    defaultModalProps.data = props.data
    setModalProps(defaultModalProps)
  }

  const openDatasetRawModal = (repo: IRepository) => {
    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = 'Dataset output'
    defaultModalProps.description = `Patient name: ${patientDetails?.name}`
    defaultModalProps.component = CustomerDatasetRaw
    defaultModalProps.data = repo
    setModalProps(defaultModalProps)
  }

  const columnNames = {
    data_set_id: 'Dataset ID',
    submitted_at: 'Submitted at',
    source: 'Type',
    img: 'Image',
  }

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      {loading && <Spinner />}
      {!loading && (
        <React.Fragment>
          <Toolbar id="toolbar-items">
            <ToolbarContent alignment={{ lg: 'alignLeft' }}>
              <ToolbarItem>
                <Button variant="primary" isSmall onClick={() => openRecommendationModal()}>
                  View recommendation
                </Button>
              </ToolbarItem>
              <ToolbarItem variant="separator" />
              <ToolbarItem>
                <Button variant="secondary" isSmall onClick={() => openEditDatasetModal(props.data.customerId)}>
                  Edit dataset
                </Button>
              </ToolbarItem>
            </ToolbarContent>
          </Toolbar>
          <TableComposable aria-label="Simple table" variant={'compact'} borders={true} isStriped>
            <Thead>
              <Tr>
                <Th>{columnNames.data_set_id}</Th>
                <Th>{columnNames.submitted_at}</Th>
                <Th>{columnNames.source}</Th>
                <Th width={10}>{columnNames.img}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {repositories.map((repo) => (
                <Tr key={repo.data_set_id}>
                  <Td dataLabel={columnNames.data_set_id}>
                    <a onClick={() => openModal(repo)}>{repo.data_set_id}</a>
                    {user.current?.isInRole(KeycloakUserRoles.BackofficeManagement) && (
                      <React.Fragment>
                        &nbsp;&nbsp;|&nbsp;&nbsp;<a onClick={() => openDatasetRawModal(repo)}>Output</a>
                      </React.Fragment>
                    )}
                  </Td>
                  <Td dataLabel={columnNames.submitted_at}>
                    <Moment date={repo.submitted_at} />
                  </Td>
                  {typeof repo.displayName === 'string' && <Td dataLabel={columnNames.source}>{repo.displayName}</Td>}
                  {typeof repo.displayName !== 'string' && <Td dataLabel={columnNames.source}>{repo.source}</Td>}
                  <Td dataLabel={columnNames.img} modifier="fitContent">
                    {repo.img && <a onClick={() => imageButtonClick(repo.img)}>Image</a>}
                  </Td>
                </Tr>
              ))}
              {!repositories.length && <NoResultsInTable></NoResultsInTable>}
            </Tbody>
          </TableComposable>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const CustomerDatasets = React.memo(component)

export { CustomerDatasets }
