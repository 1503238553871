import { BasicSelect } from '@app/components/select/BasicSelect'
import { TypeaheadSelectMultiple } from '@app/components/select/TypeaheadSelectMultiple'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { IDraftOrderFormData } from '@lib/DraftOrder/IDraftOrderFormData'
import { IDraftOrderModel } from '@lib/DraftOrder/IDraftOrderModel'
import { IProduct } from '@lib/DraftOrder/IProduct'
import {
  ActionGroup,
  Alert,
  Button,
  ClipboardCopy,
  ClipboardCopyVariant,
  Form,
  FormAlert,
  FormFieldGroupExpandable,
  FormFieldGroupHeader,
  FormFieldGroupHeaderTitleTextObject,
  FormGroup,
  FormSection,
  Icon,
  InputGroup,
  InputGroupText,
  Modal,
  ModalVariant,
  NumberInput,
  Panel,
  PanelMain,
  PanelMainBody,
  Spinner,
  TextInput,
} from '@patternfly/react-core'
import { CheckCircleIcon, PlusCircleIcon, TrashIcon } from '@patternfly/react-icons'
import { AxiosError, AxiosResponse } from 'axios'
import React from 'react'
import update from 'immutability-helper'
import { TypeaheadSelect } from '@app/components/select/TypeaheadSelect'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

const defaultDraftOrderForm = {
  customerId: 0,
  createManualAssignment: false,
  manualAssignmentExpiry: undefined,
  sendInvoice: false,
  products: [],
  tags: [],
}

const DraftOrderForm: React.FunctionComponent = () => {
  const api = useAxios()

  const [loading, setLoading] = React.useState<boolean>(true)
  const [sending, setSending] = React.useState<boolean>(false)
  const [draftOrderModel, setDraftOrderModel] = React.useState<IDraftOrderModel>()
  const [draftOrderFormData, setDraftOrderFormData] = React.useState<IDraftOrderFormData>(defaultDraftOrderForm)

  React.useEffect(() => {
    const abortController = new AbortController()

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, {
        action: BackofficeApiActions.GetDraftOrderModel,
        data: {
          customerId: 0,
        },
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          setDraftOrderModel(response.data.result as IDraftOrderModel)
          setLoading(false)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const addNewProductTemplate = () => {
    const product: IProduct = {
      account: {
        customerId: 0,
        isMaster: true,
        name: '',
      },
      usagePattern: draftOrderModel?.usagePatterns[2].key as string,
      quantity: 1,
      sku: '',
      hasCustomLabel: true,
    }

    if (draftOrderFormData) {
      if (!draftOrderFormData.products) {
        draftOrderFormData.products = []
      }

      setDraftOrderFormData({ ...draftOrderFormData, products: [...(draftOrderFormData?.products as []), ...[product]] })
    }
  }

  const getProductTitleText = (productIndex: number): FormFieldGroupHeaderTitleTextObject => {
    let title = '...'

    if (draftOrderFormData && draftOrderFormData.products && draftOrderFormData.products[productIndex] && draftOrderFormData.products[productIndex].sku) {
      const sku = draftOrderFormData.products[productIndex].sku as string
      title = draftOrderModel?.products.find((p) => p.itemId === sku)?.children as string
    }

    return {
      id: `product-title-${productIndex}`,
      text: title,
    }
  }

  const removeProduct = (index: number) => {
    const data = update(draftOrderFormData, {
      products: {
        $splice: [[index, 1]],
      },
    })

    setDraftOrderFormData(data)
  }

  const updateProductSku = (productIndex: number, itemId: string) => {
    const data = update(draftOrderFormData, {
      products: {
        [productIndex]: {
          sku: { $set: itemId },
        },
      },
    })

    setDraftOrderFormData(data)
  }

  const updateProductUsagePattern = (productIndex: number, usagePattern: string) => {
    const data = update(draftOrderFormData, {
      products: {
        [productIndex]: {
          usagePattern: { $set: usagePattern },
        },
      },
    })

    setDraftOrderFormData(data)
  }

  const updateProductQuantity = (productIndex: number, newValue: number) => {
    const data = update(draftOrderFormData, {
      products: {
        [productIndex]: {
          quantity: { $set: newValue },
        },
      },
    })

    setDraftOrderFormData(data)
  }

  const updateProductLabel = (productIndex: number, value: string) => {
    const data = update(draftOrderFormData, {
      products: {
        [productIndex]: {
          account: {
            name: { $set: value },
          },
        },
      },
    })

    setDraftOrderFormData(data)
  }

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSending(true)

    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Customer, {
        action: BackofficeApiActions.CreateDraftOrder,
        data: draftOrderFormData,
      })

      if (response && response.data && response.data.result.success) {
        setTimeout(() => {
          setSending(false)

          setDraftOrderAdminUrl(response.data.result.url as string)
          setIsDraftOrderCompleteModalOpen(true)
        }, 1000)
      }
    } catch (error) {
      setSending(false)
      alert(getErrorMessage(error))
    }
  }

  const [draftOrderAdminUrl, setDraftOrderAdminUrl] = React.useState('')
  const [isDraftOrderCompleteModalOpen, setIsDraftOrderCompleteModalOpen] = React.useState(false)
  const handleDraftOrderCompleteModalToggle = () => {
    setIsDraftOrderCompleteModalOpen(!isDraftOrderCompleteModalOpen)
    window.location.reload()
  }

  return (
    <React.Fragment>
      {loading && <Spinner />}
      {draftOrderModel && !loading && (
        <Panel>
          <PanelMain>
            <PanelMainBody>
              <Form isHorizontal isWidthLimited onSubmit={submit} className="draft-order-form">
                {/** --------------------------- Start order properties --------------------------- */}
                <FormSection title="Draft order form" titleElement="h2">
                  <FormAlert>
                    <Alert variant="info" title="Draft orders without a registered user can be done here." isInline />
                  </FormAlert>
                  <FormGroup label="Store" isRequired fieldId="txt-store">
                    <BasicSelect
                      id="txt-store"
                      initialValue={'Select...'}
                      items={draftOrderModel.stores.map((p) => {
                        return { key: p.name, value: p.name }
                      })}
                      onSelect={(item) => setDraftOrderFormData({ ...draftOrderFormData, store: draftOrderModel.stores.find((p) => p.name === item.key) })}
                    ></BasicSelect>
                  </FormGroup>
                  <FormGroup label="Order tags" fieldId="txt-tags">
                    <TypeaheadSelectMultiple
                      items={draftOrderModel.orderTags}
                      placeholder="Assign tags..."
                      onSelect={(selected) => setDraftOrderFormData({ ...draftOrderFormData, tags: selected })}
                    ></TypeaheadSelectMultiple>
                  </FormGroup>
                  <FormGroup label="Discount" fieldId="txt-discount">
                    <InputGroup>
                      <BasicSelect
                        onSelect={(item) => setDraftOrderFormData({ ...draftOrderFormData, discountType: item.key.toString() })}
                        id="txt-discount-type"
                        initialValue={'Select...'}
                        items={draftOrderModel.discountTypes}
                      ></BasicSelect>
                      <TextInput
                        id="txt-discount-amount"
                        value={draftOrderFormData?.discountAmount ?? ''}
                        onChange={(value) => setDraftOrderFormData({ ...draftOrderFormData, discountAmount: value })}
                      />
                      <InputGroupText id="txt-discount-label">
                        {draftOrderFormData?.discountType === 'percentage' && '%'}
                        {draftOrderFormData?.discountType === 'fixed_amount' && draftOrderFormData?.store && `${draftOrderFormData?.store.currency}`}
                      </InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </FormSection>
                {/** --------------------------- end order properties --------------------------- */}

                {/** --------------------------- Start products section --------------------------- */}
                <FormSection title="Order products" titleElement="h2">
                  <FormGroup
                    label="Products"
                    fieldId="txt-products"
                    isRequired
                    labelIcon={
                      <Button variant="plain" onClick={() => addNewProductTemplate()}>
                        <PlusCircleIcon />
                      </Button>
                    }
                  >
                    {draftOrderFormData?.products?.map((product, index) => (
                      <FormFieldGroupExpandable
                        className="pf-u-mb-lg"
                        isExpanded
                        key={index}
                        header={
                          <FormFieldGroupHeader
                            titleText={getProductTitleText(index)}
                            actions={
                              <Button variant="plain" aria-label="Remove" onClick={() => removeProduct(index)}>
                                <TrashIcon />
                              </Button>
                            }
                          />
                        }
                      >
                        <FormGroup label="Product" isRequired fieldId="product">
                          <TypeaheadSelect
                            items={draftOrderModel.products}
                            placeholder="Select a product..."
                            onSelect={(itemId) => updateProductSku(index, itemId)}
                          ></TypeaheadSelect>
                        </FormGroup>
                        <FormGroup label="Usage pattern" isRequired fieldId="usage-pattern">
                          <BasicSelect
                            id="txt-usage-pattern"
                            initialValue={(draftOrderFormData.products as IProduct[])[index].usagePattern}
                            items={draftOrderModel.usagePatterns}
                            onSelect={(item) => updateProductUsagePattern(index, item.key as string)}
                          ></BasicSelect>
                        </FormGroup>
                        <FormGroup label="Quantity" isRequired fieldId="quantity">
                          <NumberInput
                            value={product.quantity}
                            min={1}
                            max={100}
                            onMinus={() => updateProductQuantity(index, product.quantity - 1)}
                            onChange={(e) => updateProductQuantity(index, parseInt((e.target as HTMLInputElement).value))}
                            onPlus={() => updateProductQuantity(index, product.quantity + 1)}
                          />
                        </FormGroup>
                        <FormGroup label="Label" fieldId="custom_label">
                          <TextInput id="txt-custom-label" name="custom-label" type="text" onChange={(value) => updateProductLabel(index, value)}></TextInput>
                        </FormGroup>
                      </FormFieldGroupExpandable>
                    ))}
                  </FormGroup>
                </FormSection>

                {/** --------------------------- end products section --------------------------- */}

                <ActionGroup className="pf-u-mt-0">
                  <Button variant="primary" type="submit" isLoading={sending} isDisabled={sending}>
                    Submit
                  </Button>
                </ActionGroup>
              </Form>
            </PanelMainBody>
          </PanelMain>
        </Panel>
      )}
      <Modal variant={ModalVariant.small} aria-label="Draft Order" isOpen={isDraftOrderCompleteModalOpen} showClose={true} onClose={handleDraftOrderCompleteModalToggle}>
        <h3 className="pf-u-font-size-xl pf-u-mb-md">
          <Icon size="md" status="success">
            <CheckCircleIcon />
          </Icon>{' '}
          Draft order created
        </h3>
        <p>
          Use the URL below in order to access{' '}
          <a href={draftOrderAdminUrl} target="_blank">
            the draft order's
          </a>{' '}
          admin page.
        </p>
        <br />
        <p>
          <ClipboardCopy isReadOnly hoverTip="Copy" clickTip="Copied" variant={ClipboardCopyVariant.inline}>
            {draftOrderAdminUrl}
          </ClipboardCopy>
        </p>
      </Modal>
    </React.Fragment>
  )
}

export { DraftOrderForm }
