import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { ActionGroup, Button, Checkbox, DraggableItemPosition, Switch, Text, TextContent, TextVariants } from '@patternfly/react-core'
import React from 'react'
import { DragDrop, Draggable, Droppable } from '@patternfly/react-core'
import { GripVerticalIcon } from '@patternfly/react-icons'

interface ItemType {
  name: string
  visible: boolean
}

const reorder = (list: ItemType[], startIndex: number, endIndex: number) => {
  const result = list
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const chartDataClone = [...(props.data['chartData'] as ItemType[])]
  const [items, setItems] = React.useState(chartDataClone)

  const onDrop = (source: DraggableItemPosition, dest?: DraggableItemPosition) => {
    if (dest) {
      const newItems = reorder(items, source.index, dest.index)
      setItems([...newItems])
      return true
    }
    return false
  }

  const onItemToggle = (itemIndex: number, checked: boolean) => {
    const cloned = [...items]
    cloned[itemIndex].visible = checked
    setItems(cloned)
  }

  const save = () => {
    props.data['chartData'] = items
    props.closeModal()
  }

  return (
    <React.Fragment>
      <DragDrop onDrop={(s, d) => onDrop(s, d)}>
        <Droppable droppableId={'123456'}>
          {items.map((item, index) => (
            <Draggable key={index} style={{ padding: '8px' }}>
              <TextContent>
                <Text component={TextVariants.h4}>
                  <GripVerticalIcon></GripVerticalIcon>
                  <Switch
                    id={`checkbox-${index}`}
                    isChecked={item.visible}
                    onChange={(checked) => onItemToggle(index, checked)}
                    className="pf-u-px-md"
                    aria-label="Show this chart?"
                  />
                  {item.name}
                </Text>
              </TextContent>
            </Draggable>
          ))}
        </Droppable>
      </DragDrop>

      <ActionGroup className="pf-u-mt-lg">
        <Button onClick={() => save()}>Apply</Button>
        <Button variant="link" onClick={() => props.closeModal()}>
          Cancel
        </Button>
      </ActionGroup>
    </React.Fragment>
  )
}

const ChartsOrderComponent = React.memo(component)

export { ChartsOrderComponent }
