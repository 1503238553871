import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { AxiosResponse } from 'axios'
import * as React from 'react'
import { CodeBlock, CodeBlockCode, Form, FormGroup, FormSection, ModalVariant, Popover, Spinner, Tab, TabTitleText, Tabs, TextArea, TextInput } from '@patternfly/react-core'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { groupBy, orderBy, toArray } from 'lodash'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { CustomerImage } from './CustomerImage'
import { HelpIcon } from '@patternfly/react-icons'

interface IDatapoint {
  handle: string
  value: string
  customer_id: string
  submitted_at: Date
}

interface IDataDefinitionI18nValue {
  en: string
  da: string
}

interface IDataDefinitionMetadata {
  order: number | null
  displayFieldType: 'text' | 'image' | 'textarea'
  [x: string]: unknown
}

interface IDataDefinition {
  dataDefinitionId: number
  handle: string
  valueType: string
  createdAt: Date
  updatedAt: Date
  handleTitle: IDataDefinitionI18nValue
  tagTitle: IDataDefinitionI18nValue
  description: string
  metadata: IDataDefinitionMetadata
  tag: string
  value?: string | boolean | number | undefined
}

interface IDataDefinitionGroup {
  tag: string
  tagTitle: IDataDefinitionI18nValue
  definitions: IDataDefinition[]
}

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const orderedDefinitionGroupTags = ['profile', 'condition', 'skin_disease_assessment', 'other_information']

const aggregatedDatasetRecords = (dataset: IDatapoint[], dataDefinitions: IDataDefinition[]): IDataDefinitionGroup[] => {
  // group by tag
  const groups = toArray(groupBy(dataDefinitions, 'tag')).map((p) => {
    return {
      tag: p[0].tag,
      tagTitle: p[0].tagTitle,
      definitions: p,
    }
  })

  // assign value from dataset
  for (const dataDefinitionGroups of groups) {
    dataDefinitionGroups.definitions = orderBy(dataDefinitionGroups.definitions, ['metadata.order'], 'asc')

    for (const dataDefinition of dataDefinitionGroups.definitions) {
      if (dataset.find((p) => p.handle === dataDefinition.handle)) {
        dataDefinition.value = dataset.find((p) => p.handle === dataDefinition.handle)?.value
      }
    }
  }

  const ordered: IDataDefinitionGroup[] = []

  for (const tag of orderedDefinitionGroupTags) {
    const group = groups.find((p) => p.tag === tag)

    if (group?.definitions.filter((p) => p.value !== undefined && typeof p.metadata.order === 'number').length) {
      ordered.push(group)
    }
  }

  return ordered
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [loading, setLoading] = React.useState(true)
  const [dataDefinitions, setDataDefinitions] = React.useState<(IDataDefinitionGroup | undefined)[]>([])

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetDataset,
      data: {
        customerId: props.data.customer_id,
        datasetId: props.data.data_set_id,
      },
    }

    Promise.all([api.current?.post(BackofficeAPIConfig.Domains.Customer, payload), api.current?.get(`${BackofficeAPIConfig.VersaURL}?action=get-data-definitions`)])
      .then((responses) => {
        const dataset: IDatapoint[] = (responses[0] as AxiosResponse).data.result as IDatapoint[]
        const definitions: IDataDefinition[] = (responses[1] as AxiosResponse).data.definitions as IDataDefinition[]

        setDataDefinitions(aggregatedDatasetRecords(dataset, definitions))
        setLoading(false)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const toggleModal = (id: string) => {
    setIsModalOpen(!isModalOpen)
  }

  const imageClick = (e: React.MouseEvent, value: string | number | boolean | undefined) => {
    e.preventDefault()

    const url = value ? encodeURIComponent(value.toString()) : ''

    defaultModalProps.title = 'Timeline image'
    defaultModalProps.description = ``
    defaultModalProps.component = CustomerImage
    defaultModalProps.data = { value: url }
    defaultModalProps.size = ModalVariant.default

    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)
  }

  const skinImageSpinnerRef = React.useRef<HTMLDivElement>(null)

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      {loading && <Spinner />}
      {!loading && (
        <Form isHorizontal className="customer-dataset-form">
          <Tabs isBox={true} defaultActiveKey={0} role="region">
            {dataDefinitions.map((dataDefinitionGroup, index) => (
              <Tab eventKey={index} title={<TabTitleText>{dataDefinitionGroup?.tagTitle.en}</TabTitleText>} key={index}>
                <FormSection key={index} title={dataDefinitionGroup?.tagTitle.en} titleElement="h2">
                  {dataDefinitionGroup?.definitions
                    .filter((p) => p.value !== undefined && typeof p.metadata.order === 'number')
                    .map((definition, index2) => (
                      <React.Fragment key={index + index2}>
                        <FormGroup
                          label={definition.handleTitle.en}
                          fieldId={definition.handle}
                          labelIcon={
                            <Popover
                              maxWidth="600px"
                              bodyContent={
                                <CodeBlock>
                                  <CodeBlockCode style={{ fontSize: '12px' }}>{JSON.stringify(definition, null, 2)}</CodeBlockCode>
                                </CodeBlock>
                              }
                            >
                              <button type="button" onClick={(e) => e.preventDefault()} className="pf-c-form__group-label-help">
                                <HelpIcon noVerticalAlign />
                              </button>
                            </Popover>
                          }
                        >
                          {definition.metadata.displayFieldType === 'text' && (
                            <TextInput isDisabled type="text" id={definition.handle} name={definition.handle} value={definition.value?.toString()} />
                          )}

                          {definition.metadata.displayFieldType === 'textarea' && (
                            <TextArea isDisabled rows={4} id={definition.handle} name={definition.handle} value={definition.value?.toString()}></TextArea>
                          )}

                          {definition.metadata.displayFieldType === 'image' && typeof definition.value === 'string' && (
                            <React.Fragment>
                              <div ref={skinImageSpinnerRef}>
                                <Spinner size="md"></Spinner>
                              </div>
                              <img
                                width={100}
                                height={100}
                                src={`${BackofficeAPIConfig.MediaURL}?url=${encodeURIComponent(definition.value?.toString())}&width=100&height=100`}
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => imageClick(e, definition.value)}
                                onLoad={() => (skinImageSpinnerRef?.current ? (skinImageSpinnerRef.current.style.display = 'none') : null)}
                              />
                            </React.Fragment>
                          )}
                        </FormGroup>
                      </React.Fragment>
                    ))}
                </FormSection>
              </Tab>
            ))}
          </Tabs>
          <br />
        </Form>
      )}
    </React.Fragment>
  )
}

const CustomerDataset = React.memo(component)

export { CustomerDataset }

/*

*/
