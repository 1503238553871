import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { Spinner } from '@patternfly/react-core'
import { AxiosResponse } from 'axios'
import * as React from 'react'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [iframeUrl, setIframeUrl] = React.useState<string | undefined>(undefined)
  const [loading, setLoading] = React.useState(true)
  const iframeRef = React.useRef<HTMLIFrameElement>(null)

  const payload: BackofficeApiEventSchema = {
    action: BackofficeApiActions.GetImpersonateUrl,
    data: {
      customerId: props.data.customerId,
    },
  }

  React.useEffect(() => {
    const abortController = new AbortController()

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const _url = response?.data.result + '&redirectTo=%2Fprofile%2Fproducts-backoffice'

        setIframeUrl(_url)
        setLoading(false)

        if (iframeRef.current) {
          iframeRef.current.addEventListener('load', iframeOnLoad, false)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const iframeOnLoad = () => {
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className={`iframe-container ${loading ? 'loading' : 'loaded'}`}>
        {loading && <Spinner className="spinner" />}
        <iframe ref={iframeRef} src={iframeUrl} frameBorder="0" allow="clipboard-write;" referrerPolicy="strict-origin-when-cross-origin"></iframe>
      </div>
    </React.Fragment>
  )
}

const CustomerRecommendationWebsite = React.memo(component)

export { CustomerRecommendationWebsite }
