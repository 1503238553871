import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { IBayesianModel } from '@lib/Model/BayesianModel'
import { Button, DescriptionList, DescriptionListDescription, DescriptionListGroup, DescriptionListTerm, Label } from '@patternfly/react-core'
import moment from 'moment-timezone'
import React from 'react'
import { useHistory } from 'react-router-dom'

interface Props {
  data: IBayesianModel
}

const BMOverview: React.FunctionComponent<Props> = (props: Props) => {
  const api = useAxios()
  const history = useHistory()
  const [loading, setLoading] = React.useState<boolean>(false)
  const model = props.data

  const deleteModel = async () => {
    if (!confirm(`Confirm model (${model.modelName}) deletion?`)) return

    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.DeleteBMModel,
        data: {
          modelId: model.modelId,
        },
      })

      if (response?.status === 200) {
        setTimeout(() => {
          history.push('/model/bayesian')
        }, 500)
      } else {
        throw new Error(`Error: ${JSON.stringify(response?.data)}`)
      }
    } catch (error) {
      alert(getErrorMessage(error))
    }
  }

  const duplicateModel = async () => {
    const newModelName = prompt(`New model name:`, `duplicate_of_${model.modelName}`)
    if (!newModelName) throw new Error('Missing model name')
    setLoading(true)

    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.DuplicateBMModel,
        data: {
          modelName: model.modelName,
          newModelName,
        },
      })

      if (response?.status === 200) {
        setLoading(false)

        setTimeout(() => {
          history.push('/model/bayesian')
        }, 500)
      } else {
        throw new Error(`Error: ${JSON.stringify(response?.data)}`)
      }
    } catch (error) {
      alert(getErrorMessage(error))
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <DescriptionList isCompact isAutoFit>
        <DescriptionListGroup>
          <DescriptionListTerm>Model ID</DescriptionListTerm>
          <DescriptionListDescription>{model.modelId}</DescriptionListDescription>
        </DescriptionListGroup>

        <DescriptionListGroup>
          <DescriptionListTerm>Model key</DescriptionListTerm>
          <DescriptionListDescription>{model.modelName}</DescriptionListDescription>
        </DescriptionListGroup>

        <DescriptionListGroup>
          <DescriptionListTerm>Status</DescriptionListTerm>
          <DescriptionListDescription>
            {model.active && (
              <Label isCompact color="green">
                Active
              </Label>
            )}
            {model.active === false && (
              <Label isCompact color="red">
                Inactive
              </Label>
            )}
          </DescriptionListDescription>
        </DescriptionListGroup>

        <DescriptionListGroup>
          <DescriptionListTerm>Products</DescriptionListTerm>
          <DescriptionListDescription>
            {model.skuList.map((value, index) => (
              <React.Fragment key={index}>
                <Label isCompact color="cyan" key={index}>
                  {value}
                </Label>{' '}
              </React.Fragment>
            ))}
          </DescriptionListDescription>
        </DescriptionListGroup>

        <DescriptionListGroup>
          <DescriptionListTerm>Prediction distribution</DescriptionListTerm>
          <DescriptionListDescription>
            {Object.entries(model.predictionDistribution).map((value, index) => (
              <React.Fragment key={index}>
                <Label isCompact key={index} color="blue">
                  {value[0]}: <b>{value[1]}</b>
                </Label>{' '}
              </React.Fragment>
            ))}
          </DescriptionListDescription>
        </DescriptionListGroup>

        <DescriptionListGroup>
          <DescriptionListTerm>Type</DescriptionListTerm>
          <DescriptionListDescription>
            <Label isCompact color="cyan">
              {model.type}
            </Label>
          </DescriptionListDescription>
        </DescriptionListGroup>

        {typeof model.metadata === 'object' && typeof model.metadata['retrainingSuccessIndicators'] === 'object' && (
          <DescriptionListGroup>
            <DescriptionListTerm>Retraining success indicators</DescriptionListTerm>
            <DescriptionListDescription>
              {model.metadata['retrainingSuccessIndicators'].map((value, index) => (
                <React.Fragment key={index}>
                  <Label key={index} isCompact color="purple">
                    {value}
                  </Label>{' '}
                </React.Fragment>
              ))}
            </DescriptionListDescription>
          </DescriptionListGroup>
        )}

        <DescriptionListGroup>
          <DescriptionListTerm>Number of clusters</DescriptionListTerm>
          <DescriptionListDescription>{model.numClusters}</DescriptionListDescription>
        </DescriptionListGroup>

        <DescriptionListGroup>
          <DescriptionListTerm>Created</DescriptionListTerm>
          <DescriptionListDescription>{moment(model.createdAt).format('YYYY-MM-DD HH:mm:ss')}</DescriptionListDescription>
        </DescriptionListGroup>

        <DescriptionListGroup>
          <DescriptionListTerm>Modified</DescriptionListTerm>
          <DescriptionListDescription>{moment(model.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</DescriptionListDescription>
        </DescriptionListGroup>

        <DescriptionListGroup>
          <DescriptionListTerm>Actions</DescriptionListTerm>
          <DescriptionListDescription>
            <Button isSmall variant="tertiary" onClick={() => alert('Not implemented')}>
              Edit
            </Button>
            <Button isSmall variant="tertiary" onClick={() => duplicateModel()} className="pf-u-ml-sm" isLoading={loading} isDisabled={loading}>
              Duplicate
            </Button>

            {!model.active && (
              <Button isSmall variant="danger" onClick={() => deleteModel()} className="pf-u-ml-sm">
                Delete
              </Button>
            )}
          </DescriptionListDescription>
        </DescriptionListGroup>
      </DescriptionList>
    </React.Fragment>
  )
}

export { BMOverview }
