import { NoResultsInTable } from '@app/components/table/NoResultsInTable'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { Card, PageSection, PageSectionVariants, SearchInput, Spinner, Text, TextContent, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem } from '@patternfly/react-core'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { AxiosResponse } from 'axios'
import { orderBy, trim } from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Fuse from 'fuse.js'

interface IPatientRecord {
  customerId: number
  firstName: string
  lastName: string
  createdAt: Date
  addressCity: string
  addressPostalCode: string
  dob: Date
  dobCprLike: string
}

const columnNames = {
  name: 'Name',
  dob: 'DOB',
  city: 'City',
  zip: 'Postal code',
  created: 'Date created',
}

const RenderRow = ({ record, index }: { record: IPatientRecord; index: number }) => {
  const history = useHistory()

  return (
    <Tr key={index}>
      <Td width={40} dataLabel={columnNames.name}>
        <a onClick={() => history.push(`/customer/${record.customerId}`)}>
          {record.firstName} {record.lastName}
        </a>
      </Td>
      <Td width={15} dataLabel={columnNames.dob}>
        {moment(record.dob).format('YYYY-MM-DD')}
      </Td>
      <Td width={15} dataLabel={columnNames.city}>
        {record.addressCity}
      </Td>
      <Td width={15} dataLabel={columnNames.zip}>
        {record.addressPostalCode}
      </Td>
      <Td width={15} dataLabel={columnNames.created}>
        {moment(record.createdAt).format('YYYY-MM-DD')}
      </Td>
    </Tr>
  )
}

const fuse = new Fuse([] as IPatientRecord[], {
  includeScore: true,
  includeMatches: true,
  threshold: 0.2,
  keys: ['firstName', 'lastName', 'addressCity', 'addressPostalCode', 'dob', 'dobCprLike'],
})

const YourPatientsHome: React.FunctionComponent = () => {
  const api = useAxios()
  const [dataset, setDataset] = React.useState<IPatientRecord[]>([])
  const [searchResults, setSearchResults] = React.useState<IPatientRecord[]>([])
  const [loading, setLoading] = React.useState(false)
  const [searchInputValue, setSearchInputValue] = React.useState('')

  React.useEffect(() => {
    setLoading(true)

    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetPatientListByDatasetHandle,
      data: {},
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        let dataset: IPatientRecord[] = response?.data.result as IPatientRecord[]

        dataset = orderBy(dataset, 'firstName', 'asc').map((p) => {
          return {
            ...p,
            dobCprLike: moment(new Date(p.dob)).format('DDMMYY'),
          }
        })

        console.log(dataset)

        setDataset(dataset)
        fuse.setCollection(dataset)
        setLoading(false)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const onSearch = () => {
    const results = fuse.search(searchInputValue)

    if (trim(searchInputValue) === '') {
      onClear()
      return
    }

    if (results.length) {
      setSearchResults(results.map((result) => result.item))
    } else {
      alert(`No results for the query: ${searchInputValue}`)
    }
  }

  const onClear = () => {
    setSearchInputValue('')
    setSearchResults([])
  }

  return (
    <React.Fragment>
      <PageSection variant={PageSectionVariants.light}>
        <TextContent>
          <Text component="h1">Your patients</Text>
          <Text component="p">Search based on patient name or date of birth.</Text>
        </TextContent>
      </PageSection>
      <PageSection isFilled variant={PageSectionVariants.default}>
        {loading && <Spinner />}
        {!loading && (
          <Card>
            <Toolbar usePageInsets id="compact-toolbar">
              <ToolbarContent>
                <ToolbarGroup>
                  <ToolbarItem variant="search-filter" widths={{ default: '450px' }}>
                    <SearchInput
                      placeholder="Name, date of birth, city or postal code"
                      value={searchInputValue}
                      onChange={(e, value) => setSearchInputValue(value)}
                      onSearch={() => onSearch()}
                      onClear={() => onClear()}
                    />
                  </ToolbarItem>
                </ToolbarGroup>
              </ToolbarContent>
            </Toolbar>
            <TableComposable variant="compact" aria-label="Compact Table">
              <Thead>
                <Tr>
                  <Th key={0}>{columnNames.name}</Th>
                  <Th key={1}>{columnNames.dob}</Th>
                  <Th key={2}>{columnNames.city}</Th>
                  <Th key={3}>{columnNames.zip}</Th>
                  <Th key={4}>{columnNames.created}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchResults.length > 0 && (
                  <React.Fragment>
                    {searchResults.map((record, index) => (
                      <RenderRow key={index} index={index} record={record}></RenderRow>
                    ))}
                  </React.Fragment>
                )}

                {searchResults.length === 0 && (
                  <React.Fragment>
                    {dataset.map((record, index) => (
                      <RenderRow key={index} index={index} record={record}></RenderRow>
                    ))}
                  </React.Fragment>
                )}

                {!dataset.length && <NoResultsInTable></NoResultsInTable>}
              </Tbody>
            </TableComposable>
          </Card>
        )}
      </PageSection>
    </React.Fragment>
  )
}

export { YourPatientsHome }
