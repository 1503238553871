import React, { useEffect } from 'react'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import heic2any from 'heic2any'
import axios from 'axios'
import { Spinner } from '@patternfly/react-core'

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const url = new URL(decodeURIComponent(props.data.value))
  const [imageUrl, setImageUrl] = React.useState<string | undefined>(undefined)

  useEffect(() => {
    const getImageUrl = async () => {
      if (url.pathname.toLowerCase().endsWith('heic')) {
        const response = await axios.get(url.href, {
          responseType: 'blob',
          headers: {
            'Content-Type': 'image/heic',
            Accept: 'image/heic',
          },
        })

        const conversionResult = await heic2any({ blob: response.data })
        const newUrl = URL.createObjectURL(conversionResult as Blob)

        setImageUrl(newUrl)
      } else {
        setImageUrl(url.href)
      }
    }

    if (!imageUrl) getImageUrl()
  }, [])

  return (
    <React.Fragment>
      {typeof imageUrl === 'string' && <img src={imageUrl} width="100%" alt="User image"></img>}
      {typeof imageUrl === 'undefined' && <Spinner />}
    </React.Fragment>
  )
}

const CustomerImage = React.memo(component)

export { CustomerImage }
