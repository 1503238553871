import * as React from 'react'
import { Bullseye, EmptyState, EmptyStateIcon, EmptyStateVariant, Title } from '@patternfly/react-core'
import { SearchIcon } from '@patternfly/react-icons'
import { Td, Tr } from '@patternfly/react-table'

export const component: React.FunctionComponent = () => {
  return (
    <Tr>
      <Td colSpan={8}>
        <Bullseye>
          <EmptyState variant={EmptyStateVariant.small}>
            <EmptyStateIcon icon={SearchIcon} />
            <Title headingLevel="h2" size="lg">
              No results found
            </Title>
          </EmptyState>
        </Bullseye>
      </Td>
    </Tr>
  )
}

const NoResultsInTable = React.memo(component)

export { NoResultsInTable }
