import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { ActionGroup, Button, Form, FormGroup, TextArea, TextInput } from '@patternfly/react-core'
import moment from 'moment-timezone'
import React from 'react'

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const initialName = `My View - ${moment().format('DD-MM-yyyy')}`
  const [viewName, setViewName] = React.useState(initialName)
  const [viewDescription, setViewDescription] = React.useState('')

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    props.data['viewName'] = viewName
    props.data['viewDescription'] = viewDescription

    props.closeModal()
  }

  return (
    <React.Fragment>
      <Form onSubmit={(e) => onSubmit(e)} isHorizontal>
        <FormGroup label="View name">
          <TextInput isRequired type="text" id="viewName" name="viewName" value={viewName} onChange={(v) => setViewName(v)} />
        </FormGroup>
        <FormGroup label="Description (optional)">
          <TextArea id="viewDescription" name="viewDescription" value={viewDescription} onChange={(v) => setViewDescription(v)} rows={3}></TextArea>
        </FormGroup>
        <ActionGroup>
          <Button variant="primary" type="submit">
            Save
          </Button>
          <Button variant="link" onClick={() => props.closeModal()}>
            Cancel
          </Button>
        </ActionGroup>
      </Form>
    </React.Fragment>
  )
}

const SaveViewComponent = React.memo(component)

export { SaveViewComponent }
