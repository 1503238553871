import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { IPatientConversation } from '@lib/PatientConversation/IPatientConversation'
import { IPatientConversationAttachment } from '@lib/PatientConversation/IPatientConversationAttachment'
import { processAttachments } from '@lib/Attachments/processAttachments'
import { ActionGroup, Button, Chip, ChipGroup, CodeBlock, CodeBlockCode, Form, FormGroup, Spinner, TextArea, getUniqueId } from '@patternfly/react-core'
import { useKeycloak } from '@react-keycloak/web'
import { AxiosResponse } from 'axios'
import { orderBy } from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useHistory, useParams } from 'react-router-dom'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

interface IUpdatePatientConversationDTO {
  patientConversationId: string
  authorId: string
  content: string
  attachments: IPatientConversationAttachment[]
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [loading, setLoading] = React.useState(true)
  const [sending, setSending] = React.useState<boolean>(false)
  const [updateCounter, setUpdateCounter] = React.useState<number>(0)
  const [conversation, setConversation] = React.useState<IPatientConversation | null>(null)
  const [newMessageForm, setNewMessageForm] = React.useState<Record<string, any>>({
    content: '',
  })
  const { keycloak } = useKeycloak()
  const authorId = keycloak && keycloak.tokenParsed ? keycloak.tokenParsed['email'] : ''
  const rootElementId = getUniqueId('patient-conversation')
  const rootElementRef = React.useRef<HTMLDivElement>(null)
  const submitButtonRef = React.useRef<HTMLButtonElement>(null)
  const [files, setFiles] = React.useState<File[]>([])
  const queryParams = useParams()
  const history = useHistory()

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetPatientConversation,
      data: {
        patientConversationId: props.data.id,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const conversation = response?.data.result as IPatientConversation
        conversation.messages = orderBy(conversation.messages, ['creationDate'], ['asc'])

        setConversation(conversation)
        setLoading(false)
        scrollToBottom()
        props.setDescription(`You have a conversation with: ${conversation['name']}`)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [updateCounter])

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSending(true)

    let attachments: IPatientConversationAttachment[] = []

    if (files.length && api.current) {
      attachments = await processAttachments(parseInt(queryParams['id']), files, 'patient-conversation', api.current)
    }

    try {
      const payload: IUpdatePatientConversationDTO = {
        authorId: authorId,
        content: newMessageForm.content,
        patientConversationId: props.data.id,
        attachments,
      }
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Customer, {
        action: BackofficeApiActions.UpdatePatientConversation,
        data: payload,
      })

      if (response && response.data && response.data.result.success) {
        setSending(false)
        setUpdateCounter(updateCounter + 1)
        newMessageForm.content = ''
        setFiles([])
      } else {
        throw new Error('Something went wrong')
      }
    } catch (error) {
      setSending(false)
      alert(getErrorMessage(error))
    }
  }

  const onFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const txtFileInput = e.target as HTMLInputElement

    if (txtFileInput && txtFileInput.files) {
      const file = txtFileInput.files[0]
      setFiles((files) => [...files, file])
      txtFileInput.value = ''
    }
  }

  const removeFile = (_file: File) => {
    setFiles(files.filter((file) => file.name !== _file.name))
  }

  // Hack to always scroll to the bottom
  const scrollToBottom = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.scrollIntoView()
    }
  }

  React.useEffect(() => {
    props.onBeforeCloseModal.once((_props) => {
      if (queryParams['conversationId']) {
        history.push(`/customer/${queryParams['id']}?modal=conversations`)
      }
    })
  })

  return (
    <div className="patient-conversation" id={rootElementId} ref={rootElementRef}>
      {loading && <Spinner />}
      {!loading && conversation !== null && (
        <React.Fragment>
          {conversation.messages.map((message, index) => (
            <React.Fragment key={index}>
              {/* Employee */}
              {message.authorId !== undefined && (
                <React.Fragment>
                  <CodeBlock className="pf-u-mb-md pf-u-w-75 pf-u-float-right pf-u-background-color-active-color-300" style={{ position: 'relative' }}>
                    <div className="conversation-message-details-employee">
                      <span>{moment(message.creationDate).format('YYYY-MM-DD HH:mm:ss')}</span>
                      <span>
                        {message.authorFirstName} {message.authorLastName?.slice(0, 1)}
                      </span>
                    </div>
                    <CodeBlockCode>
                      <span className="pf-u-color-light-200" dangerouslySetInnerHTML={{ __html: message.content }}></span>
                      {message.attachments.length > 0 && (
                        <span className="pf-u-display-block pf-u-mt-md">
                          {message.attachments.map((attachment, i) => (
                            <a key={i} className="pf-u-display-inline-block pf-u-pr-sm" style={{ color: '#ccc' }} href={attachment.url} target="_blank">
                              {attachment.title}
                            </a>
                          ))}
                        </span>
                      )}
                    </CodeBlockCode>
                  </CodeBlock>
                  <div className="clearfix"></div>
                </React.Fragment>
              )}
              {/* Customer */}
              {message.authorId === undefined && (
                <CodeBlock className="pf-u-mb-md pf-u-w-75" style={{ position: 'relative' }}>
                  <div className="conversation-message-details-customer">
                    <span>{moment(message.creationDate).format('YYYY-MM-DD HH:mm:ss')}</span>
                  </div>
                  <CodeBlockCode>
                    <span className="pf-u-display-block" dangerouslySetInnerHTML={{ __html: message.content }}></span>
                    {message.attachments.length > 0 && (
                      <span className="pf-u-display-block pf-u-mt-md">
                        {message.attachments.map((attachment, i) => (
                          <a key={i} className="pf-u-display-inline-block pf-u-pr-sm" href={attachment.url} target="_blank">
                            {attachment.title}
                          </a>
                        ))}
                      </span>
                    )}
                  </CodeBlockCode>
                </CodeBlock>
              )}
            </React.Fragment>
          ))}
          <Form onSubmit={submit} className="pf-u-mt-xl">
            <FormGroup>
              <TextArea id="txt-message" onChange={(value) => setNewMessageForm({ ...newMessageForm, content: value })} rows={8} value={newMessageForm.content}></TextArea>
            </FormGroup>
            {files.length > 0 && (
              <ChipGroup categoryName="Attachments:">
                {files.map((file, index) => (
                  <Chip key={index} textMaxWidth={'200px'} onClick={() => removeFile(file)}>
                    {file.name}
                  </Chip>
                ))}
              </ChipGroup>
            )}
            <ActionGroup>
              <Button variant="primary" type="submit" isLoading={sending} isDisabled={sending || !newMessageForm.content} ref={submitButtonRef}>
                Send
              </Button>
              <label className="pf-c-button pf-m-inline pf-m-link pf-u-mr-lg" htmlFor="file" style={{ cursor: 'pointer', lineHeight: '33px' }}>
                Select attachments
              </label>
              <input id="file" type="file" onChange={(e) => onFileChanged(e)} style={{ opacity: 0, position: 'absolute', zIndex: -1 }}></input>
            </ActionGroup>
          </Form>
        </React.Fragment>
      )}
    </div>
  )
}

const CustomerConversation = React.memo(component)

export { CustomerConversation }
