import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useAxios } from '@app/utils/useAxios'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { Grid, GridItem, Spinner } from '@patternfly/react-core'
import { IDataPoint, IDataDefinition } from '../IData'

const componentReadDataDefinition: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  // Fixed
  const api = useAxios()
  const history = useHistory()
  const modalTitles = {
    read: 'Viewing data definition',
  }

  // Reactive
  const [action, setAction] = React.useState<string>(props.data.action)
  const [id, setId] = React.useState<number | undefined>(props.data.id)
  const [loading, setLoading] = React.useState<boolean>(true)

  const [dataDefinition, setDataDefinition] = React.useState<IDataDefinition | undefined>(undefined)
  const [mergedDataPoints, setMergedDataPoints] = React.useState<{ dataPoint: IDataPoint; dataDefinition: IDataDefinition }[]>([])

  React.useEffect(() => {
    setLoading(true)
    props.setTitle(modalTitles[action])

    const abortController = new AbortController()

    const fetchDataDefinition = async (id: number) => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.Data, {
          action: BackofficeApiActions.ReadDataDefinition,
          data: {
            id,
          },
        })
        if (response?.data.result) {
          return response?.data.result as IDataDefinition
        } else {
          throw new Error('No data set received.')
        }
      } catch (error) {
        throw error
      }
    }

    const fetchAll = async (id: number) => {
      const dd = await fetchDataDefinition(id)
      setDataDefinition(dd)
    }

    if (id) {
      fetchAll(id)
        .catch((error) => {
          alert(getErrorMessage(error))
        })
        .finally(() => {
          setLoading(false)
        })
    }

    return () => {
      abortController.abort()
    }
  }, [action])

  React.useEffect(() => {
    props.onBeforeCloseModal.once((_props) => {
      history.push(`/data/data_definitions`)
    })
  })

  return (
    <React.Fragment>
      {loading && <Spinner></Spinner>}
      {!loading && (
        <Grid hasGutter>
          <GridItem span={4}>
            <strong>ID:</strong> {dataDefinition?.id}
          </GridItem>
          <GridItem span={4}>
            <strong>Handle:</strong> {dataDefinition?.handle}
          </GridItem>
          <GridItem span={4}>
            <strong>Value Type:</strong> {dataDefinition?.valueType}
          </GridItem>
          <GridItem span={12}>
            <strong>Text:</strong> {dataDefinition?.handleTitle.en}
          </GridItem>
        </Grid>
      )}
    </React.Fragment>
  )
}

const ReadDataDefinition = React.memo(componentReadDataDefinition)

export { ReadDataDefinition }
