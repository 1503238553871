import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { IBayesianModel } from '@lib/Model/BayesianModel'
import {
  Breadcrumb,
  BreadcrumbItem,
  PageSection,
  PageSectionVariants,
  Spinner,
  Tab,
  TabContent,
  TabContentBody,
  TabTitleText,
  Tabs,
  Text,
  TextContent,
} from '@patternfly/react-core'
import React from 'react'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { AxiosResponse } from 'axios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { BMOverview } from './Tabs/BMOverview'
import { BMClusters } from './Tabs/BMClusters'
import { useHistory, useParams } from 'react-router-dom'

const BayesianModel: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const history = useHistory()
  const pathParams = useParams()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [model, setModel] = React.useState<IBayesianModel>()

  React.useEffect(() => {
    const abortController = new AbortController()

    setLoading(true)

    api.current
      ?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.ListBMModel,
        data: {
          modelName: pathParams['modelName'],
        },
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          setModel(response.data.result as IBayesianModel)
          setLoading(false)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const goto = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, where: string) => {
    e.preventDefault()
    history.push(where)
  }

  return (
    <React.Fragment>
      {loading && <Spinner />}
      {!loading && model !== undefined && (
        <React.Fragment>
          <PageSection variant={PageSectionVariants.light}>
            <Breadcrumb className="pf-u-mb-md">
              <BreadcrumbItem to="/model/bayesian" onClick={(e) => goto(e, '/model/bayesian')}>
                Model management
              </BreadcrumbItem>
              <BreadcrumbItem isActive>Model: {model.modelName}</BreadcrumbItem>
            </Breadcrumb>
            <TextContent>
              <Text component="h1">Model: {model.modelNameHumanReadable}</Text>
            </TextContent>
          </PageSection>
          <PageSection type="tabs">
            <Tabs defaultActiveKey={0} usePageInsets id="nested-tabs-list" isBox>
              <Tab eventKey={0} title={<TabTitleText>Overview</TabTitleText>} tabContentId={`tabContent${0}`}>
                <TabContent id={`tabContent${0}`}>
                  <TabContentBody hasPadding children={<BMOverview data={model}></BMOverview>}></TabContentBody>
                </TabContent>
              </Tab>
              <Tab eventKey={1} title={<TabTitleText>Clusters</TabTitleText>} tabContentId={`tabContent${1}`}>
                <TabContent id={`tabContent${1}`}>
                  <TabContentBody hasPadding children={<BMClusters data={model}></BMClusters>}></TabContentBody>
                </TabContent>
              </Tab>
            </Tabs>
          </PageSection>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export { BayesianModel }
