import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { AxiosResponse } from 'axios'
import React from 'react'
import { LRUCache } from 'lru-cache'

const cache = new LRUCache({ max: 500 })

interface CustomerLink {
  name: string
  value: string
}

export interface PatientDetailsDTO {
  id: number
  email: string
  phone?: string
  whatsapp?: string
  locale: string
  name: string
  address: string
  created: Date
  links: CustomerLink[]
  membership?: string
  dob: string
  age: string
  gender: string
}

export const usePatientDetails = (customerId: string) => {
  const api = useAxios()
  const [customerDetails, setCustomerDetails] = React.useState<PatientDetailsDTO>()
  const cacheKey = `patient-details-${customerId}`

  React.useEffect(() => {
    const abortController = new AbortController()

    if (cache.get(cacheKey)) {
      const result = cache.get(cacheKey) as PatientDetailsDTO

      setCustomerDetails(result)
    } else {
      api.current
        ?.post(BackofficeAPIConfig.Domains.Customer, {
          action: BackofficeApiActions.GetDetails,
          data: { customerId },
        })
        .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
          const result = response?.data.result as PatientDetailsDTO
          setCustomerDetails(result)
          cache.set(cacheKey, result)
        })
        .catch((error) => {
          alert(getErrorMessage(error))
        })
    }

    return () => {
      abortController.abort()
    }
  }, [])

  return customerDetails
}
