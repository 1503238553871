import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useAxios } from '@app/utils/useAxios'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { AxiosResponse } from 'axios'
import { ActionGroup, Alert, Button, Form, FormAlert, FormGroup, FormSelect, FormSelectOption, Grid, GridItem, Spinner, TextArea, TextInput } from '@patternfly/react-core'
import { IMiniModel } from '../IMiniModel'
import { ISuccessRateIndicator, ISuccessRateIndicatorFormHelpers, ISuccessRateIndicatorUpdateDTO } from '../ISuccessRateIndicator'

const componentCreateReadUpdateSuccessRateIndicator: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  // Fixed
  const api = useAxios()
  const history = useHistory()
  const modalTitles = {
    create: 'Creating success indicator',
    read: 'Viewing success indicator',
    update: 'Updating success indicator',
  }

  // Reactive
  const [action, setAction] = React.useState<string>(props.data.action)
  const [id, setId] = React.useState<number | undefined>(props.data.id ? parseInt(`${props.data.id}`, 10) : undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [sending, setSending] = React.useState<boolean>(false)
  const [isReadOnly, setIsReadOnly] = React.useState<boolean>(action === 'read')
  const [validated, setValidated] = React.useState<string>('default')
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [saved, setSaved] = React.useState<boolean>(false)

  const [successRateIndicator, setSuccessRateIndicator] = React.useState<ISuccessRateIndicator | undefined>(undefined)

  const [formName, setFormName] = React.useState<string>('')
  const [formUnderlyingData, setFormUnderlyingData] = React.useState<string>('')
  const [formMiniModelId, setFormMiniModelId] = React.useState<string>('')
  const [helperNames, setHelperNames] = React.useState<string[]>([])
  const [helperMiniModels, setHelperMiniModels] = React.useState<IMiniModel[]>([])

  const isUnique = (value: string, list: string[]) => {
    return typeof list.find((n) => n.toLowerCase() === value.toLowerCase()) === 'undefined'
  }

  React.useEffect(() => {
    setLoading(true)
    props.setTitle(modalTitles[action])

    const abortController = new AbortController()

    const fetchSuccessRateIndicator = async (id: number) => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.MiniModel, {
          action: BackofficeApiActions.ReadSuccessRateIndicator,
          data: {
            id,
          },
        })
        if (response?.data.result) {
          return response?.data.result as ISuccessRateIndicator
        }
        return undefined
      } catch (error) {
        throw error
      }
    }

    const fetchMiniModels = async (id?: number) => {
      try {
        const params: Record<string, any> = { limit: 0, sortColumn: 'mini_model_name', sortOrder: 'asc' }
        if (id) {
          params.filters = { ids: [id] }
        }
        const response = await api.current?.post(BackofficeAPIConfig.Domains.MiniModel, {
          action: BackofficeApiActions.ListMiniModels,
          data: params,
        })
        if (response?.data.result) {
          return response?.data.result as IMiniModel[]
        }
        return undefined
      } catch (error) {
        throw error
      }
    }

    const fetchFormHelpers = async () => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.MiniModel, {
          action: BackofficeApiActions.FormHelpersSuccessRateIndicators,
        })
        if (response?.data.result) {
          return response?.data.result as ISuccessRateIndicatorFormHelpers
        }
        return undefined
      } catch (error) {
        throw error
      }
    }

    const fetchAll = async (id: number | undefined) => {
      let fetchedSuccessRateIndicator: ISuccessRateIndicator | undefined
      if (id) {
        fetchedSuccessRateIndicator = await fetchSuccessRateIndicator(id)
        if (fetchedSuccessRateIndicator) {
          setSuccessRateIndicator(fetchedSuccessRateIndicator)
          setFormName(fetchedSuccessRateIndicator.name)
          setFormUnderlyingData(fetchedSuccessRateIndicator.underlyingData)
          setFormMiniModelId(`${fetchedSuccessRateIndicator.miniModelId}`)
        }
      }

      const miniModels = await fetchMiniModels(typeof fetchedSuccessRateIndicator !== 'undefined' && action === 'read' ? fetchedSuccessRateIndicator?.miniModelId : undefined)
      if (typeof miniModels !== 'undefined') {
        setHelperMiniModels(miniModels)
      }

      if (['create', 'update'].includes(action)) {
        const formHelpers = await fetchFormHelpers()
        if (formHelpers) {
          setHelperNames(
            typeof fetchedSuccessRateIndicator?.name === 'string'
              ? formHelpers.names.filter((name) => name.toLowerCase() !== fetchedSuccessRateIndicator?.name.toLowerCase())
              : formHelpers.names
          )
        }
      }

      return true
    }

    fetchAll(id)
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [action])

  React.useEffect(() => {
    props.onBeforeCloseModal.once((_props) => {
      history.push(`/data_refinement/success_indicators`)
    })
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setValidated('default')
    setErrorMessage('')

    // Check for required fields
    if (![formName, formUnderlyingData, formMiniModelId].every((n) => n)) {
      setValidated('error')
      setErrorMessage('Fill out all required fields before continuing.')
      return
    }

    // Check for unique fields
    const uniqueFields = [['Name', formName, helperNames]]

    for (let i = 0; i < uniqueFields.length; i += 1) {
      const fieldName = uniqueFields[i][0] as string
      const fieldValue = uniqueFields[i][1] as string
      const fieldList = uniqueFields[i][2] as string[]
      if (fieldValue && !isUnique(fieldValue, fieldList)) {
        setValidated('error')
        setErrorMessage(`The "${fieldName}" field is not unique.`)
        return
      }
    }

    let defaultData = successRateIndicator || { metadata: {} }

    let data: Partial<ISuccessRateIndicatorUpdateDTO> = {
      ...defaultData,
      name: formName.trim(),
      underlyingData: formUnderlyingData.trim(),
      miniModelId: parseInt(formMiniModelId, 10),
    }

    setSending(true)
    setSaved(false)

    api.current
      ?.post(BackofficeAPIConfig.Domains.MiniModel, {
        action: action === 'create' ? BackofficeApiActions.CreateSuccessRateIndicator : BackofficeApiActions.UpdateSuccessRateIndicator,
        data,
      } as BackofficeApiEventSchema)
      .then((response: AxiosResponse<BackofficeApiEventResponse> | undefined) => {
        if (response?.data.result) {
          const { id } = response.data.result as ISuccessRateIndicator
          if (action === 'create') {
            setAction('update')
            setId(id)
            history.replace('/data_refinement/success_indicator/update/' + id)
          }
          setSaved(true)
          if (props.data.toggleRefresh) {
            props.data.toggleRefresh()
          }
        } else {
          throw new Error('Failed to get response.')
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setSending(false)
      })
  }

  return (
    <React.Fragment>
      {loading && <Spinner></Spinner>}
      {!loading && (
        <Form onSubmit={handleSubmit}>
          {validated === 'error' && (
            <FormAlert>
              <Alert variant="danger" title={errorMessage} aria-live="polite" isInline />
            </FormAlert>
          )}
          {saved && (
            <FormAlert>
              <Alert variant="success" title="Mini model saved." aria-live="polite" isInline />
            </FormAlert>
          )}
          <Grid hasGutter>
            <GridItem span={12}>
              <FormGroup label="Name (must be unique)" isRequired>
                <TextInput isRequired id="formName" type="text" value={formName} readOnly={isReadOnly} onChange={(val) => setFormName(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={12}>
              <FormGroup label="Task">
                <TextArea
                  id="formUnderlyingData"
                  type="text"
                  value={formUnderlyingData}
                  readOnly={isReadOnly}
                  onChange={(val) => setFormUnderlyingData(val)}
                  resizeOrientation="vertical"
                ></TextArea>
              </FormGroup>
            </GridItem>
            <GridItem span={12}>
              <FormGroup label="Data Task" isRequired>
                <FormSelect isRequired id="formMiniModelId" value={formMiniModelId} readOnly={isReadOnly} onChange={(val) => setFormMiniModelId(val)}>
                  <FormSelectOption key={-1} value={''} label="Select data task..." />
                  {helperMiniModels.map((option, index) => (
                    <FormSelectOption key={index} value={`${option.id}`} label={option.name} />
                  ))}
                </FormSelect>
              </FormGroup>
            </GridItem>
            {!isReadOnly && (
              <GridItem offset={9} span={3}>
                <ActionGroup style={{ float: 'right' }}>
                  <Button variant="primary" type="submit" isLoading={sending}>
                    Submit
                  </Button>
                  <Button variant="link" type="button" onClick={() => props.closeModal()}>
                    Cancel
                  </Button>
                </ActionGroup>
              </GridItem>
            )}
          </Grid>
        </Form>
      )}
    </React.Fragment>
  )
}

const CreateReadUpdateSuccessRateIndicator = React.memo(componentCreateReadUpdateSuccessRateIndicator)

export { CreateReadUpdateSuccessRateIndicator }
