import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useAxios } from '@app/utils/useAxios'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ISkincareIngredient, ISkincareIngredientCreateDTO, ISkincareIngredientFormHelpers, ISkincareIngredientUpdateDTO } from '../IProduct'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { AxiosResponse } from 'axios'
import { ActionGroup, Alert, Button, Form, FormAlert, FormGroup, Grid, GridItem, Spinner, TextInput, TextInputGroup, TextInputGroupMain } from '@patternfly/react-core'
import { TagInput } from '@app/components/textInput/TagInput'

const componentCreateReadUpdateIngredient: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  // Fixed
  const api = useAxios()
  const history = useHistory()
  const modalTitles = {
    create: 'Creating ingredient',
    read: 'Viewing ingredient',
    update: 'Updating ingredient',
  }

  // Reactive
  const [action, setAction] = React.useState<string>(props.data.action)
  const [id, setId] = React.useState<string | undefined>(props.data.id)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [sending, setSending] = React.useState<boolean>(false)
  const [isReadOnly, setIsReadOnly] = React.useState<boolean>(action === 'read')
  const [validated, setValidated] = React.useState<string>('default')
  const [saved, setSaved] = React.useState<boolean>(false)

  const [ingredient, setIngredient] = React.useState<ISkincareIngredient | undefined>(undefined)

  const [formInciName, setFormInciName] = React.useState<string>('')
  const [formPhase, setFormPhase] = React.useState<string>('')
  const [formCasNumber, setFormCasNumber] = React.useState<string>('')
  const [formCosingFunctionality, setFormCosingFunctionality] = React.useState<string[]>([])
  const [helperCosingFunctionalities, setHelperCosingFunctionalities] = React.useState<string[]>([])

  React.useEffect(() => {
    setLoading(true)
    console.log(action, id, modalTitles[action])
    props.setTitle(modalTitles[action])

    const abortController = new AbortController()

    const fetching: Promise<any | undefined>[] = []

    if (id) {
      const readPayload: BackofficeApiEventSchema = {
        action: BackofficeApiActions.ReadIngredient,
        data: {
          id,
        },
      }
      const fetch = api.current?.post(BackofficeAPIConfig.Domains.Product, readPayload).then((response: AxiosResponse<BackofficeApiEventResponse> | undefined) => {
        if (response?.data.result) {
          const result = response?.data.result as ISkincareIngredient
          setIngredient(result)
          setFormInciName(result.name)
          setFormCasNumber(result.casNumber)
          setFormPhase(result.phase)
          setFormCosingFunctionality(result.cosingFunctionality)
        }
      })
      if (fetch) {
        fetching.push(fetch)
      }
    }

    if (['create', 'update'].includes(action)) {
      const fetch = api.current
        ?.post(BackofficeAPIConfig.Domains.Product, { action: BackofficeApiActions.FormHelpersIngredients })
        .then((response: AxiosResponse<BackofficeApiEventResponse> | undefined) => {
          if (response?.data.result) {
            const result = response?.data.result as ISkincareIngredientFormHelpers
            setHelperCosingFunctionalities(result.cosingFunctionalities)
          }
        })
      if (fetch) {
        fetching.push(fetch)
      }
    }

    Promise.all(fetching)
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [action])

  React.useEffect(() => {
    props.onBeforeCloseModal.once((_props) => {
      history.push(`/product/ingredients`)
    })
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (![formInciName, formCasNumber, formPhase].every((n) => n)) {
      setValidated('error')
      return
    }

    let defaultData = ingredient || { metadata: {} }

    let data: Partial<ISkincareIngredientUpdateDTO> = {
      ...defaultData,
      name: formInciName,
      casNumber: formCasNumber,
      phase: formPhase,
      cosingFunctionality: formCosingFunctionality,
    }

    setSending(true)
    setSaved(false)

    api.current
      ?.post(BackofficeAPIConfig.Domains.Product, {
        action: action === 'create' ? BackofficeApiActions.CreateIngredient : BackofficeApiActions.UpdateIngredient,
        data,
      } as BackofficeApiEventSchema)
      .then((response: AxiosResponse<BackofficeApiEventResponse> | undefined) => {
        if (response?.data.result) {
          const { id } = response.data.result as ISkincareIngredient
          if (action === 'create') {
            setAction('update')
            setId(id)
            history.replace('/product/ingredients/update/' + id)
          }
          setSaved(true)
          if (props.data.toggleRefresh) {
            props.data.toggleRefresh()
          }
        } else {
          throw new Error('Failed to get response.')
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setSending(false)
      })
  }

  return (
    <React.Fragment>
      {loading && <Spinner></Spinner>}
      {!loading && (
        <Form onSubmit={handleSubmit}>
          {validated === 'error' && (
            <FormAlert>
              <Alert variant="danger" title="Fill out all required fields before continuing." aria-live="polite" isInline />
            </FormAlert>
          )}
          {saved && (
            <FormAlert>
              <Alert variant="success" title="Ingredient saved." aria-live="polite" isInline />
            </FormAlert>
          )}
          <Grid hasGutter>
            <GridItem span={12}>
              <FormGroup label="Name" isRequired>
                <TextInput isRequired id="formInciName" type="text" value={formInciName} readOnly={isReadOnly} onChange={(val) => setFormInciName(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label="CAS Number" isRequired>
                <TextInput isRequired id="formCasNumber" type="text" value={formCasNumber} readOnly={isReadOnly} onChange={(val) => setFormCasNumber(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label="Phase" isRequired>
                <TextInput isRequired id="formPhase" type="text" value={formPhase} readOnly={isReadOnly} onChange={(val) => setFormPhase(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={12}>
              <FormGroup label="Cosing functionality">
                <TagInput
                  value={formCosingFunctionality}
                  onChange={(val) => setFormCosingFunctionality(val)}
                  readOnly={isReadOnly}
                  suggestions={helperCosingFunctionalities}
                ></TagInput>
              </FormGroup>
            </GridItem>
            {!isReadOnly && (
              <GridItem offset={9} span={3}>
                <ActionGroup style={{ float: 'right' }}>
                  <Button variant="primary" type="submit" isLoading={sending}>
                    Submit
                  </Button>
                  <Button variant="link" type="button" onClick={() => props.closeModal()}>
                    Cancel
                  </Button>
                </ActionGroup>
              </GridItem>
            )}
          </Grid>
        </Form>
      )}
    </React.Fragment>
  )
}

const CreateReadUpdateIngredient = React.memo(componentCreateReadUpdateIngredient)

export { CreateReadUpdateIngredient }
