import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { IWhatsAppTemplate, IWhatsAppTemplateButton } from '@lib/WhatsApp/IWhatsAppTemplate'
import { Form, FormGroup, FormSection, InputGroup, InputGroupText, TextArea, TextInput } from '@patternfly/react-core'
import React from 'react'

const ViewWhatsAppTemplate: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const template = props.data.result.template as IWhatsAppTemplate
  const hasHeader = typeof template?.internal?.template_message_data?.header?.text === 'string'
  const hasButton = typeof template?.internal?.template_message_data?.buttons === 'object'
  const button = hasButton ? (template.internal.template_message_data?.buttons[0] as IWhatsAppTemplateButton) : null
  const klaviyoExamplePayload = props.data.result.example.klaviyoPayload
  const exampleUrl = props.data.result.example.url
  const exampleAuth = props.data.result.example.authorization

  return (
    <React.Fragment>
      <Form isHorizontal>
        <FormSection title="Whatsapp template details" titleElement="h2">
          <FormGroup label="Template name">
            <TextInput id="name" value={template.name} type="text" readOnly />
          </FormGroup>
          <FormGroup label="Language">
            <TextInput id="language" value={template.language} type="text" readOnly />
          </FormGroup>
          <FormGroup label="Category">
            <TextInput id="category" value={template.category} type="text" readOnly />
          </FormGroup>
          {hasHeader && (
            <FormGroup label="Header">
              <TextInput id="header" value={template.internal.template_message_data?.header.text} type="text" readOnly />
            </FormGroup>
          )}
          <FormGroup label="Body">
            <TextArea id="body" value={template.internal.text} readOnly rows={9}></TextArea>
          </FormGroup>
          {hasButton && (
            <React.Fragment>
              <FormGroup label="Button text">
                <TextInput id="button-text" value={button?.text} type="text" readOnly />
              </FormGroup>
              <FormGroup label="Button URL">
                <TextInput id="button-url" value={button?.payload} type="text" readOnly />
              </FormGroup>
            </React.Fragment>
          )}
        </FormSection>
        <FormSection title="Klaviyo webhook payload example" titleElement="h2">
          <FormGroup label="URL">
            <TextInput id="name" value={exampleUrl} type="text" readOnly />
          </FormGroup>
          <FormGroup label="Authorization">
            <InputGroup>
              <InputGroupText id="authHeader">Authorization</InputGroupText>
              <TextInput type="text" id="authValue" value={exampleAuth} readOnly />
            </InputGroup>
          </FormGroup>
          <FormGroup label="JSON body">
            <TextArea id="klaviyo-example" value={JSON.stringify(klaviyoExamplePayload, null, 8)} readOnly rows={12}></TextArea>
          </FormGroup>
        </FormSection>
      </Form>
    </React.Fragment>
  )
}

export { ViewWhatsAppTemplate }
