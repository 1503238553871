import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useAxios } from '@app/utils/useAxios'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ISkincareFormulation, ISkincareFormulationUpdateDTO, ISkincareIngredient } from '../IProduct'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { AxiosResponse } from 'axios'
import {
  ActionGroup,
  Alert,
  Button,
  Form,
  FormAlert,
  FormGroup,
  FormSelect,
  FormSelectOption,
  Grid,
  GridItem,
  NumberInput,
  Spinner,
  TextInput,
  TextInputGroup,
  TextInputGroupMain,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
} from '@patternfly/react-core'
import { TagInput } from '@app/components/textInput/TagInput'
import { PlusIcon, TrashIcon } from '@patternfly/react-icons'

const componentCreateReadUpdateFormulation: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  // Fixed
  const api = useAxios()
  const history = useHistory()
  const modalTitles = {
    create: 'Creating formulation',
    read: 'Viewing formulation',
    update: 'Updating formulation',
  }

  // Reactive
  const [action, setAction] = React.useState<string>(props.data.action)
  const [id, setId] = React.useState<string | undefined>(props.data.id)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [sending, setSending] = React.useState<boolean>(false)
  const [isReadOnly, setIsReadOnly] = React.useState<boolean>(action === 'read')
  const [validated, setValidated] = React.useState<string>('default')
  const [saved, setSaved] = React.useState<boolean>(false)

  const [formulation, setFormulation] = React.useState<ISkincareFormulation | undefined>(undefined)

  const [formCode, setFormCode] = React.useState<string>('')
  const [formConcentrations, setFormConcentrations] = React.useState<{ ingredient: { id: string; [key: string]: any }; concentration: number }[]>([])
  const [helperIngredients, setHelperIngredients] = React.useState<ISkincareIngredient[]>([])

  React.useEffect(() => {
    setLoading(true)
    console.log('test', action, id, modalTitles[action])
    props.setTitle(modalTitles[action])

    const abortController = new AbortController()

    const fetchFormulation = async (id: string) => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.Product, {
          action: BackofficeApiActions.ReadFormulation,
          data: {
            id,
          },
        })
        if (response?.data.result) {
          return response?.data.result as ISkincareFormulation
        }
        return undefined
      } catch (error) {
        throw error
      }
    }

    const fetchIngredients = async () => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.Product, {
          action: BackofficeApiActions.ListIngredients,
          data: { limit: 0, sortColumn: 'inci_name', sortOrder: 'asc' },
        })
        if (response?.data.result) {
          return response?.data.result as ISkincareIngredient[]
        }
        return undefined
      } catch (error) {
        throw error
      }
    }

    const fetchAll = async (id?: string) => {
      let tempFormConcentrations = [] as { ingredient: { id: string; [key: string]: any }; concentration: number }[]

      if (id) {
        const formulation = await fetchFormulation(id)
        if (formulation) {
          setFormulation(formulation)
          setFormCode(formulation.code)
          tempFormConcentrations = formulation.ingredients
          setHelperIngredients(formulation.ingredients.map((n) => n.ingredient))
        }
      }

      if (['create', 'update'].includes(action)) {
        if (tempFormConcentrations.length === 0) {
          tempFormConcentrations = [{ ingredient: { id: '' }, concentration: 0 }]
        }
        const ingredients = await fetchIngredients()
        if (ingredients) {
          setHelperIngredients(ingredients)
        }
      }

      setFormConcentrations(tempFormConcentrations)
    }

    fetchAll(id)
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [action])

  React.useEffect(() => {
    props.onBeforeCloseModal.once((_props) => {
      history.push(`/product/formulations`)
    })
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (![formCode, formConcentrations].every((n) => n)) {
      setValidated('error')
      return
    }

    let defaultData = formulation || { metadata: {}, ingredients: [] }

    let data: Partial<ISkincareFormulationUpdateDTO> = {
      ...defaultData,
      code: formCode,
      ingredients: formConcentrations.reduce((arr, item) => {
        if (item.ingredient.id && item.concentration > 0 && !arr.find((n) => n.ingredient.id === item.ingredient.id)) {
          arr.push(item)
        }
        return arr
      }, [] as ISkincareFormulationUpdateDTO['ingredients']),
    }

    setSending(true)
    setSaved(false)

    api.current
      ?.post(BackofficeAPIConfig.Domains.Product, {
        action: action === 'create' ? BackofficeApiActions.CreateFormulation : BackofficeApiActions.UpdateFormulation,
        data,
      } as BackofficeApiEventSchema)
      .then((response: AxiosResponse<BackofficeApiEventResponse> | undefined) => {
        if (response?.data.result) {
          const { id } = response.data.result as ISkincareFormulation
          if (action === 'create') {
            setAction('update')
            setId(id)
            history.replace('/product/formulations/update/' + id)
          }
          setSaved(true)
          if (props.data.toggleRefresh) {
            props.data.toggleRefresh()
          }
        } else {
          throw new Error('Failed to get response.')
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setSending(false)
      })
  }

  const updateIngredientId = (index: number, id: string) => {
    setFormConcentrations(
      formConcentrations.map((n, i) => {
        if (i === index) {
          n.ingredient.id = id
        }
        return n
      })
    )
    console.log('updateIngredientId', index, id)
  }

  const updateConcentration = (index: number, value: string | number) => {
    setFormConcentrations(
      formConcentrations.map((n, i) => {
        if (i === index) {
          const newVal = typeof value === 'string' ? parseFloat(value) : value
          if (!isNaN(newVal)) {
            n.concentration = newVal
          }
        }
        return n
      })
    )
  }

  const clickAddIngredient = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    setFormConcentrations([...formConcentrations, { ingredient: { id: '' }, concentration: 0 }])
  }

  const clickRemoveIngredient = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.preventDefault()

    setFormConcentrations(formConcentrations.filter((_, i) => i !== index))
  }

  return (
    <React.Fragment>
      {loading && <Spinner></Spinner>}
      {!loading && (
        <Form onSubmit={handleSubmit}>
          {validated === 'error' && (
            <FormAlert>
              <Alert variant="danger" title="Fill out all required fields before continuing." aria-live="polite" isInline />
            </FormAlert>
          )}
          {saved && (
            <FormAlert>
              <Alert variant="success" title="Formulation saved." aria-live="polite" isInline />
            </FormAlert>
          )}
          <Grid hasGutter>
            <GridItem span={12}>
              <FormGroup label="Code" isRequired>
                <TextInput isRequired id="formCode" type="text" value={formCode} readOnly={isReadOnly} onChange={(val) => setFormCode(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={12}>
              <FormGroup label="Ingredients" isRequired>
                {formConcentrations.map((item, index) => (
                  <Grid key={'concentration' + index} hasGutter>
                    <GridItem span={4}>
                      <FormSelect
                        isRequired
                        id={'formIngredientId' + index}
                        value={item.ingredient.id}
                        readOnly={isReadOnly}
                        onChange={(ingredientId) => {
                          updateIngredientId(index, ingredientId)
                        }}
                      >
                        <FormSelectOption key={-1} value={''} label="Select ingredient..." />
                        {helperIngredients.map((ingredient, index) => (
                          <FormSelectOption key={index} value={ingredient.id} label={ingredient.name} />
                        ))}
                      </FormSelect>
                    </GridItem>
                    <GridItem span={4}>
                      <NumberInput
                        placeholder="Concentration"
                        min={0}
                        max={100}
                        id={'formOptionValue' + index}
                        type="text"
                        onMinus={() => updateConcentration(index, formConcentrations[index].concentration - 0.01)}
                        onPlus={() => updateConcentration(index, formConcentrations[index].concentration + 0.01)}
                        value={item.concentration}
                        isDisabled={isReadOnly}
                        readOnly={isReadOnly}
                        onChange={(e) => updateConcentration(index, (e.target as HTMLInputElement).value || 0)}
                        unit="%"
                        widthChars={6}
                        allowEmptyInput
                      ></NumberInput>
                    </GridItem>
                    <GridItem span={4}>
                      {!isReadOnly && (
                        <Toolbar>
                          <ToolbarContent alignment={{ default: 'alignRight' }}>
                            <ToolbarGroup alignment={{ default: 'alignRight' }} variant="icon-button-group">
                              {index + 1 === formConcentrations.length && (
                                <ToolbarItem>
                                  <Button variant="plain" aria-label="view" onClick={(e) => clickAddIngredient(e)}>
                                    <PlusIcon></PlusIcon>
                                  </Button>
                                </ToolbarItem>
                              )}
                              <ToolbarItem>
                                <Button variant="plain" aria-label="delete" onClick={(e) => clickRemoveIngredient(e, index)}>
                                  <TrashIcon></TrashIcon>
                                </Button>
                              </ToolbarItem>
                            </ToolbarGroup>
                          </ToolbarContent>
                        </Toolbar>
                      )}
                    </GridItem>
                  </Grid>
                ))}
              </FormGroup>
            </GridItem>
            {!isReadOnly && (
              <GridItem offset={9} span={3}>
                <ActionGroup style={{ float: 'right' }}>
                  <Button variant="primary" type="submit" isLoading={sending}>
                    Submit
                  </Button>
                  <Button variant="link" type="button" onClick={() => props.closeModal()}>
                    Cancel
                  </Button>
                </ActionGroup>
              </GridItem>
            )}
          </Grid>
        </Form>
      )}
    </React.Fragment>
  )
}

const CreateReadUpdateFormulation = React.memo(componentCreateReadUpdateFormulation)

export { CreateReadUpdateFormulation }
