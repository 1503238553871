import React from 'react'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { CodeBlock, CodeBlockCode } from '@patternfly/react-core'

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  return (
    <React.Fragment>
      <CodeBlock>
        <CodeBlockCode id="code-content">{props.data.membership}</CodeBlockCode>
      </CodeBlock>
    </React.Fragment>
  )
}

const CustomerMembership = React.memo(component)

export { CustomerMembership }
