import {
  Button,
  Chip,
  ChipGroup,
  Divider,
  Menu,
  MenuContent,
  MenuItem,
  MenuList,
  Popper,
  TextInputGroup,
  TextInputGroupMain,
  TextInputGroupUtilities,
} from '@patternfly/react-core'
import { TimesIcon } from '@patternfly/react-icons'
import React from 'react'

export interface ITagInputProps {
  onChange?: (value: string[]) => void
  value?: string[]
  readOnly?: boolean
  suggestions?: string[]
  placeholder?: string
  'aria-label'?: string
}

const componentTagInput: React.FunctionComponent<ITagInputProps> = (props: ITagInputProps) => {
  const [inputValue, setInputValue] = React.useState('')
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)
  const [currentChips, setCurrentChips] = React.useState<string[]>(props.value || [])
  const [hint, setHint] = React.useState('')

  /** auto-completing suggestion text items to be shown in the menu */
  const suggestionItems = props.suggestions || []
  const [menuItems, setMenuItems] = React.useState<React.ReactElement[]>([])

  /** refs used to detect when clicks occur inside vs outside of the textInputGroup and menu popper */
  const menuRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>
  const textInputGroupRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>

  /** callback for updating the inputValue state in this component so that the input can be controlled */
  const handleInputChange = (_event: React.FormEvent<HTMLInputElement>, value: string) => {
    setInputValue(value)
  }

  /** callback for removing a chip from the chip selections */
  const deleteChip = (chipToDelete: string) => {
    const newChips = currentChips.filter((chip) => !Object.is(chip, chipToDelete))
    setCurrentChips(newChips)
    if (props.onChange) {
      props.onChange(newChips)
    }
  }

  /** callback for clearing all selected chips and the text input */
  const clearChipsAndInput = () => {
    setCurrentChips([])
    setInputValue('')
    if (props.onChange) {
      props.onChange([])
    }
  }

  React.useEffect(() => {
    /** in the menu only show items that include the text in the input */
    const filteredMenuItems = suggestionItems
      .filter((item) => !currentChips.includes(item))
      .filter((item) => !inputValue || item.toLowerCase().includes(inputValue.toString().toLowerCase()))
      .map((currentValue, index) => (
        <MenuItem key={currentValue} itemId={index}>
          {currentValue}
        </MenuItem>
      ))

    /** in the menu show a disabled "no result" when all menu items are filtered out */
    if (filteredMenuItems.length === 0) {
      const noResultItem = (
        <MenuItem isDisabled key="no result">
          No results found
        </MenuItem>
      )
      setMenuItems([noResultItem])
      setHint('')
      return
    }

    /** The hint is set whenever there is only one autocomplete option left. */
    if (filteredMenuItems.length === 1) {
      const hint = filteredMenuItems[0].props.children
      if (hint.toLowerCase().indexOf(inputValue.toLowerCase())) {
        // the match was found in a place other than the start, so typeahead wouldn't work right
        setHint('')
      } else {
        // use the input for the first part, otherwise case difference could make things look wrong
        setHint(inputValue + hint.substr(inputValue.length))
      }
    } else {
      setHint('')
    }

    setMenuItems([...filteredMenuItems])
  }, [inputValue])

  /** add the given string as a chip in the chip group and clear the input */
  const addChip = (newChipText: string) => {
    if (!currentChips.includes(newChipText)) {
      const newChips = [...currentChips, `${newChipText}`]
      setCurrentChips(newChips)
      if (props.onChange) {
        props.onChange(newChips)
      }
    }
    setInputValue('')
  }

  /** add the current input value as a chip */
  const handleEnter = () => {
    if (inputValue.length) {
      addChip(inputValue)
    }
  }

  const handleTab = () => {
    if (menuItems.length === 1) {
      setInputValue(menuItems[0].props.children)
    }
    setMenuIsOpen(false)
  }

  /** close the menu when escape is hit */
  const handleEscape = () => {
    setMenuIsOpen(false)
  }

  /** allow the user to focus on the menu and navigate using the arrow keys */
  const handleArrowKey = () => {
    if (menuRef.current) {
      const firstElement = menuRef.current.querySelector<HTMLButtonElement>('li > button:not(:disabled)')
      firstElement && firstElement.focus()
    }
  }

  /** reopen the menu if it's closed and any un-designated keys are hit */
  const handleDefault = () => {
    if (!menuIsOpen) {
      setMenuIsOpen(true)
    }
  }

  /** enable keyboard only usage while focused on the text input */
  const handleTextInputKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        if (inputValue !== '') event.preventDefault()
        handleEnter()
        break
      case 'Escape':
        handleEscape()
        break
      case 'Tab':
        handleTab()
        break
      case 'ArrowUp':
      case 'ArrowDown':
        handleArrowKey()
        break
      default:
        handleDefault()
    }
  }

  /** apply focus to the text input */
  const focusTextInput = () => {
    textInputGroupRef.current?.querySelector('input')?.focus()
  }

  /** add the text of the selected item as a new chip */
  const onSelect = (event: React.MouseEvent<Element, MouseEvent> | undefined, _itemId: string | number | undefined) => {
    if (event) {
      const selectedText = (event.target as HTMLElement).innerText
      addChip(selectedText)
      event.stopPropagation()
      focusTextInput()
    }
  }

  /** close the menu when a click occurs outside of the menu or text input group */
  const handleClick = (event: MouseEvent | undefined) => {
    if (event && menuRef.current && !menuRef.current.contains(event.target as HTMLElement) && !textInputGroupRef.current?.contains(event.target as HTMLElement)) {
      setMenuIsOpen(false)
    }
  }

  /** enable keyboard only usage while focused on the menu */
  const handleMenuKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Tab':
      case 'Escape':
        event.preventDefault()
        focusTextInput()
        setMenuIsOpen(false)
        break
      case 'Enter':
      case ' ':
        setTimeout(() => setMenuIsOpen(false), 0)
        break
    }
  }

  /** only show the clear button when there is something that can be cleared */
  const showClearButton = !!inputValue || !!currentChips.length

  /** render the utilities component only when a component it contains is being rendered */
  const showUtilities = !props.readOnly && showClearButton

  const inputGroup = (
    <div ref={textInputGroupRef}>
      <TextInputGroup isDisabled={props.readOnly}>
        <TextInputGroupMain
          value={inputValue}
          hint={hint}
          onChange={handleInputChange}
          onFocus={() => setMenuIsOpen(true)}
          onKeyDown={handleTextInputKeyDown}
          placeholder={props.placeholder || ''}
          aria-label={props['aria-label'] || ''}
        >
          <ChipGroup numChips={Infinity}>
            {currentChips.map((currentChip) => (
              <Chip key={currentChip} onClick={() => deleteChip(currentChip)} isReadOnly={props.readOnly} textMaxWidth="100%">
                {currentChip}
              </Chip>
            ))}
          </ChipGroup>
        </TextInputGroupMain>
        {showUtilities && (
          <TextInputGroupUtilities>
            {showClearButton && (
              <Button variant="plain" onClick={clearChipsAndInput} aria-label="Clear input">
                <TimesIcon />
              </Button>
            )}
          </TextInputGroupUtilities>
        )}
      </TextInputGroup>
    </div>
  )

  const menu = (
    <div ref={menuRef}>
      <Menu onSelect={(e, i) => onSelect(e, i)} onKeyDown={handleMenuKeyDown} isScrollable>
        <MenuContent maxMenuHeight="200px">
          <MenuList>{menuItems}</MenuList>
        </MenuContent>
      </Menu>
    </div>
  )

  return (
    <Popper
      trigger={inputGroup}
      popper={menu}
      direction="up"
      appendTo={() => textInputGroupRef.current}
      isVisible={suggestionItems.length > 0 && menuIsOpen}
      onDocumentClick={handleClick}
    />
  )
}

const TagInput = React.memo(componentTagInput)

export { TagInput }
