import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useAxios } from '@app/utils/useAxios'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { AxiosResponse } from 'axios'
import { ActionGroup, Alert, Button, Form, FormAlert, FormGroup, Grid, GridItem, Spinner, TextArea, TextInput, TextInputGroup, TextInputGroupMain } from '@patternfly/react-core'
import { TagInput } from '@app/components/textInput/TagInput'
import { IMiniModel, IMiniModelFormHelpers, IMiniModelUpdateDTO } from '../IMiniModel'
import { sortBy, uniq } from 'lodash'

const componentCreateReadUpdateMiniModel: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  // Fixed
  const api = useAxios()
  const history = useHistory()
  const modalTitles = {
    create: 'Creating data task',
    read: 'Viewing data task',
    update: 'Updating data task',
  }

  // Reactive
  const [action, setAction] = React.useState<string>(props.data.action)
  const [id, setId] = React.useState<number | undefined>(props.data.id ? parseInt(`${props.data.id}`, 10) : undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [sending, setSending] = React.useState<boolean>(false)
  const [isReadOnly, setIsReadOnly] = React.useState<boolean>(action === 'read')
  const [validated, setValidated] = React.useState<string>('default')
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [saved, setSaved] = React.useState<boolean>(false)

  const [miniModel, setMiniModel] = React.useState<IMiniModel | undefined>(undefined)

  const [formName, setFormName] = React.useState<string>('')
  const [formPurpose, setFormPurpose] = React.useState<string>('')
  const [formTask, setFormTask] = React.useState<string>('')
  const [formUseCases, setFormUseCases] = React.useState<string[]>([])
  const [formDataSources, setFormDataSources] = React.useState<string[]>([])
  const [formOutput, setFormOutput] = React.useState<string>('')
  const [formDatabaseHandle, setFormDatabaseHandle] = React.useState<string>('')
  const [formOutcomeId, setFormOutcomeId] = React.useState<string>('')
  const [helperNames, setHelperNames] = React.useState<string[]>([])
  const [helperDataSources, setHelperDataSources] = React.useState<string[]>([])
  const [helperUseCases, setHelperUseCases] = React.useState<string[]>([])
  const [helperDatabaseHandles, setHelperDatabaseHandles] = React.useState<string[]>([])
  const [helperOutcomeIds, setHelperOutcomeIds] = React.useState<string[]>([])

  const alwaysSuggestedUseCases: string[] = sortBy(['Input for bayesian models', 'NØIE analyser', 'Chat', 'Input for mini models'])

  const isUnique = (value: string, list: string[]) => {
    return typeof list.find((n) => n.toLowerCase() === value.toLowerCase()) === 'undefined'
  }

  React.useEffect(() => {
    setLoading(true)
    props.setTitle(modalTitles[action])

    const abortController = new AbortController()

    const fetchMiniModel = async (id: number) => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.MiniModel, {
          action: BackofficeApiActions.ReadMiniModel,
          data: {
            id,
          },
        })
        if (response?.data.result) {
          return response?.data.result as IMiniModel
        }
        return undefined
      } catch (error) {
        throw error
      }
    }

    const fetchFormHelpers = async () => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.MiniModel, {
          action: BackofficeApiActions.FormHelpersMiniModels,
        })
        if (response?.data.result) {
          return response?.data.result as IMiniModelFormHelpers
        }
        return undefined
      } catch (error) {
        throw error
      }
    }

    const fetchAll = async (id: number | undefined) => {
      let fetchedMiniModel: IMiniModel | undefined
      if (id) {
        fetchedMiniModel = await fetchMiniModel(id)
        if (fetchedMiniModel) {
          setMiniModel(fetchedMiniModel)
          setFormName(fetchedMiniModel.name)
          setFormPurpose(fetchedMiniModel.purpose)
          setFormTask(fetchedMiniModel.task)
          setFormUseCases(fetchedMiniModel.useCases)
          setFormDataSources(fetchedMiniModel.dataSources)
          setFormOutput(fetchedMiniModel.output)
          setFormDatabaseHandle(fetchedMiniModel.databaseHandle || '')
          setFormOutcomeId(fetchedMiniModel.outcomeId || '')
        }
      }

      if (['create', 'update'].includes(action)) {
        const formHelpers = await fetchFormHelpers()
        if (formHelpers) {
          setHelperNames(
            typeof fetchedMiniModel?.name === 'string' ? formHelpers.names.filter((name) => name.toLowerCase() !== fetchedMiniModel?.name.toLowerCase()) : formHelpers.names
          )
          setHelperDatabaseHandles(
            typeof fetchedMiniModel?.databaseHandle === 'string'
              ? formHelpers.databaseHandles.filter((databaseHandle) => databaseHandle.toLowerCase() !== fetchedMiniModel?.databaseHandle?.toLowerCase())
              : formHelpers.databaseHandles
          )
          setHelperOutcomeIds(
            typeof fetchedMiniModel?.outcomeId === 'string'
              ? formHelpers.outcomeIds.filter((outcomeId) => outcomeId.toLowerCase() !== fetchedMiniModel?.outcomeId?.toLowerCase())
              : formHelpers.outcomeIds
          )
          setHelperDataSources(formHelpers.dataSources)
          setHelperUseCases(sortBy(uniq([...alwaysSuggestedUseCases, ...formHelpers.useCases])))
        }
      }

      return true
    }

    fetchAll(id)
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [action])

  React.useEffect(() => {
    props.onBeforeCloseModal.once((_props) => {
      history.push(`/data_refinement/main`)
    })
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setValidated('default')
    setErrorMessage('')

    // Check for required fields
    if (![formName].every((n) => n)) {
      setValidated('error')
      setErrorMessage('Fill out all required fields before continuing.')
      return
    }

    // Check for unique fields
    const uniqueFields = [
      ['Name', formName, helperNames],
      ['Database Handle', formDatabaseHandle, helperDatabaseHandles],
      ['Outcome ID', formOutcomeId, helperOutcomeIds],
    ]

    for (let i = 0; i < uniqueFields.length; i += 1) {
      const fieldName = uniqueFields[i][0] as string
      const fieldValue = uniqueFields[i][1] as string
      const fieldList = uniqueFields[i][2] as string[]
      if (fieldValue && !isUnique(fieldValue, fieldList)) {
        setValidated('error')
        setErrorMessage(`The "${fieldName}" field is not unique.`)
        return
      }
    }

    let defaultData = miniModel || { metadata: {} }

    let data: Partial<IMiniModelUpdateDTO> = {
      ...defaultData,
      name: formName.trim(),
      purpose: formPurpose.trim(),
      task: formTask.trim(),
      useCases: formUseCases,
      dataSources: formDataSources,
      output: formOutput.trim(),
      databaseHandle: formDatabaseHandle.trim() || undefined,
      outcomeId: formOutcomeId.trim() || undefined,
    }

    setSending(true)
    setSaved(false)

    api.current
      ?.post(BackofficeAPIConfig.Domains.MiniModel, {
        action: action === 'create' ? BackofficeApiActions.CreateMiniModel : BackofficeApiActions.UpdateMiniModel,
        data,
      } as BackofficeApiEventSchema)
      .then((response: AxiosResponse<BackofficeApiEventResponse> | undefined) => {
        if (response?.data.result) {
          const { id } = response.data.result as IMiniModel
          if (action === 'create') {
            setAction('update')
            setId(id)
            history.replace('/mini_model/main/update/' + id)
          }
          setSaved(true)
          if (props.data.toggleRefresh) {
            props.data.toggleRefresh()
          }
        } else {
          throw new Error('Failed to get response.')
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setSending(false)
      })
  }

  return (
    <React.Fragment>
      {loading && <Spinner></Spinner>}
      {!loading && (
        <Form onSubmit={handleSubmit}>
          {validated === 'error' && (
            <FormAlert>
              <Alert variant="danger" title={errorMessage} aria-live="polite" isInline />
            </FormAlert>
          )}
          {saved && (
            <FormAlert>
              <Alert variant="success" title="Mini model saved." aria-live="polite" isInline />
            </FormAlert>
          )}
          <Grid hasGutter>
            <GridItem span={12}>
              <FormGroup label="Name (must be unique)" isRequired>
                <TextInput isRequired id="formName" type="text" value={formName} readOnly={isReadOnly} onChange={(val) => setFormName(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={12}>
              <FormGroup label="Purpose">
                <TextInput isRequired id="formPurpose" type="text" value={formPurpose} readOnly={isReadOnly} onChange={(val) => setFormPurpose(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={12}>
              <FormGroup label="Task">
                <TextArea id="formTask" type="text" value={formTask} readOnly={isReadOnly} onChange={(val) => setFormTask(val)} resizeOrientation="vertical"></TextArea>
              </FormGroup>
            </GridItem>
            <GridItem span={12}>
              <FormGroup label="Use cases">
                <TagInput value={formUseCases} onChange={(val) => setFormUseCases(val)} readOnly={isReadOnly} suggestions={helperUseCases}></TagInput>
              </FormGroup>
            </GridItem>
            <GridItem span={12}>
              <FormGroup label="Data sources">
                <TagInput value={formDataSources} onChange={(val) => setFormDataSources(val)} readOnly={isReadOnly} suggestions={helperDataSources}></TagInput>
              </FormGroup>
            </GridItem>
            <GridItem span={12}>
              <FormGroup label="Output">
                <TextArea id="formOutput" type="text" value={formOutput} readOnly={isReadOnly} onChange={(val) => setFormOutput(val)} resizeOrientation="vertical"></TextArea>
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label="Database handle (must be unique)">
                <TextInput
                  isRequired
                  id="formDatabaseHandle"
                  type="text"
                  value={formDatabaseHandle}
                  readOnly={isReadOnly}
                  onChange={(val) => setFormDatabaseHandle(val)}
                ></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label="Outcome ID (must be unique)">
                <TextInput isRequired id="formOutcomeId" type="text" value={formOutcomeId} readOnly={isReadOnly} onChange={(val) => setFormOutcomeId(val)}></TextInput>
              </FormGroup>
            </GridItem>
            {!isReadOnly && (
              <GridItem offset={9} span={3}>
                <ActionGroup style={{ float: 'right' }}>
                  <Button variant="primary" type="submit" isLoading={sending}>
                    Submit
                  </Button>
                  <Button variant="link" type="button" onClick={() => props.closeModal()}>
                    Cancel
                  </Button>
                </ActionGroup>
              </GridItem>
            )}
          </Grid>
        </Form>
      )}
    </React.Fragment>
  )
}

const CreateReadUpdateMiniModel = React.memo(componentCreateReadUpdateMiniModel)

export { CreateReadUpdateMiniModel }
