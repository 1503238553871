import React from 'react'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import update from 'immutability-helper'
import {
  ActionGroup,
  Banner,
  Button,
  CalendarMonth,
  Checkbox,
  Chip,
  ChipGroup,
  CodeBlock,
  CodeBlockCode,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  Spinner,
  TextArea,
  getUniqueId,
} from '@patternfly/react-core'
import { IInternalConversationDTO, IInternalConversationMessageDTO } from '@lib/InternalConversation/IInternalConversation'
import { AxiosResponse } from 'axios'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import moment from 'moment-timezone'
import { IAttachment } from '@lib/Attachments/IAttachment'
import { processAttachments } from '@lib/Attachments/processAttachments'
import { isArray } from 'lodash'
import { InfoCircleIcon } from '@patternfly/react-icons'
import { KeycloakUserRoles, useKeycloakUser } from '@lib/User/useKeycloakUser'

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const user = useKeycloakUser()

  const [loading, setLoading] = React.useState(true)
  const [sending, setSending] = React.useState<boolean>(false)
  const [updateCounter, setUpdateCounter] = React.useState<number>(0)
  const [messages, setMessages] = React.useState<IInternalConversationMessageDTO[] | null>(null)
  const [newMessageForm, setNewMessageForm] = React.useState<Record<string, any>>({
    content: '',
  })
  const rootElementNs = 'internal-conversation'
  const rootElementId = getUniqueId(rootElementNs)
  const submitButtonRef = React.useRef<HTMLButtonElement>(null)
  const [files, setFiles] = React.useState<File[]>([])

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetInternalConversationMessages,
      data: {
        customerId: props.data.customerId,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const messages = response?.data?.result as IInternalConversationMessageDTO[]

        setMessages(messages)
        setLoading(false)
        scrollToBottom()
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [updateCounter])

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSending(true)

    let attachments: IAttachment[] = []

    if (files.length && api.current) {
      attachments = await processAttachments(parseInt(props.data.customerId), files, 'internal-conversation', api.current)
    }

    try {
      const payload: IInternalConversationDTO = {
        customerId: props.data.customerId,
        content: newMessageForm.content,
        attachments,
        subscribers: employeesSelected,
        reminderDate: reminderSelected?.getTime(),
      }
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Customer, {
        action: BackofficeApiActions.CreateOrUpdateInternalConversation,
        data: payload,
      })

      if (response && response.data && response.data.result.success) {
        setSending(false)
        setUpdateCounter(updateCounter + 1)
        newMessageForm.content = ''
        setFiles([])
        setEmployeesSelected([])
        setReminderSelected(undefined)
      } else {
        throw new Error('Something went wrong')
      }
    } catch (error) {
      setSending(false)
      alert(getErrorMessage(error))
    }
  }

  const onFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const txtFileInput = e.target as HTMLInputElement

    if (txtFileInput && txtFileInput.files) {
      const file = txtFileInput.files[0]
      setFiles((files) => [...files, file])
      txtFileInput.value = ''
    }
  }

  const removeFile = (_file: File) => {
    setFiles(files.filter((file) => file.name !== _file.name))
  }

  const deleteMessage = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, messageId: string) => {
    e.preventDefault()

    if (confirm('Confirm?')) {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.Customer, {
          action: BackofficeApiActions.DeleteInternalConversationMessage,
          data: {
            customerId: props.data.customerId,
            messageId,
          },
        })

        setUpdateCounter(updateCounter + 1)
      } catch (error) {
        alert(getErrorMessage(error))
      }
    }
  }

  const [employees, setEmployees] = React.useState<Record<string, any>[]>([])
  const [employeesSelected, setEmployeesSelected] = React.useState<Record<string, any>[]>([])
  const [isUsersSelectModalOpen, setUserSelectIsModalOpen] = React.useState(false)
  const handleUserSelectModalToggle = async (action: string) => {
    setUserSelectIsModalOpen(!isUsersSelectModalOpen)

    switch (action) {
      case 'load':
        await loadEmplopyees()
        break
      default:
        break
    }
  }
  const loadEmplopyees = async () => {
    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Employee, {
        action: BackofficeApiActions.GetEmployees,
        data: {},
      })

      if (response?.data?.result && isArray(response.data.result)) {
        setEmployees(response.data.result)
      }
    } catch (error) {}
  }
  const toggleEmployee = async (checked: boolean, employee: Record<string, any>) => {
    let results: Record<string, any>[] = []

    if (checked) {
      results = update(employeesSelected, { $push: [employee] })
    } else {
      results = employeesSelected.filter((p) => p['id'] !== employee['id'])
    }

    setEmployeesSelected(results)
  }

  const now = new Date()
  const [reminder, setReminder] = React.useState<Date>(moment(now).add(1, 'day').toDate())
  const [reminderSelected, setReminderSelected] = React.useState<Date | undefined>(undefined)
  const [isreminderModalOpen, setRemninderIsModalOpen] = React.useState(false)
  const handleReminderModalToggle = async (action: string) => {
    setRemninderIsModalOpen(!isreminderModalOpen)

    if (action === 'confirm') {
      setReminderSelected(reminder)
    }

    if (action === 'cancel') {
      setReminderSelected(undefined)
    }
  }
  const reminderCalenderValidator = (date: Date) => {
    if (date.getTime() < now.getTime()) {
      return false
    }

    return true
  }

  // Hack to always scroll to the bottom
  const scrollToBottom = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.scrollIntoView()
    }
  }

  return (
    <React.Fragment>
      <div className={rootElementNs} id={rootElementId}>
        {loading && <Spinner />}
        {!loading && messages !== null && (
          <React.Fragment>
            {messages.map((message, index) => (
              <div key={index} className="internal-conversation-message">
                <div className="internal-conversation-message-details">
                  <span>{moment(message.creationDate).format('YYYY-MM-DD HH:mm:ss')}</span>
                  <span>
                    {message.authorName} ({message.authorEmail})
                  </span>
                  <span>
                    <a href="#" onClick={(e) => deleteMessage(e, message.id)}>
                      Delete
                    </a>
                  </span>
                </div>
                <CodeBlock className="pf-u-mb-md pf-u-w-75" style={{ backgroundColor: '#FFF9B2' }}>
                  <CodeBlockCode>
                    <span className="pf-u-display-block" dangerouslySetInnerHTML={{ __html: message.content }}></span>
                    {message.attachments.length > 0 && (
                      <span className="pf-u-display-block pf-u-mt-md">
                        {message.attachments.map((attachment, i) => (
                          <a key={i} className="pf-u-display-inline-block pf-u-pr-sm" href={attachment.url} target="_blank">
                            {attachment.title}
                          </a>
                        ))}
                      </span>
                    )}
                  </CodeBlockCode>
                </CodeBlock>
              </div>
            ))}
            {messages.length === 0 && (
              <Banner variant="info">
                <Flex spaceItems={{ default: 'spaceItemsSm' }}>
                  <FlexItem>
                    <InfoCircleIcon />
                  </FlexItem>
                  <FlexItem>No internal notes for this user</FlexItem>
                </Flex>
              </Banner>
            )}
            <Form onSubmit={submit} className="pf-u-mt-xl">
              <FormGroup>
                <TextArea id="txt-message" onChange={(value) => setNewMessageForm({ ...newMessageForm, content: value })} rows={8} value={newMessageForm.content}></TextArea>
              </FormGroup>
              {(files.length > 0 || employeesSelected.length > 0 || typeof reminderSelected !== 'undefined') && (
                <FormGroup>
                  {files.length > 0 && (
                    <ChipGroup categoryName="Attachments:">
                      {files.map((file, index) => (
                        <Chip key={index} textMaxWidth={'200px'} onClick={() => removeFile(file)}>
                          {file.name}
                        </Chip>
                      ))}
                    </ChipGroup>
                  )}
                  {employeesSelected.length > 0 && (
                    <ChipGroup categoryName="Send notification to:">
                      {employeesSelected.map((employeeSelected, index) => (
                        <Chip key={index} textMaxWidth={'300px'} isReadOnly>
                          {employeeSelected['firstName']} {employeeSelected['lastName']}
                        </Chip>
                      ))}
                    </ChipGroup>
                  )}
                  {typeof reminderSelected !== 'undefined' && (
                    <ChipGroup categoryName="Remind on:">
                      <Chip textMaxWidth={'300px'} isReadOnly>
                        {moment(reminderSelected).format('YYYY-MM-DD')}
                      </Chip>
                    </ChipGroup>
                  )}
                </FormGroup>
              )}
              <ActionGroup>
                <Button className="pf-u-mr-lg" variant="primary" type="submit" isLoading={sending} isDisabled={sending || !newMessageForm.content} ref={submitButtonRef}>
                  Send
                </Button>

                <label className="pf-c-button pf-m-inline pf-m-plain pf-m-small" htmlFor="file" style={{ cursor: 'pointer', lineHeight: '33px' }}>
                  Select attachments
                </label>
                <input id="file" type="file" onChange={(e) => onFileChanged(e)} style={{ opacity: 0, position: 'absolute', zIndex: -1 }}></input>

                {user.current?.isInRole(KeycloakUserRoles.BackofficeManagement) && (
                  <React.Fragment>
                    <Button isSmall variant="plain" isInline onClick={() => handleUserSelectModalToggle('load')}>
                      Select users to notify
                    </Button>
                    <Button isSmall variant="plain" isInline onClick={() => handleReminderModalToggle('open')}>
                      Set a reminder
                    </Button>
                  </React.Fragment>
                )}
              </ActionGroup>
            </Form>
          </React.Fragment>
        )}

        <Modal
          className={`${rootElementNs}-modal`}
          variant={ModalVariant.small}
          title="Select users to notify by email"
          isOpen={isUsersSelectModalOpen}
          showClose={false}
          actions={[
            <Button key="confirm" variant="primary" onClick={() => handleUserSelectModalToggle('confirm')}>
              Confirm
            </Button>,
            <Button key="cancel" variant="link" onClick={() => handleUserSelectModalToggle('cancel')}>
              Cancel
            </Button>,
          ]}
        >
          <Form>
            <FormGroup hasNoPaddingTop isStack>
              <Grid hasGutter span={4}>
                {employees.map((employee, index) => (
                  <GridItem key={index}>
                    <Checkbox
                      isChecked={typeof employeesSelected.find((p) => p['id'] === employee['id']) === 'object'}
                      onChange={(checked) => toggleEmployee(checked, employee)}
                      label={`${employee['firstName']} ${employee['lastName']}`}
                      id={employee['id']}
                    />
                  </GridItem>
                ))}
              </Grid>
            </FormGroup>
          </Form>
        </Modal>

        <Modal
          className={`${rootElementNs}-modal`}
          variant={ModalVariant.small}
          title="Set a reminder"
          isOpen={isreminderModalOpen}
          showClose={false}
          actions={[
            <Button key="confirm" variant="primary" onClick={() => handleReminderModalToggle('confirm')}>
              Confirm
            </Button>,
            <Button key="cancel" variant="link" onClick={() => handleReminderModalToggle('cancel')}>
              Cancel
            </Button>,
          ]}
        >
          <CalendarMonth
            style={{ position: 'absolute', top: 60, left: 0 }}
            date={reminder}
            onChange={setReminder}
            validators={[reminderCalenderValidator]}
            weekStart={1}
            locale="da-DK"
          />
          <div style={{ height: '365px', visibility: 'hidden' }}>&nbsp;</div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

const CustomerInternalConversation = React.memo(component)

export { CustomerInternalConversation }
