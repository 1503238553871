import * as React from 'react'
import '@patternfly/react-core/dist/styles/base.css'
import { BrowserRouter as Router } from 'react-router-dom'
import { AppLayout } from '@app/layouts/AppLayout/AppLayout'
import { AppRoutes } from '@app/routes/routes'
import '@app/app.css'
import { useKeycloak } from '@react-keycloak/web'

const App: React.FunctionComponent = () => {
  const { initialized } = useKeycloak()
  if (!initialized) {
    return <h3>Loading...</h3>
  }
  return (
    <Router>
      <AppLayout>
        <AppRoutes />
      </AppLayout>
    </Router>
  )
}

export default App
