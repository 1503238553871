import { useEffect, useRef } from 'react'
import axios from 'axios'
import type { AxiosInstance } from 'axios'

import { useKeycloak } from '@react-keycloak/web'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'

export const useAxios = (baseURL?: string) => {
  const _baseURL: string = baseURL ? baseURL : BackofficeAPIConfig.BaseURL
  const axiosInstance = useRef<AxiosInstance>()
  const { keycloak, initialized } = useKeycloak()
  const kcToken = keycloak?.token ?? ''

  useEffect(() => {
    axiosInstance.current = axios.create({
      baseURL: _baseURL,
      headers: {
        Authorization: initialized ? `Bearer ${kcToken}` : undefined,
      },
    })

    return () => {
      axiosInstance.current = undefined
    }
  }, [_baseURL, initialized, kcToken])

  return axiosInstance
}
