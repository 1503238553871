import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { ActionGroup, Button, Form, FormGroup, FormSection, FormSelect, FormSelectOption, TextArea, TextInput } from '@patternfly/react-core'
import React from 'react'
import update from 'immutability-helper'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

interface IWhatsAppTemplateDTO {
  name: string
  language: string
  category: string
  internal: {
    text: string
    template_message_data: {
      header: {
        type: 'text'
        text: string
      }
      buttons: [
        {
          payload: string
          text: string
          type: 'url'
        }
      ]
    }
  }
}

const emptyTemplate: IWhatsAppTemplateDTO = {
  name: '',
  language: '',
  category: '',
  internal: {
    text: '',
    template_message_data: {
      header: {
        type: 'text',
        text: '',
      },
      buttons: [
        {
          payload: '',
          text: '',
          type: 'url',
        },
      ],
    },
  },
}

const languageOptions = [
  { value: '', label: 'Select...', disabled: false },
  { value: 'en_US', label: 'English (US)', disabled: false },
]

const categoryOptions = [
  { value: '', label: 'Select...', disabled: false },
  { value: 'MARKETING', label: 'MARKETING', disabled: false },
  { value: 'UTILITY', label: 'UTILITY', disabled: false },
]

const CreateWhatsAppTemplate: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [template, setTemplate] = React.useState<IWhatsAppTemplateDTO>(emptyTemplate)
  const [sending, setSending] = React.useState<boolean>(false)

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSending(true)

    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.WhatsApp, {
        action: BackofficeApiActions.CreateWhatsappTemplate,
        data: template,
      })

      if (response?.data?.result?.id) {
        setTimeout(() => {
          setSending(false)
          props.closeModal()
        }, 500)
      }
    } catch (error) {
      setSending(false)
      alert(getErrorMessage(error))
    }
  }

  const setHeader = (value: string) => {
    const updated = update(template, { internal: { template_message_data: { header: { text: { $set: value } } } } })
    setTemplate(updated)
  }

  const setBody = (value: string) => {
    const updated = update(template, { internal: { text: { $set: value } } })
    setTemplate(updated)
  }

  const setButtonText = (value: string) => {
    const updated = update(template, { internal: { template_message_data: { buttons: { [0]: { text: { $set: value } } } } } })
    setTemplate(updated)
  }

  const setButtonUrl = (value: string) => {
    const updated = update(template, { internal: { template_message_data: { buttons: { [0]: { payload: { $set: value } } } } } })
    setTemplate(updated)
  }

  return (
    <React.Fragment>
      <Form isHorizontal onSubmit={submit}>
        <FormSection title="CreateWhatsApp template" titleElement="h2">
          <FormGroup label="Template name:" helperText="Example: onboarding_day0_v6">
            <TextInput id="name" value={template.name} type="text" onChange={(value) => setTemplate({ ...template, name: value })} />
          </FormGroup>
          <FormGroup label="Language:">
            <FormSelect value={template.language} onChange={(value) => setTemplate({ ...template, language: value })} id="language">
              {languageOptions.map((option, index) => (
                <FormSelectOption isDisabled={option.disabled} key={index} value={option.value} label={option.label} />
              ))}
            </FormSelect>
          </FormGroup>
          <FormGroup label="Category:">
            <FormSelect value={template.category} onChange={(value) => setTemplate({ ...template, category: value })} id="category">
              {categoryOptions.map((option, index) => (
                <FormSelectOption isDisabled={option.disabled} key={index} value={option.value} label={option.label} />
              ))}
            </FormSelect>
          </FormGroup>
          <FormGroup label="Header text:">
            <TextInput id="header" name="header" value={template.internal.template_message_data.header.text} type="text" onChange={(value) => setHeader(value)} />
          </FormGroup>
          <FormGroup label="Body:" helperText="Example variable: {{name}}">
            <TextArea id="body" value={template.internal.text} rows={8} onChange={(value) => setBody(value)}></TextArea>
          </FormGroup>
          <FormGroup label="Button text:">
            <TextInput id="buttonText" value={template.internal.template_message_data.buttons[0].text} type="text" onChange={(value) => setButtonText(value)} />
          </FormGroup>
          <FormGroup label="Button URL:" helperText="Example URL with path variable: https://noie.com/{{path}}">
            <TextInput id="buttonUrl" value={template.internal.template_message_data.buttons[0].payload} type="text" onChange={(value) => setButtonUrl(value)} />
          </FormGroup>
          <ActionGroup className="pf-u-mt-0">
            <Button variant="primary" type="submit" isLoading={sending} isDisabled={sending}>
              Submit
            </Button>
          </ActionGroup>
        </FormSection>
      </Form>
    </React.Fragment>
  )
}

export { CreateWhatsAppTemplate }
