import { FilterChartsPage, FilterChartsPageConfig } from '@app/components/page/FilterChartsPage'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import React from 'react'

const filterChartsPageConfig: FilterChartsPageConfig = {
  tableName: 'profile_analysis',
  getFiltersActionName: BackofficeApiActions.GetUserInsightsFilters,
  getDataActionName: BackofficeApiActions.GetUserInsightsData,
  pageTitle: 'Users',
  pageDescription: 'Apply filters and explore results to discover new opportunities.',
}

const UserAnalysisIndex: React.FunctionComponent = () => {
  return <FilterChartsPage {...filterChartsPageConfig}></FilterChartsPage>
}

export { UserAnalysisIndex }
