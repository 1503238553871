import React from 'react'
import { Select, SelectOption, SelectList } from '@patternfly/react-core/next'
import { MenuToggle, MenuToggleElement } from '@patternfly/react-core'
import { IBasicSelectHandle, IBasicSelectOption, IBasicSelectProps } from '@lib/Core/Select'

export const component: React.ForwardRefRenderFunction<IBasicSelectHandle, IBasicSelectProps> = (props: IBasicSelectProps, ref: React.ForwardedRef<IBasicSelectHandle>) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [selected, setSelected] = React.useState<string>(props.initialValue)
  const [selectOptions, setSelectOptions] = React.useState<IBasicSelectOption[]>(props.items)
  const menuRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setSelectOptions(props.items)
  }, [props.items])

  React.useImperativeHandle(ref, () => ({
    clear() {
      setSelected(props.initialValue)
      setIsOpen(false)
    },
    set(value: string) {
      setSelected(value)
      setIsOpen(false)
    },
  }))

  const onToggleClick = () => {
    setIsOpen(!isOpen)
  }

  const onSelect = (_event: React.MouseEvent<Element, MouseEvent> | undefined, itemId: string | number | undefined) => {
    setSelected(selectOptions.find((p) => p.key === itemId)?.value as string)
    setIsOpen(false)

    if (typeof props.onSelect === 'function') {
      const item: IBasicSelectOption = {
        key: selectOptions.find((p) => p.key === itemId)?.key as string,
        value: selectOptions.find((p) => p.key === itemId)?.value as string,
      }
      props.onSelect(item)
    }
  }

  const toggle = (toggleRef: React.Ref<MenuToggleElement>) => (
    <MenuToggle
      ref={toggleRef}
      onClick={onToggleClick}
      isExpanded={isOpen}
      style={
        {
          width: '100%',
        } as React.CSSProperties
      }
    >
      {selected}
    </MenuToggle>
  )

  return (
    <Select id="single-select" ref={menuRef} isOpen={isOpen} selected={selected} onSelect={onSelect} onOpenChange={(isOpen) => setIsOpen(isOpen)} toggle={toggle}>
      <SelectList>
        {selectOptions.map((option, index) => (
          <SelectOption
            key={index}
            itemId={option.key}
            isDisabled={typeof option.isDisabled === 'boolean' && option.isDisabled ? true : false}
            description={typeof option.description === 'string' ? option.description : ''}
          >
            {option.value}
          </SelectOption>
        ))}
      </SelectList>
    </Select>
  )
}

const BasicSelect = React.memo(React.forwardRef(component))

export { BasicSelect }
