import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { Alert, Button, Form, FormGroup, Spinner, TextInput, Wizard } from '@patternfly/react-core'
import { AxiosError, AxiosResponse } from 'axios'
import * as React from 'react'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'

let oldEmail: string = ''
let newEmail: string = ''

const Step2: React.FunctionComponent<Record<string, any>> = (props) => {
  oldEmail = props.customerDetails.email

  const onNewEmailInputChange = (value) => {
    newEmail = value
  }

  return (
    <React.Fragment>
      <h3 className="pf-u-font-size-lg pf-u-mb-md">Set the new email address</h3>
      <Form>
        <FormGroup label="Current Email" fieldId="CurrentEmail">
          <TextInput value={props.customerDetails.email} readOnly readOnlyVariant="default" type="text" id="CurrentEmail" name="CurrentEmail" />
        </FormGroup>
        <FormGroup label="New Email" fieldId="NewEmail">
          <TextInput onChange={onNewEmailInputChange} type="email" id="NewEmail" name="NewEmail" placeholder="Set new email" />
        </FormGroup>
      </Form>
    </React.Fragment>
  )
}

const Step3: React.FunctionComponent<Record<string, any>> = (props) => {
  return (
    <React.Fragment>
      <h3 className="pf-u-font-size-lg pf-u-mb-md">Review changes and proceed</h3>
      <p>
        Old email:
        <br />
        <b>{oldEmail}</b>
      </p>
      <br />
      <p>
        New email:
        <br />
        <b>{newEmail}</b>
      </p>
    </React.Fragment>
  )
}

const Step4: React.FunctionComponent<Record<string, any>> = (props) => {
  const api = useAxios()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState<string | boolean>(false)
  const [updates, setUpdates] = React.useState<Array<string>>([])

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.UpdateEmail,
      data: {
        customerId: props.customerDetails.id,
        newEmail: newEmail,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        setLoading(false)

        const responseData = response?.data

        if (responseData && responseData.result && responseData.result['error']) {
          setError(responseData.result['error'])
        }
        if (responseData && responseData.result && responseData.result['updates']) {
          setUpdates(responseData.result['updates'])
        }
      })
      .catch((e) => {
        setLoading(false)
        const error = e as AxiosError
        console.error(error)
        setError(error.message)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <React.Fragment>
      <h3 className="pf-u-font-size-lg pf-u-mb-md">Finalizing...</h3>
      {loading && <Spinner size="md"></Spinner>}
      {error && <Alert variant="danger" isInline isPlain title={error} />}
      {updates.map((update, index) => (
        <React.Fragment>
          {update.startsWith('error:') && <Alert key={index} variant="danger" isInline isPlain title={update.replace('error:', '')} />}
          {update.startsWith('warning:') && <Alert key={index} variant="warning" isInline isPlain title={update.replace('warning:', '')} />}
          {update.startsWith('success:') && <Alert key={index} variant="success" isInline isPlain title={update.replace('success:', '')} />}
        </React.Fragment>
      ))}
      {!loading && (
        <React.Fragment>
          <br />
          <p>
            <b>IMPORTANT</b>: if there were any <b className="pf-u-danger-color-100">errors</b> above, take a screenshot and contact IT support.
          </p>
          <p>
            <br />
            <Button variant="primary" onClick={() => window.location.reload()}>
              Finish
            </Button>
          </p>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const [stepIdReached, setStepIdReached] = React.useState<number>(1)
  const steps = [
    {
      id: 'step-1',
      name: 'Information',
      component: (
        <p>
          You're about to change email and username on behalf of the customer.
          <br />
          <br />
          Current email/username: <b>{props.data.email}</b>
        </p>
      ),
    },
    {
      id: 'step-2',
      name: 'Configuration',
      component: <Step2 customerDetails={props.data}></Step2>,
      canJumpTo: stepIdReached >= 2,
    },
    {
      id: 'step-3',
      name: 'Review',
      component: <Step3></Step3>,
      canJumpTo: stepIdReached >= 3,
    },
    {
      id: 'step-4',
      name: 'Finalize',
      component: <Step4 customerDetails={props.data}></Step4>,
      nextButtonText: 'Finish',
      canJumpTo: stepIdReached >= 4,
      isFinishedStep: true,
    },
  ]
  const title = 'Wizard'

  const onNext = ({ id }) => {
    const [, orderIndex] = id.split('-')
    setStepIdReached(stepIdReached < orderIndex ? orderIndex : stepIdReached)
  }

  const onExit = () => {
    props.closeModal()
  }

  return (
    <React.Fragment>
      <Wizard navAriaLabel={`${title} steps`} mainAriaLabel={`${title} content`} steps={steps} onNext={({ id }) => onNext({ id })} height={400} onSave={onExit} onClose={onExit} />
    </React.Fragment>
  )
}

const CustomerChangeEmail = React.memo(component)

export { CustomerChangeEmail }
