import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { TableComposable, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table'
import { AxiosResponse } from 'axios'
import Moment from 'react-moment'
import * as React from 'react'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '../../../components/modal/Modal'
import { CustomerRecommendation } from './CustomerRecommendation'
import { ActionList, ActionListItem, Button, DatePicker, Form, FormGroup, FormSection, Grid, GridItem, Icon, Label, Spinner, Tooltip } from '@patternfly/react-core'
import { SelectOptionProps } from '@patternfly/react-core/next'
import { NoResultsInTable } from '../../../components/table/NoResultsInTable'
import { TypeaheadSelect } from '../../../components/select/TypeaheadSelect'
import { BasicSelect } from '@app/components/select/BasicSelect'
import { TimesCircleIcon } from '@patternfly/react-icons'
import { IBasicSelectOption } from '@lib/Core/Select'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { CustomerRecommendationWebsite } from './CustomerRecommendationWebsite'
import { KeycloakUserRoles, useKeycloakUser } from '@lib/User/useKeycloakUser'
import { useHistory } from 'react-router-dom'

interface IProductAssignment {
  activeClusterId: string
  primarySymptom: string
  sku: string
  modelTypeId: string
  modelName: string
  customerId: string
  assignedAt: string
  assignedBy?: string
  expireAt?: string
  id?: string
}

interface IManualAssignmentModel {
  products: SelectOptionProps[]
  usagePatterns: IBasicSelectOption[]
}

interface IManualAssignmentFormData {
  customerId: string
  sku: string
  usagePattern: string
  expires?: string
}

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const user = useKeycloakUser()
  const history = useHistory()

  const [productAssignments, setProductAssignments] = React.useState<IProductAssignment[]>([])
  const [loading, setLoading] = React.useState(true)

  const [manualAssignmentModel, setManualAssignmentModel] = React.useState<IManualAssignmentModel>({
    products: [],
    usagePatterns: [],
  })
  const [manualAssignmentFormData, setManualAssignmentFormData] = React.useState<IManualAssignmentFormData>({
    customerId: props.data.customerId,
    sku: '',
    usagePattern: '',
  })

  const [reload, setReload] = React.useState<number>(0)

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetProductAssignments,
      data: {
        customerId: props.data.customerId,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const productAssignments: IProductAssignment[] = response?.data.result as IProductAssignment[]
        setProductAssignments(productAssignments)
        setLoading(false)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, {
        action: BackofficeApiActions.GetManualAssignmentModel,
        data: {
          customerId: props.data.customerId,
        },
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          setManualAssignmentModel({ products: response.data.result['products'] as [], usagePatterns: response.data.result['usagePatterns'] as [] })
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })

    return () => {
      abortController.abort()
    }
  }, [reload])

  const columnNames = {
    activeClusterId: 'Cluster ID',
    assignedAt: 'Assigned at',
    expireAt: 'Expires',
    modelTypeId: 'Model ID',
    modelName: 'Model name',
    primarySymptom: 'Primary symptom',
    sku: 'SKU',
    assignedBy: 'Assigned by',
    actions: 'Actions',
  }

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const openRecommendationModal = () => {
    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = 'Recommendation output'
    defaultModalProps.description = `Recommendation for customer ${props.data.customerId}`
    defaultModalProps.component = CustomerRecommendation
    defaultModalProps.data = props.data
    setModalProps(defaultModalProps)
  }
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const openWebsiteRecommendationModal = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = 'Recommendation'
    defaultModalProps.description = `Recommendation for customer ${props.data.customerId}`
    defaultModalProps.component = CustomerRecommendationWebsite
    defaultModalProps.data = { customerId: props.data.customerId }
    setModalProps(defaultModalProps)
  }

  type TypeaheadSelectHandle = React.ElementRef<typeof TypeaheadSelect>
  const typeaheadSelectRef = React.useRef<TypeaheadSelectHandle>(null)
  type BasicSelectHandle = React.ElementRef<typeof BasicSelect>
  const basicSelectRef = React.useRef<BasicSelectHandle>(null)

  const datepickerValueInitial = ''
  const [datepickerValue, setDatepickervalue] = React.useState(datepickerValueInitial)
  const onDatepickerChange = (newValue: string) => {
    setManualAssignmentFormData({ ...manualAssignmentFormData, expires: newValue })
    setDatepickervalue(newValue)
  }

  const createButtonRef = React.useRef<HTMLButtonElement>(null)

  const createManualAssignment = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (createButtonRef.current) {
      createButtonRef.current.disabled = true
    }

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.CreateManualAssignment,
      data: manualAssignmentFormData,
    }

    try {
      await api.current?.post(BackofficeAPIConfig.Domains.Customer, payload)
    } catch (error) {
      alert(getErrorMessage(error))
    }

    setManualAssignmentFormData({
      customerId: props.data.customerId,
      sku: '',
      usagePattern: '',
      expires: '',
    })

    typeaheadSelectRef.current?.clear()
    basicSelectRef.current?.clear()
    setDatepickervalue(datepickerValueInitial)
    setReload(reload + 1)
  }

  const [isManualAssignmentFormValid, setManualAssignmentFormValid] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!manualAssignmentFormData.customerId || !manualAssignmentFormData.sku || !manualAssignmentFormData.usagePattern) {
      setManualAssignmentFormValid(false)
    } else {
      setManualAssignmentFormValid(true)
    }
  }, [manualAssignmentFormData])

  const getSkuTooltipContent = (sku: string): string | undefined => {
    let result: string | undefined = ''

    if (manualAssignmentModel.products.length) {
      result = manualAssignmentModel.products.find((p) => p.itemId === sku)?.children?.toString()
    }

    return result
  }

  const deleteManualAssignment = async (id: string | undefined): Promise<void> => {
    if (id && confirm('Confirm deleting manual assignment?')) {
      const payload: BackofficeApiEventSchema = {
        action: BackofficeApiActions.DeleteManualAssignment,
        data: {
          customerId: props.data.customerId,
          manualAssignmentId: id,
        },
      }

      try {
        await api.current?.post(BackofficeAPIConfig.Domains.Customer, payload)
      } catch (error) {
        alert(getErrorMessage(error))
      }

      setReload(reload + 1)
    }
  }

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      {loading && <Spinner />}
      {!loading && (
        <React.Fragment>
          {user.current?.isInRole(KeycloakUserRoles.BackofficeManagement) && (
            <Form className="pf-u-mb-xl" onSubmit={(e) => createManualAssignment(e)}>
              <FormSection title="Create manual assignment">
                <Grid hasGutter>
                  <GridItem span={6}>
                    <FormGroup label="Product" isRequired fieldId="ma-product">
                      <TypeaheadSelect
                        ref={typeaheadSelectRef}
                        items={manualAssignmentModel.products}
                        placeholder="Select a product..."
                        onSelect={(itemId) => setManualAssignmentFormData({ ...manualAssignmentFormData, sku: itemId })}
                        onClear={() => setManualAssignmentFormData({ ...manualAssignmentFormData, sku: '' })}
                      ></TypeaheadSelect>
                    </FormGroup>
                  </GridItem>
                  <GridItem span={2}>
                    <FormGroup label="Usage" isRequired fieldId="ma-usage">
                      <BasicSelect
                        id="basic-select"
                        ref={basicSelectRef}
                        initialValue="Select..."
                        items={manualAssignmentModel.usagePatterns}
                        onSelect={(item) => setManualAssignmentFormData({ ...manualAssignmentFormData, usagePattern: item.value })}
                      ></BasicSelect>
                    </FormGroup>
                  </GridItem>
                  <GridItem span={3}>
                    <FormGroup label="Expires" fieldId="ma-expires">
                      <DatePicker value={datepickerValue} onChange={(_event, value) => onDatepickerChange(value)} />
                    </FormGroup>
                  </GridItem>
                  <GridItem span={1} className="pf-u-align-self-flex-end">
                    <Button type="submit" isDisabled={!isManualAssignmentFormValid} ref={createButtonRef}>
                      Create
                    </Button>
                  </GridItem>
                </Grid>
              </FormSection>
            </Form>
          )}

          <div className="pf-u-mb-xl">
            <ActionList>
              <ActionListItem>
                <Button variant="primary" isSmall onClick={(e) => openWebsiteRecommendationModal(e)}>
                  View recommendation
                </Button>
              </ActionListItem>
              <ActionListItem>
                <Button variant="tertiary" isSmall onClick={() => openRecommendationModal()}>
                  View model output
                </Button>
              </ActionListItem>
            </ActionList>
          </div>

          <TableComposable aria-label="Simple table" variant={'compact'} borders={true} isStriped>
            <Thead>
              <Tr>
                <Th width={15}>{columnNames.activeClusterId}</Th>
                <Th width={15}>{columnNames.primarySymptom}</Th>
                <Th width={10}>{columnNames.sku}</Th>
                <Th width={15}>{columnNames.modelTypeId}</Th>
                <Th width={15}>{columnNames.modelName}</Th>
                <Th width={15}>{columnNames.assignedBy}</Th>
                <Th width={15}>{columnNames.assignedAt}</Th>
                <Th width={10}>{columnNames.expireAt}</Th>
                <Th>{columnNames.actions}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {productAssignments.map((productAssignment, index) => (
                <Tr key={index}>
                  <Td dataLabel={columnNames.activeClusterId}>{productAssignment.activeClusterId}</Td>
                  <Td dataLabel={columnNames.primarySymptom}>{productAssignment.primarySymptom}</Td>
                  <Td dataLabel={columnNames.sku}>
                    {getSkuTooltipContent(productAssignment.sku) !== undefined && (
                      <Tooltip removeFindDomNode={true} content={getSkuTooltipContent(productAssignment.sku)}>
                        <span className="pf-u-link-color">{productAssignment.sku}</span>
                      </Tooltip>
                    )}
                    {getSkuTooltipContent(productAssignment.sku) === undefined && <span>{productAssignment.sku}</span>}
                  </Td>
                  <Td dataLabel={columnNames.modelTypeId}>{productAssignment.modelTypeId}</Td>
                  <Td dataLabel={columnNames.modelName}>
                    <Button variant="link" isInline onClick={() => history.push(`/model/bayesian/${productAssignment.modelName}`)}>
                      {productAssignment.modelName}
                    </Button>
                  </Td>
                  <Td dataLabel={columnNames.assignedBy}>{productAssignment.assignedBy}</Td>
                  <Td dataLabel={columnNames.assignedAt}>
                    <Moment date={productAssignment.assignedAt} />
                  </Td>
                  <Td dataLabel={columnNames.expireAt}>{productAssignment.expireAt}</Td>
                  <Td>
                    {productAssignment.modelTypeId === 'manual' && (
                      <Button isSmall variant="plain" aria-label="Action" onClick={() => deleteManualAssignment(productAssignment.id)}>
                        <Icon status="danger">
                          <TimesCircleIcon />
                        </Icon>
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
              {!productAssignments.length && <NoResultsInTable></NoResultsInTable>}
            </Tbody>
          </TableComposable>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const CustomerProductAssignments = React.memo(component)

export { CustomerProductAssignments }
