import React from 'react'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { CodeBlock, CodeBlockCode } from '@patternfly/react-core'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { AxiosResponse } from 'axios'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [data, setData] = React.useState<string | Record<string, any> | [] | null>(null)

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetDataset,
      data: {
        customerId: props.data.customer_id,
        datasetId: props.data.data_set_id,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        setData(response?.data.result)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <CodeBlock>
      <CodeBlockCode id="code-content">{JSON.stringify(data, null, 2)}</CodeBlockCode>
    </CodeBlock>
  )
}

const CustomerDatasetRaw = React.memo(component)

export { CustomerDatasetRaw }
