import axios, { AxiosError } from 'axios'

export const getErrorMessage = (error: any) => {
  let message = 'Something went wrong.'

  const e = error as Error

  if (e.message) {
    message = e.message
  }

  if (error && axios.isAxiosError(error)) {
    const e = error as AxiosError

    if (e.response?.data && e.response?.data['error'] && e.response?.data['errorMessage']) {
      if ((e.response.data['errorMessage'] as string).includes('no_access')) {
        message = `You don't have access to this resource.`

        window.location.href = '/'
      } else {
        message = `${e.response.data['error']}: ${e.response.data['errorMessage']}`
      }
    }
  }

  console.log(error)

  return message
}
