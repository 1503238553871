import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { ClipboardCopy, ClipboardCopyVariant, DataList, DataListCell, DataListItem, DataListItemCells, DataListItemRow, Spinner } from '@patternfly/react-core'
import { AxiosResponse } from 'axios'
import * as React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

interface ILinkManagerLink {
  name: string
  value: string
  locale: string
  countryCode: string
}

interface ILinkManagerModel {
  links: ILinkManagerLink[]
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [linkManagerModel, setLinkManagerModel] = React.useState<ILinkManagerModel>()

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetLinkManagerModel,
      data: {
        customerId: props.data.customerId,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const result = response?.data.result as ILinkManagerModel
        setLinkManagerModel(result)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <React.Fragment>
      {!linkManagerModel && <Spinner />}
      {linkManagerModel && (
        <React.Fragment>
          <DataList aria-label="Links">
            <DataListItem>
              {linkManagerModel.links.map((link, index) => (
                <DataListItemRow key={index}>
                  <DataListItemCells
                    dataListCells={[
                      <DataListCell width={2} key={`name-${index}`}>
                        <span>
                          {link.name}
                          <ReactCountryFlag className="pf-u-pl-md" countryCode={link.countryCode}></ReactCountryFlag>
                        </span>
                      </DataListCell>,
                      <DataListCell width={3} key={`value-${index}`}>
                        <ClipboardCopy isReadOnly hoverTip="Copy" clickTip="Copied" variant={ClipboardCopyVariant.expansion}>
                          {link.value}
                        </ClipboardCopy>
                      </DataListCell>,
                    ]}
                  />
                </DataListItemRow>
              ))}
            </DataListItem>
          </DataList>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const CustomerLinkManager = React.memo(component)

export { CustomerLinkManager }
