import axios, { AxiosInstance } from 'axios'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { isArray } from 'lodash'
import mime from 'mime-types'
import { IAttachment } from './IAttachment'

export const processAttachments = async (customerId: number, files: File[], folderName: string, api: AxiosInstance): Promise<IAttachment[]> => {
  const attachments: IAttachment[] = []

  const getFileUploadUrlsPayload = files.map((item) => {
    return {
      customerId,
      folderName,
      extension: item.name.split('.').pop(),
    }
  })

  const getFileUploadUrlsResponse = await api.post(BackofficeAPIConfig.Domains.Customer, {
    action: BackofficeApiActions.GetFileUploadUrls,
    data: { attachments: getFileUploadUrlsPayload },
  })

  if (
    getFileUploadUrlsResponse &&
    getFileUploadUrlsResponse.data &&
    getFileUploadUrlsResponse.data.result &&
    isArray(getFileUploadUrlsResponse.data.result) &&
    getFileUploadUrlsResponse.data.result.length
  ) {
    let index = 0
    for await (const uploadItem of getFileUploadUrlsResponse.data.result) {
      const file = files[index]
      const extension = file.name.split('.').pop() as string

      await axios.put(uploadItem.uploadUrl, file, {
        headers: {
          'Content-Type': mime.lookup(extension),
        },
      })

      attachments.push({
        id: uploadItem.id,
        path: uploadItem.s3Path,
        title: file.name,
      })

      index++
    }
  } else {
    alert('Something went wrong when uploading.')
  }

  return attachments
}
