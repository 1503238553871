import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useAxios } from '@app/utils/useAxios'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ActionGroup, Alert, Button, FileUpload, Form, FormAlert, FormGroup, Grid, GridItem, Spinner, TextInput, TextInputGroup, TextInputGroupMain } from '@patternfly/react-core'

const componentImportDataSet: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  // Fixed
  const api = useAxios()
  const history = useHistory()
  const modalTitles = {
    create: 'Import Data Set',
  }

  // Reactive
  const [action, setAction] = React.useState<string>(props.data.action)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [sending, setSending] = React.useState<boolean>(false)
  const [isReadOnly, setIsReadOnly] = React.useState<boolean>(action === 'read')
  const [validated, setValidated] = React.useState<string>('default')
  const [saved, setSaved] = React.useState<boolean>(false)

  const [formSource, setFormSource] = React.useState<string>('')
  const [formFile, setFormFile] = React.useState<any>('')
  const [formFilename, setFormFilename] = React.useState<string>('')

  React.useEffect(() => {
    props.setTitle(modalTitles[action])

    const abortController = new AbortController()

    return () => {
      abortController.abort()
    }
  }, [action])

  React.useEffect(() => {
    props.onBeforeCloseModal.once((_props) => {
      history.push(`/data`)
    })
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  const handleFileInputChange = (_event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>, file: File) => {
    setFormFilename(file.name)
  }

  const handleClear = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setFormFilename('')
    setFormFile(undefined)
  }

  return (
    <React.Fragment>
      {loading && <Spinner></Spinner>}
      {!loading && (
        <Form onSubmit={handleSubmit}>
          {validated === 'error' && (
            <FormAlert>
              <Alert variant="danger" title="Fill out all required fields before continuing." aria-live="polite" isInline />
            </FormAlert>
          )}
          {saved && (
            <FormAlert>
              <Alert variant="success" title="Data set saved." aria-live="polite" isInline />
            </FormAlert>
          )}
          <Grid hasGutter>
            <GridItem span={12}>
              <FormGroup label="Source" isRequired>
                <TextInput isRequired id="formSource" type="text" value={formSource} readOnly={isReadOnly} onChange={(val) => setFormSource(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={12}>
              <FormGroup label="File" isRequired>
                <FileUpload
                  id="simple-file"
                  value={formFile}
                  filename={formFilename}
                  filenamePlaceholder="Drag and drop a file or upload one"
                  onFileInputChange={handleFileInputChange}
                  onClearClick={handleClear}
                  browseButtonText="Upload"
                />
              </FormGroup>
            </GridItem>
            {!isReadOnly && (
              <GridItem offset={9} span={3}>
                <ActionGroup style={{ float: 'right' }}>
                  <Button variant="primary" type="submit" isLoading={sending}>
                    Submit
                  </Button>
                  <Button variant="link" type="button" onClick={() => props.closeModal()}>
                    Cancel
                  </Button>
                </ActionGroup>
              </GridItem>
            )}
          </Grid>
        </Form>
      )}
    </React.Fragment>
  )
}

const ImportDataSet = React.memo(componentImportDataSet)

export { ImportDataSet }
