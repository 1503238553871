export enum ApiPermissionState {
  READ = 'read',
  FULL = 'full',
}

export interface IApiPermissions {
  signature: string
  endpoints: Record<string, ApiPermissionState>
}

export interface IApiKey {
  id: number
  handle: string
  tenantId: number
  keyValue?: string
  permissions: IApiPermissions
  metadata: Record<string, any>
  createdAt: string
  updatedAt: string
}
