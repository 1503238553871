import * as React from 'react'
import { Tabs, Tab, TabTitleText, TabTitleIcon, Spinner, PageSection, PageSectionVariants, TabsComponent } from '@patternfly/react-core'
import { CustomerDashboard } from './CustomerDashboard'
import { UsersIcon } from '@patternfly/react-icons'
import { useHistory, useParams } from 'react-router-dom'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { KeycloakUserRoles, useKeycloakUser } from '@lib/User/useKeycloakUser'

interface ITab {
  master_id: string
  sub_id: string
  status: number
}

const TabsWithData: React.FunctionComponent = () => {
  const history = useHistory()
  const api = useAxios()
  const params = useParams()
  const user = useKeycloakUser()
  const id = params['id']
  const subid = params['subid']

  const [activeTabKey, setActiveTabKey] = React.useState(id)
  const [tabs, setTabs] = React.useState<ITab[]>([])

  const handleTabClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, tabIndex: string | number) => {
    setActiveTabKey(tabIndex as number)

    if (tabIndex === id) {
      history.push(`/customer/${id}`)
    } else {
      history.push(`/customer/${id}/${tabIndex}`)
    }
  }

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetSubAccounts,
      data: {
        customerId: id,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((subAccountResponse: AxiosResponse<BackofficeApiEventResponse>) => {
        const tabs = subAccountResponse?.data.result as ITab[]
        setTabs(tabs)

        if (subid) {
          setActiveTabKey(subid)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })

    return () => {
      abortController.abort()
    }
  }, [id])

  return (
    <React.Fragment>
      {user.current?.isInRole(KeycloakUserRoles.BackofficeManagement) && (
        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} mountOnEnter={true} unmountOnExit={true} component={TabsComponent.nav} className="customer-dashboard-tabs">
          <Tab
            key={id}
            eventKey={id}
            title={
              <>
                <TabTitleIcon>
                  <UsersIcon />
                </TabTitleIcon>{' '}
                <TabTitleText>
                  <b>MASTER: {id}</b>
                </TabTitleText>{' '}
              </>
            }
          >
            <CustomerDashboard id={id} masterId={id} isMaster={true} key={null} type={''} props={undefined} />
          </Tab>
          {tabs.map((item: ITab, key) => (
            <Tab key={item?.sub_id} eventKey={item?.sub_id} title={<TabTitleText className={item?.status === -1 ? 'inactive' : ''}>{item?.sub_id}</TabTitleText>}>
              <CustomerDashboard id={parseInt(item?.sub_id)} masterId={id} isMaster={false} key={null} type={''} props={undefined} />
            </Tab>
          ))}
        </Tabs>
      )}

      {!user.current?.isInRole(KeycloakUserRoles.BackofficeManagement) && <CustomerDashboard masterId={id} id={id} isMaster={true} key={null} type={''} props={undefined} />}
    </React.Fragment>
  )
}

const Loading: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <PageSection variant={PageSectionVariants.light}>
        <Spinner />
      </PageSection>
    </React.Fragment>
  )
}

const CustomerDashboardTabs: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState(0)

  if (loading) {
    return <Loading />
  } else {
    return <TabsWithData />
  }
}

export { CustomerDashboardTabs }
