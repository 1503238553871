import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { CodeBlock, CodeBlockCode, Hint, HintBody, Spinner } from '@patternfly/react-core'
import { AxiosResponse } from 'axios'
import React from 'react'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [data, setData] = React.useState<Record<string, any>>({})
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetAllergyReport,
      data: {
        customerId: props.data.customerId,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const data = response?.data.result as {}
        setData(data)
        setLoading(false)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <React.Fragment>
      {loading && <Spinner />}
      {!loading && (
        <React.Fragment>
          <Hint>
            <HintBody>Below is raw output from Allergy-api.</HintBody>
          </Hint>
          <br></br>
          <CodeBlock>
            <CodeBlockCode id="code-content">{JSON.stringify(data, null, 2)}</CodeBlockCode>
          </CodeBlock>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const CustomerAllergyReport = React.memo(component)

export { CustomerAllergyReport }
