import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import React from 'react'
import { ISkincareIngredient } from '../IProduct'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { AxiosResponse } from 'axios'
import {
  Button,
  Chip,
  ChipGroup,
  Pagination,
  PaginationVariant,
  Panel,
  PanelMain,
  PanelMainBody,
  Spinner,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
} from '@patternfly/react-core'
import { NoResultsInTable } from '@app/components/table/NoResultsInTable'
import { EyeIcon, PenIcon, TrashIcon } from '@patternfly/react-icons'
import { TableComposable, Thead, Tr, Th, Tbody, Td, TableText } from '@patternfly/react-table'
import { useHistory, useParams } from 'react-router-dom'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { CreateReadUpdateIngredient } from './CreateReadUpdateIngredient'

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const ListIngredients: React.FunctionComponent = () => {
  const [refresh, setRefresh] = React.useState<number>(1)

  // Fixed
  const api = useAxios()
  const history = useHistory()
  const pathParams = useParams()
  const actions = ['create', 'read', 'update']
  const { action, id } = pathParams as Record<string, string | undefined>

  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }

  let initialModalProps: IModalBasicComponentModalProps = defaultModalProps
  let initialShowModal = false

  const toggleRefresh = () => {
    setRefresh(refresh + 1)
  }

  if (action === 'create' || (actions.includes(action || '') && typeof id === 'string')) {
    initialModalProps = {
      component: CreateReadUpdateIngredient,
      description: '',
      title: '',
      data: {
        action,
        id,
        toggleRefresh,
      },
    }
    initialShowModal = true
  }

  const [loading, setLoading] = React.useState(false)
  const [perPage, setPerPage] = React.useState<number>(50)
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [totalResults, setTotalResults] = React.useState<number>(0)
  const [results, setResults] = React.useState<ISkincareIngredient[]>([])
  const [isModalOpen, setIsModalOpen] = React.useState(initialShowModal)
  const [modalProps, setModalProps] = React.useState(initialModalProps)

  React.useEffect(() => {
    setLoading(true)
    const abortController = new AbortController()

    const countPayload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.CountIngredients,
      data: {},
    }

    const listPayload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.ListIngredients,
      data: {
        limit: perPage,
        page: currentPage - 1,
      },
    }

    Promise.all([api.current?.post(BackofficeAPIConfig.Domains.Product, listPayload), api.current?.post(BackofficeAPIConfig.Domains.Product, countPayload)])
      .then(([r1, r2]: (AxiosResponse<BackofficeApiEventResponse> | undefined)[]) => {
        const r1Results = (r1?.data.result || []) as ISkincareIngredient[]
        const r2Results = (r2?.data.result || 0) as number
        setResults(r1Results)
        setTotalResults(r2Results)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [refresh])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const toggleModalProps = (props: Partial<IModalBasicComponentModalProps> = {}) => {
    setModalProps({ ...modalProps, ...props })
  }

  const onSetPage = (_event: React.MouseEvent | React.KeyboardEvent | MouseEvent, newPage: number) => {
    setCurrentPage(newPage)
    toggleRefresh()
  }

  const onPerPageSelect = (_event: React.MouseEvent | React.KeyboardEvent | MouseEvent, newPerPage: number, newPage: number) => {
    setPerPage(newPerPage)
    setCurrentPage(newPage)
    toggleRefresh()
  }

  const clickCreate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    setModalProps({
      component: CreateReadUpdateIngredient,
      data: { action: 'create', toggleRefresh },
      description: '',
      title: '',
      id: 'ingredient-modal',
    })

    setIsModalOpen(true)

    history.push('/product/ingredients/create')
  }

  const clickRead = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault()

    setModalProps({
      component: CreateReadUpdateIngredient,
      data: { action: 'read', id },
      description: ``,
      title: '',
      id: 'ingredient-modal',
    })

    setIsModalOpen(true)

    history.push('/product/ingredients/read/' + id)
  }

  const clickUpdate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault()

    setModalProps({
      component: CreateReadUpdateIngredient,
      data: { action: 'update', id, toggleRefresh },
      description: ``,
      title: '',
      id: 'ingredient-modal',
    })

    setIsModalOpen(true)

    history.push('/product/ingredients/update/' + id)
  }

  const clickDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, ingredient: ISkincareIngredient) => {
    e.preventDefault()

    if (confirm(`Are you sure you want to delete ingredient "${ingredient.name}"?`)) {
      setLoading(true)
      await api.current
        ?.post(BackofficeAPIConfig.Domains.Product, {
          action: BackofficeApiActions.DeleteIngredient,
          data: {
            id: ingredient.id,
          },
        } as BackofficeApiEventSchema)
        .then(toggleRefresh)
        .catch((error) => {
          alert(getErrorMessage(error))
          setLoading(false)
        })
    }
  }

  const columns = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'casNumber',
      label: 'CAS number',
      format: (item: string) => <TableText wrapModifier="truncate">{item}</TableText>,
      width: 10 as 10,
    },
    {
      key: 'phase',
      label: 'Phase',
    },
    {
      key: 'cosingFunctionality',
      label: 'Cosing functionality',
      format: (items: string[]) => (
        <ChipGroup>
          {items.map((item, i) => (
            <Chip key={i} isReadOnly>
              {item}
            </Chip>
          ))}
        </ChipGroup>
      ),
      width: 20 as 20,
    },
  ]

  return (
    <React.Fragment>
      <ModalBasicComponent
        isModalOpen={isModalOpen}
        handleModalToggle={toggleModal}
        modalProps={modalProps}
        toggleModalProps={toggleModalProps}
        key={null}
        type={''}
        props={undefined}
      />

      <Toolbar id="toolbar-items">
        <ToolbarContent alignment={{ lg: 'alignLeft' }}>
          <ToolbarItem>
            <Button isSmall variant="primary" id="single-group-create-button" onClick={(e) => clickCreate(e)}>
              Create new ingredient
            </Button>
          </ToolbarItem>
        </ToolbarContent>
      </Toolbar>

      <Panel>
        <PanelMain>
          <PanelMainBody>
            <Pagination
              perPageComponent="button"
              itemCount={totalResults}
              widgetId="pagination-options-menu-top"
              perPage={perPage}
              page={currentPage}
              variant={PaginationVariant.top}
              onSetPage={onSetPage}
              onPerPageSelect={onPerPageSelect}
            ></Pagination>
            {loading && <Spinner />}
            {!loading && (
              <TableComposable className="vertical-align-middle" variant={'compact'} borders={true} isStriped>
                <Thead>
                  <Tr>
                    {columns.map((column) => (
                      <Th width={column.width} key={column.key}>
                        {column.label}
                      </Th>
                    ))}
                    <Th width={10}></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {results.length > 0 &&
                    results.map((result, index) => (
                      <Tr key={index}>
                        {columns.map((column) => (
                          <Td key={column.key}>{column.format ? column.format(result[column.key]) : result[column.key]}</Td>
                        ))}
                        <Td width={10}>
                          <Toolbar>
                            <ToolbarContent alignment={{ default: 'alignRight' }}>
                              <ToolbarGroup alignment={{ default: 'alignRight' }} variant="icon-button-group">
                                <ToolbarItem>
                                  <Button variant="plain" aria-label="view" onClick={(e) => clickRead(e, result.id)}>
                                    <EyeIcon></EyeIcon>
                                  </Button>
                                </ToolbarItem>
                                <ToolbarItem>
                                  <Button variant="plain" aria-label="update" onClick={(e) => clickUpdate(e, result.id)}>
                                    <PenIcon></PenIcon>
                                  </Button>
                                </ToolbarItem>
                                <ToolbarItem>
                                  <Button variant="plain" aria-label="delete" onClick={(e) => clickDelete(e, result)}>
                                    <TrashIcon></TrashIcon>
                                  </Button>
                                </ToolbarItem>
                              </ToolbarGroup>
                            </ToolbarContent>
                          </Toolbar>
                        </Td>
                      </Tr>
                    ))}
                  {results.length === 0 && <NoResultsInTable></NoResultsInTable>}
                </Tbody>
              </TableComposable>
            )}
            <Pagination
              perPageComponent="button"
              itemCount={totalResults}
              widgetId="pagination-options-menu-bottom"
              perPage={perPage}
              page={currentPage}
              variant={PaginationVariant.bottom}
              onSetPage={onSetPage}
              onPerPageSelect={onPerPageSelect}
            ></Pagination>
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </React.Fragment>
  )
}

export { ListIngredients }
