import React from 'react'
import { Select, SelectOption, SelectList, SelectOptionProps } from '@patternfly/react-core/next'
import { MenuToggle, MenuToggleElement, TextInputGroup, TextInputGroupMain, TextInputGroupUtilities, ChipGroup, Chip, Button } from '@patternfly/react-core'
import TimesIcon from '@patternfly/react-icons/dist/esm/icons/times-icon'

interface ITypeaheadSelectMultipleProps {
  placeholder?: string
  items: SelectOptionProps[]
  preselected?: string[]
  onSelect?: (selected: string[]) => void
  onClear?: () => void
}

interface ITypeaheadHandle {
  clear: () => void
  set: (items: string[]) => void
}

const component: React.ForwardRefRenderFunction<ITypeaheadHandle, ITypeaheadSelectMultipleProps> = (
  props: ITypeaheadSelectMultipleProps,
  ref: React.ForwardedRef<ITypeaheadHandle>
) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState<string>('')
  const [selected, setSelected] = React.useState<string[]>(props.preselected ? props.preselected : [])
  const [selectOptions, setSelectOptions] = React.useState<SelectOptionProps[]>(props.items)
  const [focusedItemIndex, setFocusedItemIndex] = React.useState<number | null>(null)

  const menuRef = React.useRef<HTMLDivElement>(null)
  const textInputRef = React.useRef<HTMLInputElement>()

  React.useEffect(() => {
    setSelectOptions(props.items)
  }, [props.items])

  React.useImperativeHandle(ref, () => ({
    clear() {
      setSelected([])
      setInputValue('')
    },
    set(items: string[]) {
      setSelected(items)
    },
  }))

  React.useEffect(() => {
    let newSelectOptions: SelectOptionProps[] = props.items

    // Filter menu items based on the text input value when one exists
    if (inputValue) {
      newSelectOptions = props.items.filter((menuItem) => String(menuItem.children).toLowerCase().includes(inputValue.toLowerCase()))

      // When no options are found after filtering, display 'No results found'
      if (!newSelectOptions.length) {
        newSelectOptions = [{ isDisabled: true, children: 'No results found' }]
      }
    }

    setSelectOptions(newSelectOptions)
    setFocusedItemIndex(0)
  }, [inputValue])

  React.useEffect(() => {
    if (typeof props.onSelect === 'function') {
      props.onSelect(selected)
    }
  }, [selected])

  const handleMenuArrowKeys = (key: string) => {
    let indexToFocus

    if (isOpen) {
      if (key === 'ArrowUp') {
        // When no index is set or at the first index, focus to the last, otherwise decrement focus index
        if (focusedItemIndex === null || focusedItemIndex === 0) {
          indexToFocus = selectOptions.length - 1
        } else {
          indexToFocus = focusedItemIndex - 1
        }
      }

      if (key === 'ArrowDown') {
        // When no index is set or at the last index, focus to the first, otherwise increment focus index
        if (focusedItemIndex === null || focusedItemIndex === selectOptions.length - 1) {
          indexToFocus = 0
        } else {
          indexToFocus = focusedItemIndex + 1
        }
      }

      setFocusedItemIndex(indexToFocus)
    }
  }

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const enabledMenuItems = selectOptions.filter((menuItem) => !menuItem.isDisabled)
    const [firstMenuItem] = enabledMenuItems
    const focusedItem = focusedItemIndex ? enabledMenuItems[focusedItemIndex] : firstMenuItem

    switch (event.key) {
      // Select the first available option
      case 'Enter':
        if (!isOpen) {
          setIsOpen((prevIsOpen) => !prevIsOpen)
        } else {
          onSelect(focusedItem.itemId as string)
        }
        break
      case 'Tab':
      case 'Escape':
        setIsOpen(false)
        break
      case 'ArrowUp':
      case 'ArrowDown':
        handleMenuArrowKeys(event.key)
        break
      default:
        !isOpen && setIsOpen(true)
    }
  }

  const onToggleClick = () => {
    setIsOpen(!isOpen)
  }

  const onTextInputChange = (_event: React.FormEvent<HTMLInputElement>, value: string) => {
    setInputValue(value)
  }

  const onSelect = (itemId: string) => {
    if (itemId) {
      setSelected(selected.includes(itemId) ? selected.filter((selection) => selection !== itemId) : [...selected, itemId])
    }
  }

  const toggle = (toggleRef: React.Ref<MenuToggleElement>) => (
    <MenuToggle variant="typeahead" onClick={onToggleClick} innerRef={toggleRef} isExpanded={isOpen} isFullWidth>
      <TextInputGroup isPlain>
        <TextInputGroupMain
          value={inputValue}
          onClick={onToggleClick}
          onChange={onTextInputChange}
          onKeyDown={onInputKeyDown}
          id="multi-typeahead-select-input"
          autoComplete="off"
          innerRef={textInputRef}
          placeholder={props.placeholder}
        >
          <ChipGroup>
            {selected.map((selection, index) => (
              <Chip
                key={index}
                onClick={(ev) => {
                  ev.stopPropagation()
                  onSelect(selection)
                }}
              >
                {selection}
              </Chip>
            ))}
          </ChipGroup>
        </TextInputGroupMain>
        <TextInputGroupUtilities>
          {selected.length > 0 && (
            <Button
              variant="plain"
              onClick={() => {
                setInputValue('')
                setSelected([])
              }}
              aria-label="Clear input value"
            >
              <TimesIcon aria-hidden />
            </Button>
          )}
        </TextInputGroupUtilities>
      </TextInputGroup>
    </MenuToggle>
  )

  return (
    <Select
      id="multi-typeahead-select"
      ref={menuRef}
      isOpen={isOpen}
      selected={selected}
      onSelect={(event, selection) => onSelect(selection as string)}
      onOpenChange={() => setIsOpen(false)}
      toggle={toggle}
      isScrollable
    >
      <SelectList isAriaMultiselectable>
        {selectOptions.map((option, index) => (
          <SelectOption key={option.itemId || option.children} isFocused={focusedItemIndex === index} className={option.className} {...option} ref={null} />
        ))}
      </SelectList>
    </Select>
  )
}

const TypeaheadSelectMultiple = React.memo(React.forwardRef(component))

export { TypeaheadSelectMultiple }
