import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { BasicSelect } from '@app/components/select/BasicSelect'
import { TypeaheadSelect } from '@app/components/select/TypeaheadSelect'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { ActionGroup, Button, Form, FormGroup, FormSection, Grid, GridItem, InputGroup, Spinner, Switch, TextInput } from '@patternfly/react-core'
import { SelectOptionProps } from '@patternfly/react-core/next'
import { PlusIcon } from '@patternfly/react-icons'
import { AxiosResponse } from 'axios'
import React from 'react'

const BMCreateNew: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [sending, setSending] = React.useState<boolean>(false)
  const [formModel, setFormModel] = React.useState<Record<string, any> | undefined>(undefined)
  const [modelTypes, setModelTypes] = React.useState<string[]>([])
  const [modelProducts, setModelProducts] = React.useState<SelectOptionProps[]>([])

  React.useEffect(() => {
    const abortController = new AbortController()

    setLoading(true)

    api.current
      ?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.GetBMFormModel,
        data: {},
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          setFormModel(response.data.result['model'] as Record<string, any>)
          setModelTypes(response.data.result['types'] as string[])
          setModelProducts(response.data.result['products'] as SelectOptionProps[])
          setLoading(false)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSending(true)

    if (!formModel) return

    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.CreateBMModel,
        data: formModel,
      })

      if (response?.status === 200) {
        setTimeout(() => {
          setSending(false)
          props.closeModal()
        }, 1000)
      } else {
        throw new Error(`Error: ${JSON.stringify(response?.data)}`)
      }
    } catch (error) {
      alert(getErrorMessage(error))
      setSending(false)
    }
  }

  const removeProduct = (index) => {
    if (!formModel) return
    const skus = [...formModel['skuList']]
    skus.splice(index, 1)
    setFormModel({ ...formModel, skuList: skus })
  }

  const addProduct = (fullSku, index) => {
    if (!formModel) return
    const skus = [...formModel['skuList']]
    skus[index] = fullSku.substring(0, 3)
    setFormModel({ ...formModel, skuList: skus })
  }

  return (
    <React.Fragment>
      {loading && <Spinner />}
      {!loading && typeof formModel !== 'undefined' && (
        <React.Fragment>
          <Form isHorizontal onSubmit={submit}>
            <FormSection title="New model configuration" titleElement="h2">
              <FormGroup label="Model name" isRequired fieldId="modelName" helperText={'Must be unique, no spaces or special characters. Example: face_egzema'}>
                <TextInput
                  isRequired
                  type="text"
                  id="modelName"
                  name="modelName"
                  value={formModel['modelName']}
                  onChange={(value) => setFormModel({ ...formModel, modelName: value })}
                />
              </FormGroup>

              <FormGroup label="Active" isRequired fieldId="active">
                <Switch id="active" label="New model must be inactive by default" isDisabled />
              </FormGroup>

              <FormGroup label="Selection probability" isRequired fieldId="selectionProb" helperText={'New model must have 0 by default'}>
                <TextInput isRequired type="number" id="selectionProb" name="selectionProb" value={formModel['selectionProb']} isDisabled />
              </FormGroup>

              <FormGroup label="Model type" isRequired fieldId="type">
                <BasicSelect
                  id="type"
                  initialValue={'Select...'}
                  items={modelTypes.map((p) => {
                    return { key: p, value: p }
                  })}
                  onSelect={(item) => setFormModel({ ...formModel, type: item.value })}
                ></BasicSelect>
              </FormGroup>
            </FormSection>

            <FormSection title="Prediction distribution" titleElement="h2">
              <FormGroup label="Equal" isRequired fieldId="predictionDistribution.equal" helperText={'Default'} className="pf-u-w-25">
                <TextInput
                  isRequired
                  type="number"
                  id="predictionDistribution.equal"
                  name="predictionDistribution.equal"
                  value={formModel['predictionDistribution']['equal']}
                  isDisabled
                />
              </FormGroup>
              <FormGroup label="Priors" isRequired fieldId="predictionDistribution.priors" helperText={'Default'} className="pf-u-w-25">
                <TextInput
                  isRequired
                  type="number"
                  id="predictionDistribution.priors"
                  name="predictionDistribution.priors"
                  value={formModel['predictionDistribution']['priors']}
                  isDisabled
                />
              </FormGroup>
              <FormGroup label="Posteriors" isRequired fieldId="predictionDistribution.posteriors" helperText={'Default'} className="pf-u-w-25">
                <TextInput
                  isRequired
                  type="number"
                  id="predictionDistribution.posteriors"
                  name="predictionDistribution.posteriors"
                  value={formModel['predictionDistribution']['posteriors']}
                  isDisabled
                />
              </FormGroup>
            </FormSection>

            <FormSection title="Products" titleElement="h2">
              {formModel['skuList'].length > 0 && (
                <FormGroup isStack label="Products" isRequired fieldId="product">
                  {formModel['skuList'].map((sku, index) => (
                    <Grid key={index}>
                      <GridItem span={10}>
                        <TypeaheadSelect items={modelProducts} placeholder="Select a product..." onSelect={(itemId) => addProduct(itemId, index)}></TypeaheadSelect>
                      </GridItem>
                      <GridItem span={2}>
                        <Button variant="link" isDanger isSmall onClick={() => removeProduct(index)}>
                          Remove
                        </Button>
                      </GridItem>
                    </Grid>
                  ))}
                </FormGroup>
              )}

              <FormGroup label="">
                <Button id="addProduct" variant="tertiary" isSmall onClick={() => setFormModel({ ...formModel, skuList: [...formModel['skuList'], ''] })}>
                  <PlusIcon></PlusIcon> Add product
                </Button>
              </FormGroup>
            </FormSection>

            <ActionGroup>
              <Button variant="primary" type="submit" isLoading={sending} isDisabled={sending}>
                Create
              </Button>
              <Button variant="secondary" type="reset" isDisabled={sending} onClick={() => props.closeModal()}>
                Cancel
              </Button>
            </ActionGroup>
          </Form>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export { BMCreateNew }
