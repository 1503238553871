import { useKeycloak } from '@react-keycloak/web'
import { useEffect, useRef } from 'react'

export interface IKeycloakUser {
  getRoles(): string[]
  isInRole(role: KeycloakUserRoles): boolean
}

export enum KeycloakUserRoles {
  BackofficeManagement = 'backoffice-management',
}

class KeycloakUser implements IKeycloakUser {
  private _keycloak

  constructor(keycloak) {
    this._keycloak = keycloak
  }

  getRoles(): string[] {
    return this._keycloak.tokenParsed?.realm_access?.roles
  }

  isInRole(role: KeycloakUserRoles) {
    return this.getRoles().includes(role)
  }
}

export const useKeycloakUser = () => {
  const { keycloak, initialized } = useKeycloak()
  const keycloakUserInstance = useRef<IKeycloakUser>()

  keycloakUserInstance.current = new KeycloakUser(keycloak)

  useEffect(() => {
    return () => {
      keycloakUserInstance.current = undefined
    }
  }, [initialized])

  return keycloakUserInstance
}
