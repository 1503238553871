import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { NoResultsInTable } from '@app/components/table/NoResultsInTable'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { ActionList, ActionListItem, Button, Spinner } from '@patternfly/react-core'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { AxiosResponse } from 'axios'
import moment from 'moment-timezone'
import * as React from 'react'
import { CustomerDraftOrderForm } from './CustomerDraftOrderForm'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

interface IDraftOrdersViewItem {
  createdAt: string
  linkToShopify: string
  linkToInvoice: string
  name: string
  owner: string
}

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()

  const [loading, setLoading] = React.useState(false)
  const [reloadOrders, setReloadOrders] = React.useState<number>(0)
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const [draftOrders, setDraftOrders] = React.useState<IDraftOrdersViewItem[]>([])

  React.useEffect(() => {
    setLoading(true)

    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetDraftOrders,
      data: {
        customerId: props.data.customerId,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        setDraftOrders(response?.data.result as IDraftOrdersViewItem[])
        setLoading(false)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [reloadOrders])

  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const toggleModal = (id: string) => {
    setIsModalOpen(!isModalOpen)

    if (id === draftOrderModalId) {
      setReloadOrders(reloadOrders + 1)
    }
  }

  const draftOrderModalId = 'modal-draft-order-form'
  const openDraftOrderFormModal = () => {
    defaultModalProps.title = 'Draft order'
    defaultModalProps.description = `Create a draft order for customer: ${props.data.customerId}`
    defaultModalProps.component = CustomerDraftOrderForm
    defaultModalProps.data.customerId = props.data.customerId
    defaultModalProps.id = draftOrderModalId

    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)
  }

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      {loading && <Spinner />}
      {!loading && (
        <React.Fragment>
          <div className="pf-u-mb-xl">
            <ActionList>
              <ActionListItem>
                <Button variant="secondary" isSmall onClick={() => openDraftOrderFormModal()}>
                  Create draft order
                </Button>
              </ActionListItem>
            </ActionList>
          </div>
          <TableComposable variant={'compact'} borders={true} isStriped>
            <Thead>
              <Tr>
                <Th>Created at</Th>
                <Th>Link to Shopify</Th>
                <Th>Link to invoice</Th>
                <Th>Owner</Th>
              </Tr>
            </Thead>
            <Tbody>
              {draftOrders.map((draftOrder, index) => (
                <Tr key={index}>
                  <Td>{moment(draftOrder.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Td>
                  <Td>
                    <a href={draftOrder.linkToShopify} target={'_blank'}>
                      {draftOrder.name}
                    </a>
                  </Td>
                  <Td>
                    <a href={draftOrder.linkToInvoice} target={'_blank'}>
                      {draftOrder.name}
                    </a>
                  </Td>
                  <Td>{draftOrder.owner}</Td>
                </Tr>
              ))}
              {!draftOrders.length && <NoResultsInTable></NoResultsInTable>}
            </Tbody>
          </TableComposable>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const CustomerDraftOrders = React.memo(component)

export { CustomerDraftOrders }
